import React, { useCallback, useMemo, useState } from 'react'
import {
  FieldType,
  OCol,
  OField,
  ORow,
  OToggleSwitch
} from '@dnvgl-onefoundation/onedesign-react'
import { FieldsGroupCondition, FormField, FormGroupType, isOptionsField, isOptionsFieldType, OptionsField, QuotationFormStepTemplate } from '../../../interfaces/FormTemplate'
import Checkbox from '@dnvgl-onefoundation/onedesign-react/lib/cjs/Field/Checkbox'

interface Props {
  step: QuotationFormStepTemplate
  condition?: FieldsGroupCondition
  setCondition: (condition: FieldsGroupCondition) => void
}

const FieldsGroupConditionEditor = (props: Props) => {
  const { step, condition, setCondition } = props

  const isNumericMasterField = condition?.masterFieldName && step.fields?.find(x => x.name === condition?.masterFieldName)?.type === 'number'
 
  const getConditionFields = (step: QuotationFormStepTemplate) => {
    if (!step || !step.fields) return []
    const fields = step.fields.filter(x => isOptionsField(x)
      || condition?.type === FormGroupType.Multiplication && x.type === 'number')

    return  fields.map((f: FormField) => {
      return {
        value: f.name,
        text: f.name
      }
    })
  }

  const getConditionOptions = (fieldName?: string) => {

    if (!step || !step.fields || !fieldName) return []

    const field = step.fields.find(x => x.name === fieldName);
    if (!field || !isOptionsFieldType(field)) return []

    return (field as OptionsField).options;
  }

  const conditionFieldsOptions = useMemo(
    () => getConditionFields(step),
    [step, condition?.type]
  )

  const conditionOptions = useMemo(
    () => getConditionOptions(condition?.masterFieldName),
    [condition?.masterFieldName]
  )

  const [options, setOptions] = useState<Record<string, number>>({})

  const adjustOptions = useCallback(() => {
    if (isNumericMasterField) {
      setOptions({});
    } else {
      const newOptions: Record<string, number> = {}
      conditionOptions.forEach(x => { newOptions[x] = options[x] ?? 0})
      setOptions(newOptions)
    }
  }, [condition?.type, conditionOptions])

  const isOptionChecked = (option: string) => (options?.[option] > 0)
  const onOptionCheckChanged = (option: string, isChecked: boolean) => {
    options[option] = isChecked ? 1 : 0;
    setOptions({...options});
    setConditionOptions(options)
  }

  const onOptionValueChanged = (option: string, value: number) => {
    options[option] = value;
    setOptions({...options});
    setConditionOptions(options)
  }

  const setConditionOptions = (options:Record<string, number>) => {
      const result: FieldsGroupCondition = {
        type: condition?.type ?? FormGroupType.Condition,
        masterFieldName: condition?.masterFieldName ?? '',
        options: options,
      }

      setCondition(result)
  }

  const setGroupType = (type:FormGroupType) => {
    adjustOptions()
    const result: FieldsGroupCondition = {
      type: type,
      masterFieldName: condition?.masterFieldName ?? '',
      options: condition?.options ?? {}
    }
    setCondition(result)
  }

  const setMasterFieldName = (fieldName:string) => {
    const result: FieldsGroupCondition = {
      type: condition?.type ?? FormGroupType.Condition,
      masterFieldName:fieldName,
      options: condition?.options ?? {}
    }

    setCondition(result)
  }

  return (
    <ORow>
      <OCol col='12'>
      <OToggleSwitch
        small
        className="pointer border-0"
        textLocation="hidden"
        checked={condition?.type === FormGroupType.Condition}
        onChange={e => {
          setGroupType(e?.target.checked ? FormGroupType.Condition : FormGroupType.Multiplication)
        }}
      >
        {condition?.type === FormGroupType.Condition ? 'Conditional group' : 'Multiplication group'}
      </OToggleSwitch>
      <OField
        onChange={e => {
          setMasterFieldName(`${e.value}`);
        }}
        value={{}}
        field={{
          heading: 'Conditioning Field',
          name: 'conditionIndex',
          type: FieldType.Select,
          optionalTag: false,
          options: conditionFieldsOptions
        }}
      />
        {condition?.type === FormGroupType.Condition && (          
          conditionOptions.map((option, index) => (
            <Checkbox
              id={`gc_${index}`}
              key={`gc_${index}`}
              isValid={true}
              disabled={false}
              checked={isOptionChecked(option)}
              onChange={e => {
                onOptionCheckChanged(option, e.target.checked)
              }}
              >
              {option}
            </Checkbox>
          ))
        )}
        {condition?.type === FormGroupType.Multiplication && (          
          conditionOptions.map((option, index) => (
            <ORow
              key={`gcr_${index}`}
            >
              <OCol>
                <label>{option}:</label>
              </OCol>
              <OCol>
                <input 
                  key={`gc_${index}`}
                  value={options[option]} 
                  onChange={e => {
                    onOptionValueChanged(option, Number.parseInt(e.target.value))
                  }}
                  type={'number'} />
              </OCol>
            </ORow>
          ))
        )}
    </OCol>
  </ORow>
  )
}

export default React.memo(FieldsGroupConditionEditor)
