import React, { useCallback, useEffect, useState } from 'react'
import {
  OButton,
  OModal,
  showError
} from '@dnvgl-onefoundation/onedesign-react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../store/hooks'
import { getPublishedServices, quotationsSlice } from '../../store/slices/quotations'
import {  config } from '../../utils'

import {
  GroupTypeEnum,
  User
} from '../../interfaces'
import SelectService from './SelectService'

interface Props {
  currentUser: User
}

const AddQuotation = (props: Props) => {
  const { currentUser } = props
  const [isVisible, setIsVisible] = useState(false)
  const [serviceId, setServiceId] = useState<string>()
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const { paths } = config

  useEffect(() => {
    dispatch(getPublishedServices())
  }, [dispatch])

  const handleOk = () => {
    const userCompanyId = currentUser?.groups?.find(
      group => group.type === GroupTypeEnum.Company
    )?.id

    if (userCompanyId === undefined) {
      showError(
        'Unknown company',
        'Please contact Veracity support to assign you to company.'
      )
      return
    }
    dispatch(quotationsSlice.actions.setServiceId(serviceId));
    navigate(`${paths.quotations}/new`);
  }

  const handleCancel = useCallback(() => setIsVisible(false), [])

  const onServiceSelected = useCallback(
    (id?: string) => setServiceId(id),
    []
  )

  const okDisabled = () => !serviceId 

  return (
    <>
      <OButton
        iconClass="fal fa-file-import"
        onClick={() => setIsVisible(true)}>
        Add Quotation Request
      </OButton>
      <OModal
        visible={isVisible}
        hideCloseButton
        okText="Add"
        clickOutside={false}
        titleText="Please select service type"
        bodySlot={<SelectService onServiceSelected = { onServiceSelected } />}
        onOk={handleOk}
        onCancel={handleCancel}
        okDisabled={okDisabled()}
      />
    </>
  )
}

export default React.memo(AddQuotation)
