import React, { useState } from 'react'

import { OButton, OTooltip } from '@dnvgl-onefoundation/onedesign-react'
import { api, helper, momentHelper } from '../../utils'
import { FileItem, UploadItem } from '../../interfaces'
import { EmptyListIndicator, StatusIndicator } from '.'
import moment from 'moment-timezone'

interface Props {
  projectId?: string
  isInternalUser: boolean
  isDeleteAllowed: boolean
  files: FileItem[] | UploadItem[]
  notifyEmptyList?: boolean
  onDelete?: (file: FileItem) => any
  downloadUrlBuilder?: (path: string) => string
}

function isUpload(item: FileItem | UploadItem): item is UploadItem {
  return (
    (item as UploadItem).source !== undefined ||
    (item as UploadItem).isTransmitted !== undefined
  )
}

const FileList = (props: Props) => {
  const {
    projectId,
    isInternalUser,
    isDeleteAllowed,
    files,
    notifyEmptyList = true,
    onDelete,
    downloadUrlBuilder
  } = props
  const [transmitting, setTransmitting] = useState<boolean>(false)

  const canRetryTransmittals =
    projectId &&
    isInternalUser &&
    files.some(
      x =>
        isUpload(x) &&
        !x.isTransmitted &&
        x.uploadedAt &&
        moment(x.uploadedAt).isBefore(moment().subtract(1, 'hour'))
    )

  const retryTransmittals = () => {
    if (!projectId) return undefined
    setTransmitting(true)
    api.projectsAdministration.retryTransmittals(projectId).catch(() => {
      helper.handleErrorMessage()
      setTransmitting(false)
    })
  }

  return (
    <div className="mb-3">
      <ul className="list-group list-group-flush">
        {files?.map((file, key) => {
          const upload = projectId && isUpload(file) ? file : undefined
          return (
            <li
              key={key}
              className="list-group-item d-flex justify-content-between align-items-center p-1">
              <span>
                {isInternalUser && upload && (
                  <span className="ml-1">
                    <StatusIndicator
                      statusName={
                        upload.isTransmitted ? 'Transmitted' : 'Pending'
                      }
                    />
                  </span>
                )}
                <strong className="ml-1">{file.name}</strong>
              </span>
              <span className="w-50 text-right">
                <small>
                  Uploaded {upload?.source === 'quotation' && 'to quotation '}
                  by&nbsp;
                  {file.uploadedBy?.firstName} {file.uploadedBy?.lastName}
                  {file?.uploadedAt
                    ? `, ${momentHelper.toHumandFriendlyDateTime(
                        file.uploadedAt
                      )}`
                    : ''}
                </small>
                &nbsp;
                {!!downloadUrlBuilder && (
                  <a
                    href={downloadUrlBuilder(file.path)}
                    target="_blank"
                    rel="noopener noreferrer">
                    <OTooltip content={`Download ${file.name}`}>
                      <OButton
                        iconClass="fal fa-cloud-download"
                        variant="flat"
                      />
                    </OTooltip>
                  </a>
                )}
                {isDeleteAllowed && upload?.source !== 'quotation' && (
                  <OTooltip content={`Delete ${file.name}`}>
                    <OButton
                      onClick={() => onDelete?.(file)}
                      iconClass="fal fa-trash-alt text-danger"
                      variant="flat"
                    />
                  </OTooltip>
                )}
              </span>
            </li>
          )
        })}
      </ul>
      {canRetryTransmittals && (
        <OButton
          disabled={transmitting}
          onClick={retryTransmittals}
          variant="subtle"
          iconClass="fal fa-sync"
          className="mt-2">
          Retry pending transmittals
        </OButton>
      )}
      {!files?.length && notifyEmptyList && <EmptyListIndicator />}
    </div>
  )
}

export default React.memo(FileList)
