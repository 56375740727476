import React, { useState, useEffect, ReactNode, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  OTable,
  OButton,
  OActionBtn,
  OTooltip,
  showSuccess,
  OToggleSwitch,
  showConfirm
} from '@dnvgl-onefoundation/onedesign-react'
import { UserRoleIndicator } from '../../helpers'
import { ApplicationState } from '../../../store'
import { helper, api, groups } from '../../../utils'
import {
  UserGroupItem,
  User,
  UserRole,
  UpdateAction,
  UserRoleEnum,
  GroupTypeEnum
} from '../../../interfaces'
import { getUsers } from '../../../store/slices/userGroups'

interface Props {
  currentUser: User
  currentUserGroup: UserGroupItem
}

export const unassignedGroup: UserGroupItem = {
  id: 'Unassigned',
  name: 'Unassigned',
  companyId: 'Unassigned',
  isInternal: false,
  type: GroupTypeEnum.Company
}

interface IUserViewModel extends User {
  rolesDisplayString?: string
  admin?: ReactNode
  actions?: ReactNode
}

const fields = [
  { name: 'firstName', heading: 'First Name', sortable: true },
  {
    name: 'lastName',
    heading: 'Last Name',
    sortable: true
  },
  { name: 'email', heading: 'Email', sortable: true }
]

const customerFields = [...fields, { name: 'actions', heading: 'Actions' }]

const internalFields = [
  ...fields,
  { name: 'admin', heading: 'Admin' },
  { name: 'actions', heading: 'Actions' }
]

const AdminUsersList = (props: Props) => {
  const { currentUser, currentUserGroup } = props
  const isSuperAdministrator = helper.isSuperAdministrator(currentUser)
  const [allItems, setAllItems] = useState<IUserViewModel[]>([])
  const users = useSelector((s: ApplicationState) => s.userGroups.users)
  const isLoadingUsers = useSelector(
    (state: ApplicationState) => state.userGroups.isLoadingUsers
  )
  const userGroups = useSelector(
    (s: ApplicationState) => s.userGroups.userGroups
  )

  const isInternalGroup = currentUserGroup.isInternal
  const isUnassignedGroup = currentUserGroup === unassignedGroup

  const dispatch = useDispatch()

  const loadUsers = useCallback(
    (isReload?: boolean) => {
      dispatch(getUsers(currentUserGroup.id, isReload ?? true))
    },
    [currentUserGroup.id, dispatch]
  )

  useEffect(() => {
    loadUsers(false)
  }, [loadUsers])

  useEffect(() => {
    users?.length ? setAllItems(extendUsers(users)) : setAllItems([])
  }, [users]) // eslint-disable-line react-hooks/exhaustive-deps

  const addUserToGroup = (user: User, group: UserGroupItem) => {
    api.groups
      .addUser(group.id, user.id)
      .then(() => {
        showSuccess('Updated', `User assigned to "${group.name}".`)
        currentUserGroup.id === 'Unassigned' && loadUsers()
      })
      .catch(helper.handleErrorMessage)
  }

  const removeUserFromCurrentGroup = (user: User) =>
    showConfirm(
      `${currentUserGroup.name}`,
      `Remove ${user.firstName} ${user.lastName} from ${currentUserGroup.name}?`
    ).then(confirmed => {
      if (confirmed)
        api.groups
          .deleteUser(currentUserGroup.id, user.id)
          .then(() => loadUsers())
          .catch(helper.handleErrorMessage)
    })

  const addActionsForGroups = (u: User) => {
    const targetGroups = isUnassignedGroup
      ? userGroups.filter(
          g => g.type === GroupTypeEnum.Company && !g.isInternal
        )
      : currentUserGroup.type === GroupTypeEnum.Company
      ? userGroups.filter(
          g =>
            g.type === GroupTypeEnum.Team &&
            g.companyId === currentUserGroup.id &&
            !u.groups?.some(x => x.id === g.id)
        )
      : []

    return (
      <>
        {!isInternalGroup && currentUserGroup.type === GroupTypeEnum.Company && (
          <OTooltip content="Add to Group" placement="top">
            <OActionBtn
              variant="flat"
              iconClass="fad fa-users"
              disabled={!targetGroups.length}
              actions={targetGroups.map(o => ({
                name: <span>{o?.name}</span>,
                onClick: () => addUserToGroup(u, o)
              }))}
            />
          </OTooltip>
        )}
        {!isUnassignedGroup && (
          <OTooltip
            content={`Remove from ${currentUserGroup.name}`}
            placement="top">
            <OButton
              variant="flat"
              iconClass="fad fa-users-slash text-danger"
              disabled={!isSuperAdministrator && currentUserGroup.isInternal}
              onClick={() => removeUserFromCurrentGroup(u)}
            />
          </OTooltip>
        )}
      </>
    )
  }

  const setUserRole = (u: User, role: UserRoleEnum) => {
    api.userAdministration
      .patchRole({
        userId: u.id,
        role,
        updateAction: helper.hasRole(u, role)
          ? UpdateAction.Remove
          : UpdateAction.Set
      })
      .then(() => {
        loadUsers()
        showSuccess('Updated', `Updated role for ${u.firstName} ${u.lastName}.`)
      })
      .catch(helper.handleErrorMessage)
  }

  const addActionsForRoles = (u: User) => (
    <OToggleSwitch
      small
      textLocation="hidden"
      disabled={!isSuperAdministrator}
      checked={helper.hasRole(u, UserRoleEnum.SuperAdministrator)}
      onChange={() => setUserRole(u, UserRoleEnum.SuperAdministrator)}
      className="pointer">
      <UserRoleIndicator user={u} />
    </OToggleSwitch>
  )

  const extendUsers = (users: User[] = []) =>
    users.map((user: User) => {
      const updatedUser = helper.deepClone(user) as IUserViewModel
      if (isInternalGroup) updatedUser.admin = addActionsForRoles(updatedUser)
      updatedUser.actions = addActionsForGroups(updatedUser)
      updatedUser.rolesDisplayString = updatedUser?.roles?.length
        ? updatedUser?.roles.map((r: UserRole) => r.name)?.join(', ')
        : 'No roles'
      return updatedUser
    })

  return (
    <div className="row">
      <div className={`col-12 mt-2 mb-5`}>
        {allItems?.length ? (
          <OTable
            fields={isInternalGroup ? internalFields : customerFields}
            allItems={allItems}
            rowsPerPage={10}
            hideGoToEndButtons={false}
            showActionIcons={false}
            showFilter={false}
            showFilterButton={false}
            showOptionsButton={false}
            showColumnsButton={false}
            selectMode={false}
            responsive={false}
            small={false}
            bordered={false}
            striped={false}
            className="optimized-table"
          />
        ) : (
          !isLoadingUsers && (
            <div className="mt-5 text-center">
              The {currentUserGroup.name}&nbsp;
              {groups
                ?.getDisplayType(currentUserGroup?.type)
                ?.toLocaleLowerCase()}
              &nbsp;does not include any users.
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default React.memo(AdminUsersList)
