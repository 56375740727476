import { OTooltip } from '@dnvgl-onefoundation/onedesign-react'
import React from 'react'

interface Props {
  title: string
  isValid?: boolean | null
  tooltip?: string | null
}

const icon = (isValid: boolean) => (
  <small
    className={`ml-2 fas fa-${
      isValid ? 'check-circle' : 'exclamation-triangle'
    } text-${isValid ? 'success' : 'warning'}`}>
  </small>
)

const TabTitleIndicator = (props: Props) => {
  const { title, isValid, tooltip } = props
  return (
    <span>
      {title}
      {!isValid && tooltip && (
        <OTooltip content={tooltip} >
          {isValid !== null && icon(isValid as boolean)}
        </OTooltip>
      )}
      {isValid !== null && !tooltip && icon(isValid as boolean)}
    </span>
  )
}

export default React.memo(TabTitleIndicator)
