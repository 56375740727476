import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  OButton,
  ORow,
  OCol,
  OToast,
  showSuccess,
  showError,
  showPrompt,
  showConfirm,
  OToggleSwitch
} from '@dnvgl-onefoundation/onedesign-react'
import ReactJson, { InteractionProps } from 'react-json-view'
import ErrorBoundary from '../../helpers/ErrorBoundary'
import { api, helper } from '../../../utils'
import {
  ServiceDetails
} from '../../../interfaces'

import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  getPublishedServices,
  updateService
} from '../../../store/slices/quotations'
import QuotationWizzard from '../../quotations/QuotationWizzard'
import AddFieldForm from './AddFieldForm'
import { useQuotationFormTemplate } from '../../quotations/hooks'

import { SelectItem, UpdateButton } from '../../helpers'
import AddFieldsGroupForm from './AddFieldsGroupForm'
import { FieldsGroupTemplate, FieldTemplate, QuotationFormStepTemplate } from '../../../interfaces/FormTemplate'

const withDefaultData = (input: QuotationFormStepTemplate[]) =>
  input.map((step: QuotationFormStepTemplate) => {
    step.title = step.title || 'Please add a title'
    step.intro = step.intro || []
    step.fields = step.fields || []
    return step
  })

const PreviewWizzard = (props: {
  steps: QuotationFormStepTemplate[] | undefined
}) => {
  const { form, setStepProperty } = useQuotationFormTemplate(props.steps)
  return form ? (
    <QuotationWizzard
      className="preview-wizzard"
      steps={form.steps}
      data={form.data}
      setStepProperty={setStepProperty}
      isEditAllowed
      onOk={() => console.log(JSON.stringify(form.data, undefined, 2))}
    />
  ) : null
}

const PreviewErrorBoundary = (props: {
  steps: QuotationFormStepTemplate[]
}) => {
  try {
    return <PreviewWizzard steps={props.steps} />
  } catch (e: unknown) {
    return (
      <OToast
        variant="danger"
        message="A parsing error has occurred."
        dismissable={false}
      />
    )
  }
}

interface Props {
  service: ServiceDetails
}

const QuotationFormDesigner = ({ service }: Props) => {
  const [steps, setSteps] = useState<QuotationFormStepTemplate[]>([])
  const [stepsChanged, setStepsChanged] = useState(false)
  const [isAdvancedEditor, setIsAdvancedEditor] = useState(true)
  const [isParsingValid, setIsParsingValid] = useState(true)
  const [formName, setFormName] = useState<string>('')
  const internalUsers = useAppSelector(state => state.userGroups.internalUsers)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setSteps([])
    setIsAdvancedEditor(true)
    let isCanceled = false
    api.quotationForms
      .read(service.id)
      .then(x => {
        if (!isCanceled) {
          setStepsChanged(false)
          setSteps(JSON.parse(x))
        }
      })
      .catch(x => {
        if (!isCanceled) helper.handleErrorMessage(x)
      })
    return () => {
      isCanceled = true
    }
  }, [service.id])

  useEffect(() => setFormName(service.name ?? ''), [service.name])

  const canSave = steps.length && stepsChanged

  const save = () => {
    if (canSave) {
      const stepsToSave = withDefaultData(steps)
      api.quotationForms
        .update(service.id, JSON.stringify(stepsToSave), formName)
        .then(() => {
          showSuccess(service.name, 'Successfully saved!')
          dispatch(getPublishedServices())
          setStepsChanged(false)
          setSteps(stepsToSave)
        })
        .catch(helper.handleErrorMessage)
    }
  }

  const updateName = useCallback(
    () =>
      showPrompt('Update Name', service.name).then(propertyValue => {
        if (propertyValue)
          dispatch(
            updateService(service.id, { propertyName: 'name', propertyValue })
          )
      }),
    [dispatch, service.id, service.name]
  )

  const updateHandlerId = useCallback(
    (items: string[]) => {
      if (items[0])
        dispatch(
          updateService(service.id, {
            propertyName: 'handlerId',
            propertyValue: items[0]
          })
        )
    },
    [dispatch, service.id]
  )

  const updateHandlers = useCallback(
    (items: string[]) => {
      dispatch(
        updateService(service.id, {
          propertyName: 'handlers',
          propertyValue: items
        })
      )
    },
    [dispatch, service.id]
  )

  const handler = useMemo(
    () => internalUsers.find(x => x.id === service.handlerId),
    [internalUsers, service.handlerId]
  )

  const handlers = useMemo(
    () => internalUsers.filter(x => service.handlers.some(y => y === x.id)),
    [internalUsers, service.handlers]
  )

  const updateIsPublished = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
      if (!e) return
      const propertyValue = e.target.checked

      showConfirm(
        service.name,
        `${propertyValue ? 'Publish' : 'Un-publish'} this quotation form?`
      ).then(confirmed => {
        if (confirmed)
          dispatch(
            updateService(service.id, {
              propertyName: 'isPublished',
              propertyValue
            })
          )
      })
    },
    [dispatch, service.id, service.name]
  )

  const buildGenericStep = (title: string): QuotationFormStepTemplate => {
    return {
      title,
      intro: ['First generic paragraph.', 'Second generic paragraph.'],
      fields: []
    }
  }

  const addStep = () =>
    showPrompt('Add Step', 'Step title').then(title => {
      if (title) {
        const newSteps = [...steps]
        newSteps.push(buildGenericStep(title))
        setSteps(newSteps)
        setStepsChanged(true)
      }
    })

  const onUpdate = (payload: InteractionProps) => {
    setSteps(payload.updated_src as QuotationFormStepTemplate[])
    setStepsChanged(true)
  }

  const pushField = (field: FieldTemplate, stepIndex: number) => {
    const newSteps = [...steps]
    const step = newSteps[stepIndex]
    if (step) {
      step.fields = step.fields || []
      step.fields.push(field)
      setSteps(newSteps)
      setStepsChanged(true)
    }
  }

  const pushGroup = (group: FieldsGroupTemplate, stepIndex: number) => {
    const newSteps = [...steps]
    const step = newSteps[stepIndex]
    if (step) {
      step.groups = step.groups || []
      step.groups.push(group)
      setSteps(newSteps)
      setStepsChanged(true)
    }
  }

  const onChange = (e: any) => {
    const value = e.target.value
    let isValid = true

    try {
      JSON.parse(value)
    } catch (error) {
      isValid = false
      showError('JSON Parsing Error')
    }
    setIsParsingValid(isValid)
    if (isValid) setSteps(JSON.parse(value))
    setStepsChanged(isValid)
  }

  return (
    <>
      <ORow className="mx-1 mt-3">
        <OCol col="3">
          <strong>Name</strong>
          <div className="mt-1">
            {formName}
            <UpdateButton onClick={updateName} />
          </div>
        </OCol>
        <OCol col="3">
          <strong>Main Manager</strong>
          <div className="mt-1">
            {handler?.firstName}&nbsp;
            {handler?.lastName}
            <SelectItem
              titleText="Assign Main Manager"
              items={internalUsers}
              iconClass="fas fa-user-edit"
              okText="Submit"
              onSelected={updateHandlerId}
            />
          </div>
        </OCol>
        <OCol col="3">
          <strong>DNV Contact Persons</strong>
          <div className="mt-1">
            {service.handlers.length} users
            <SelectItem
              titleText="Assign Contact Persons"
              items={internalUsers}
              selectedItems={handlers}
              selectionRequired={false}
              iconClass="fas fa-users"
              multiselect = {true}
              okText="Submit"
              onSelected={updateHandlers}
            />
          </div>
        </OCol>
        <OCol col="3">
          <strong>Published</strong>
          <OToggleSwitch
            small
            className="mt-2 pointer"
            textLocation="hidden"
            checked={service.isPublished}
            onChange={updateIsPublished}
          />
        </OCol>
        <OCol col="3" className="mt-4 text-right">
          <OButton
            onClick={save}
            disabled={!canSave}
            iconClass="fal fa-cloud"
            variant={canSave ? 'cta' : 'flat'}>
            Save
          </OButton>
        </OCol>
        <OCol col="6" className="mt-3">
          <ORow>
            <OCol col="6">
              <OToggleSwitch
                small
                className="pointer border-0"
                textLocation="hidden"
                checked={isAdvancedEditor}
                onChange={() => setIsAdvancedEditor(!isAdvancedEditor)}>
                {isAdvancedEditor ? 'Advanced Editor' : 'Plain JSON'}
              </OToggleSwitch>
            </OCol>
            <OCol col="6">
              <OButton
                onClick={addStep}
                size="small"
                iconClass="fal fa-plus"
                variant="flat">
                Add Step
              </OButton>
              <AddFieldForm onAdd={pushField} steps={steps} showStep={true} />
              <AddFieldsGroupForm onAdd={pushGroup} steps={steps} />
            </OCol>
            {isAdvancedEditor ? (
              <OCol col="12">
                {!!steps.length && (
                  <div
                    className="mt-2"
                    style={{ overflowY: 'scroll', maxHeight: 620 }}>
                    <ReactJson
                      src={steps}
                      theme="tomorrow"
                      iconStyle="circle"
                      indentWidth={2}
                      enableClipboard={false}
                      onEdit={onUpdate}
                      onAdd={onUpdate}
                      onDelete={onUpdate}
                    />
                  </div>
                )}
              </OCol>
            ) : (
              <OCol col="12">
                <textarea
                  onChange={onChange}
                  className={`w-100 ${isParsingValid ? '' : 'text-danger'}`}
                  rows={30}
                  defaultValue={JSON.stringify(steps, null, 4)}
                />
              </OCol>
            )}
          </ORow>
        </OCol>
        <OCol col="6" className="mt-3">
          <p>
            <strong>Preview</strong>
          </p>
          <ErrorBoundary showReset>
            <PreviewErrorBoundary steps={steps} />
          </ErrorBoundary>
        </OCol>
      </ORow>
    </>
  )
}

export default React.memo(QuotationFormDesigner)
