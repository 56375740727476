import React, { useState, useEffect } from 'react'

import {
  ORow,
  OLogo,
  GridColumns,
} from '@dnvgl-onefoundation/onedesign-react'
import { DetailsItem, UpdateButton, SelectItem, TeamBadges } from '../helpers'
import momentHelper from '../../utils/momentHelper'

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { helper } from '../../utils'
import {
  GroupTypeEnum,
  ProjectDetails,
  User
} from '../../interfaces'
import { checkGroups } from '../../store/slices/userGroups'

const { toHumandFriendlyDateTime } = momentHelper

export type ProjectValue = string | number | string[]

interface Props {
  project: ProjectDetails
  promptToUpdate: (title: string, value: string, propertyName: string) => void
  updateProject: (propertyName: string, propertyValue: ProjectValue) => void
}

const ProjectDetailsView = (props: Props) => {
  const { project, promptToUpdate, updateProject } = props
  const [groupUsers, setGroupUsers] = useState<User[]>([])

  const currentUser = useAppSelector(state => state.users.currentUser)
  const internalUsers = useAppSelector(state => state.userGroups.internalUsers)
  const externalUsers = useAppSelector(state => state.userGroups.externalUsers)
  const userGroups = useAppSelector(state => state.userGroups.userGroups)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(checkGroups())
  }, [dispatch])

  useEffect(() => {
    if (externalUsers) setGroupUsers(externalUsers[project.company.id])
  }, [externalUsers, project.company.id])
  const isInternalUser = !!helper.isInternal(currentUser)

  const companyName = project.company.name

  const items = [
    {
      title: 'Created',
      content: `${toHumandFriendlyDateTime(project.createdAt)} by 
  ${project.createdBy?.firstName} 
  ${project.createdBy?.lastName}`,
      isVisible: true,
      gridColumns: '6'
    },
    {
      title: 'Last Activity',
      content: `${toHumandFriendlyDateTime(project.updatedAt)} by 
  ${project.updatedBy?.firstName} 
  ${project.updatedBy?.lastName}`,
      isVisible: true,
      gridColumns: '6'
    },
    {
      title: 'Teamcenter ID',
      content: (
        <>
          {project.teamcenterId}&nbsp;
          <UpdateButton
            onClick={() =>
              promptToUpdate(
                'Update Teamcenter ID',
                `${project.teamcenterId ?? ''}`,
                'teamcenterId'
              )
            }
          />
        </>
      ),
      isVisible: isInternalUser,
      gridColumns: '6'
    },
    {
      title: 'Company',
      content: companyName,
      isVisible: isInternalUser,
      gridColumns: '6'
    },
    {
      title: 'DNV Contact Person',
      content: (
        <span>
          {project.projectManager?.firstName}&nbsp;
          {project.projectManager?.lastName}
          &nbsp;
          <SelectItem
            titleText={<OLogo style={{ maxWidth: '80px' }} />}
            items={internalUsers}
            iconClass="fas fa-user-edit"
            okText="Assign Contact Person"
            onSelected={items => updateProject('projectManagerId', items[0])}
          />
        </span>
      ),
      isVisible: true,
      gridColumns: '6'
    },
    {
      title: `${companyName} Contact Person`,
      content: (
        <span>
          {project.customerContact?.firstName}&nbsp;
          {project.customerContact?.lastName}
          &nbsp;
          {(isInternalUser ||
            (currentUser &&
              currentUser.id === project.customerContact?.id)) && (
            <SelectItem
              titleText={companyName}
              items={groupUsers}
              iconClass="fas fa-user-edit"
              okText="Assign Contact Person"
              onSelected={items =>
                updateProject('customerContactId', items?.[0])
              }
            />
          )}
        </span>
      ),
      isVisible: true,
      gridColumns: '6'
    },
    {
      //DNV Team placeholder
      title: '',
      content: (
        <span className="flex d-flex align-items-center">
        </span>
      ),
      isVisible: true,
      gridColumns: '6'
    },
    {
      title: 'Teams',
      content: (
        <span className="flex d-flex align-items-center">
          <TeamBadges teams={project.teams} />
          {!project.isReadOnly && (
            <SelectItem
              titleText={companyName}
              items={userGroups.filter(
                x =>
                  x.companyId === project.company.id &&
                  x.type === GroupTypeEnum.Team
              )}
              selectedItems={project.teams}
              multiselect
              selectionRequired={false}
              iconClass="fas fa-users-cog"
              okText="Assign Teams"
              onSelected={items => updateProject('teams', items)}
            />
          )}
        </span>
      ),
      isVisible: true,
      gridColumns: '6'
    },
  ]

  return (
    <ORow>
      {items
        ?.filter(item => item.isVisible)
        .map(item => (
          <DetailsItem
            gridColumns={item.gridColumns as GridColumns}
            title={item.title}
            key={item.title}>
            {item.content}
          </DetailsItem>
        ))}
    </ORow>
  )
}

export default React.memo(ProjectDetailsView)
