import { OToggleSwitch } from '@dnvgl-onefoundation/onedesign-react'
import React, { useState, ReactNode, useEffect, CSSProperties } from 'react'

interface Props {
  isChecked: boolean,
  id: string,
  className?: string,
  style?: CSSProperties,
  checkedIcon: string,
  uncheckedIcon: string,
  onToggle?: (id: string, value: boolean) => void
}

const ToggleBox = (props: Props) => {
    const {
      isChecked,
      id,
      className,
      style,
      checkedIcon,
      uncheckedIcon,
          onToggle
    } = props
    
    const classes = ['custom-control-input', 'm-0', 'd-flex', 'align-items-center']
    if (className) classes.push(className)

    return (
      <div 
        className="custom-control"
        style={{ minWidth: '60px', display:'-webkit-box' }}
        onClick={e => {
          if (!(e.target as HTMLElement).onclick) {
            e.stopPropagation()
          }
        }}
      >
        <OToggleSwitch
          small
          textLocation="hidden"
          checked={isChecked}
          onChange={e => 
            e && onToggle?.(id, e.target.checked) }
        />
        <i className={ isChecked ? checkedIcon : uncheckedIcon} />
      </div>
      )
}

export default React.memo(ToggleBox)
