import React, { CSSProperties } from 'react'

interface Props {
  icon?: string
  placeholder?: string
  value?: string
  className?: string
  style?: CSSProperties
  onChange?: (value: string) => void
}

const InputWithIcon = ({
  icon = 'fas fa-search',
  placeholder = 'Search…',
  value,
  style,
  className,
  onChange
}: Props) => (
  <div
    className={`input-group ${className || ''}`} 
    style={{ ...style }}>
    <span
      className="input-group-text"
      style={{ borderRight: 0, background: 'transparent' }}>
      <i className={icon}></i>
    </span>
    <input
      style={{ borderLeft: 0 }}
      type="text"
      className="form-control"
      placeholder={placeholder}
      value={value}
      aria-label={placeholder?.toLocaleLowerCase?.()}
      onChange={e => onChange?.(e.target.value)}
    />
  </div>
)
export default React.memo(InputWithIcon)
