import React from 'react'
import { OTooltip, OButton } from '@dnvgl-onefoundation/onedesign-react'

interface Props {
  onClick: () => void
}
const UpdateButton = ({ onClick }: Props) => (
  <OTooltip content="Update">
    <OButton onClick={onClick} iconClass="fas fa-edit" variant="flat" />
  </OTooltip>
)

export default React.memo(UpdateButton)
