import helper from './helper'

interface TransformRule {
  property: string
  handler: Function
}

const transform = (collection: any[], rules: TransformRule[]): any[] => {
  const items = helper.deepClone(collection)
  rules.forEach(rule =>
    items.forEach((item: any) => {
      item[rule.property] =
        item[rule.property] === undefined
          ? '–'
          : rule.handler(item[rule.property])
    })
  )

  return items
}

export default transform
