import React, { ReactNode } from 'react'
import { ORow, OCol } from '@dnvgl-onefoundation/onedesign-react'
import ContentSection from './ContentSection'
import SpinIndicator from './SpinIndicator'

interface PageProps {
  title?: ReactNode
  actions?: ReactNode
  disabled?: boolean
  isFullWidthTitle?: boolean
  isLoading?: boolean
  className?: string
  showUnderConstruction?: boolean
  children?: ReactNode
}

function Page(props: PageProps) {
  const {
    title,
    actions,
    disabled,
    isFullWidthTitle,
    isLoading,
    className,
    showUnderConstruction,
    children
  } = props

  return (
    <ORow className={className}>
      {!!title && (
        <OCol col={isFullWidthTitle ? '12' : '6'}>
          <h1 className="mb-1">{title}</h1>
        </OCol>
      )}
      {!!actions && (
        <OCol col="6">
          <div className="mt-3 d-flex align-items-center float-right no-print ">
            {actions}
          </div>
        </OCol>
      )}
      <OCol col="12">
        <div style={{ minHeight: '3px' }}>{isLoading && <SpinIndicator />}</div>
      </OCol>
      <OCol col="12" className={disabled ? 'is-disabled' : ''}>
        {showUnderConstruction ? (
          <ContentSection title="Under Construction" icon="fal fa-tools">
            <p>This page is not implemented yet.</p>
          </ContentSection>
        ) : (
          <article>{children}</article>
        )}
      </OCol>
    </ORow>
  )
}

export default Page
