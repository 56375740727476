import React, { useCallback, useEffect, useState } from 'react'
import {
  ORow,
  OCol,
  OList,
  ListItem,
} from '@dnvgl-onefoundation/onedesign-react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getPublishedServices } from '../../store/slices/quotations'
import { FilterInput } from '../helpers'

interface Props {
  onServiceSelected?: (id?: string) => void
}

const SelectService = (props: Props) => {
  const { onServiceSelected } = props
  const [serviceId, setServiceId] = useState<string>()
  const [servicesFilterQuery, setServicesFilterQuery] = useState<string>('')
  const services = useAppSelector(x => x.quotations.publishedServices)
  const dispatch = useAppDispatch()

  const [viewItems, setViewItems] = useState<ListItem[]>([])
 
  useEffect(() => {
    dispatch(getPublishedServices())
  }, [dispatch])

  useEffect(() => {
    setViewItems(services
      .filter(x =>
        x.name?.toLocaleLowerCase()?.includes(servicesFilterQuery)
      )
      .map(x => ({ id: x.id, content: x.name }))
      .sort((x, y) => x.content.localeCompare(y.content)))
  }, [services, servicesFilterQuery])

  const onSelectedHandler = useCallback(
    (item?: ListItem) => {
      const id = item?.id as string
      setServiceId(id);
      onServiceSelected?.(id);
    },[])

  return (
    <ORow>
        <OCol col="7">
          <p className="font-weight-bold">Available services</p>
        </OCol>
        <OCol col="5">
          <FilterInput
            onChange={(query: string) => {
              setServicesFilterQuery(query?.toLocaleLowerCase())
            }}
            placeholder="Filter services…"
          />
        </OCol>
        <OCol col="12" className="mt-1">
        <div style={{maxHeight:'320px',  overflowY: 'auto'}}>
          {viewItems.length > 0 ? (
            <OList
              small
              flush
              items={viewItems}
              selected={[`${serviceId}`]}
              onSelected={onSelectedHandler}
            />
          ) : (
            <span>No service found</span>
          )}
        </div>
        </OCol>
    </ORow>
  )
}

export default React.memo(SelectService)
