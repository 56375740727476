import React, { useMemo } from 'react'
import { OTable, TableItem } from '@dnvgl-onefoundation/onedesign-react'
import { QuotationItem, UserGroupItem } from '../../interfaces'

import { config, momentHelper } from '../../utils'
import { useUserGroups } from './hooks'
import { QuotationStatusBadge } from '.'

interface Props {
  items: QuotationItem[]
  isInternalUser: boolean
  showFilterButton?: boolean
  onOpen: (item?: QuotationItem) => void
}

const normalize = (
  items: QuotationItem[],
  isInternalUser: boolean,
  getGroup: (id: string | undefined) => UserGroupItem | undefined,
  getCompany: (id: string | undefined) => UserGroupItem | undefined
) => {
  return items.map((item: QuotationItem) => {
    return {
      ...item,
      statusName: <QuotationStatusBadge status={item.status} isInternalUser={isInternalUser} tableView />,
      action:
        item.status.isInternalAction === null
          ? '-'
          : item.status.isInternalAction
          ? 'DNV'
          : getGroup(item.groupId)?.name ?? 'Customer',
      name: item.name,
      updatedAt: momentHelper.toHumandFriendlyDateTime(item.updatedAt),
      groupName: isInternalUser
        ? getCompany(item.groupId)?.name ?? '-'
        : undefined,
      customerContact: `${item?.customerContact?.firstName} ${item?.customerContact?.lastName}`,
      dnvContact: item?.dnvContact?.fullName ?? '-'
    }
  })
}

const QuotationItemsList = (props: Props) => {
  const { items, isInternalUser, showFilterButton, onOpen } = props
  const { QuotationItemsList } = config
  const { getGroup, getCompany } = useUserGroups()

  const openQuotation = (item?: TableItem) =>
    item?.id && onOpen(items?.find(i => i.id === item.id))

  const fields = isInternalUser
    ? QuotationItemsList.forInternal.fields
    : QuotationItemsList.forCustomer.fields

  const rowsPerPage = isInternalUser
    ? QuotationItemsList.forInternal.rowsPerPage
    : QuotationItemsList.forCustomer.rowsPerPage

  var allItems = useMemo(
    () => normalize(items, isInternalUser, getGroup, getCompany),
    [items, isInternalUser, getGroup, getCompany]
  )

  return (
    <OTable
      className="selectable-table"
      allItems={allItems}
      rowsPerPage={rowsPerPage}
      fields={fields}
      small
      showActionIcons
      showColumnsButton={false}
      showOptionsButton={false}
      showFilterButton={showFilterButton}
      onFocusRowChange={openQuotation}
    />
  )
}

export default React.memo(QuotationItemsList)
