import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api, helper, validator } from '../../utils'
import {
  ServiceDetails,
  ServiceItem,
  UpdatePropertyRequest,
  QuotationItem,
  QuotationDetails,
  QuotationTechnicalDetails,
  AgreementFile,
  QuotationTab,
  QuotationStatusEnum,
  PurchaseOrder,
  QuotationStatus,
  MeasurementUnitsEnum
} from '../../interfaces'
import { AsyncAction, AsyncActionCreator } from '../index'
import { getErrorHandler } from './users'
import { TechnicalDetailsValidationResult } from '../../utils/validator'

export interface QuotationsState {
  disabledQuotationTabs: QuotationTab[]
  services: ServiceDetails[]
  serviceId: string | null
  publishedServices: ServiceItem[]
  quotationItems: QuotationItem[]
  loadingQuotationItems: boolean
  quotationDetails: QuotationDetails | null
  isQuotationDetailsValid: boolean | null
  loadingQuotationDetails: boolean
  technicalDetails: QuotationTechnicalDetails | null
  technicalDetailsValidationResult: TechnicalDetailsValidationResult | null
  listAgreements: AgreementFile[]
  agreementsAccepted: boolean
  purchaseOrder: PurchaseOrder | null
}

const { publishSorter, checkAgreementsAccepted } = helper
const { isCustomerDetailsValid, validateTechnicalDetails } = validator

const initialState: QuotationsState = {
  disabledQuotationTabs: [
    QuotationTab.Technical,
    QuotationTab.CustomerInfo,
    QuotationTab.Documents,
    QuotationTab.Agreement,
    QuotationTab.PurchaseOrder
  ],
  services: [],
  serviceId: null,
  publishedServices: [],
  quotationItems: [],
  loadingQuotationItems: false,
  quotationDetails: null,
  isQuotationDetailsValid: null,
  loadingQuotationDetails: false,
  technicalDetails: null,
  technicalDetailsValidationResult: null,
  listAgreements: [],
  agreementsAccepted: false,
  purchaseOrder: null
}

export const quotationsSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setDisabledQuotationTabs: (state, action) => {
      state.disabledQuotationTabs = action.payload
    },
    setServices: (state, action) => {
      state.services = action.payload?.sort(publishSorter)
    },
    setService: (state, action: PayloadAction<ServiceDetails>) => {
      const index = state.services.findIndex(x => x.id === action.payload.id)
      if (index >= 0) {
        state.services[index] = action.payload
        state.services = state.services.sort(publishSorter)
      }
    },
    setServiceId: (state, action) => {
      state.serviceId = action.payload
    },
    setPublishedServices: (state, action: PayloadAction<ServiceItem[]>) => {
      state.publishedServices = action.payload
    },
    setQuotationItems: (state, action: PayloadAction<QuotationItem[]>) => {
      state.quotationItems = action.payload
    },
    setLoadingQuotationItems: (state, action: PayloadAction<boolean>) => {
      state.loadingQuotationItems = action.payload
    },
    setQuotationDetails: (
      state,
      action: PayloadAction<QuotationDetails | null>
    ) => {
      state.quotationDetails = action.payload
    },
    setIsQuotationDetailsValid: (
      state,
      action: PayloadAction<QuotationDetails>
    ) => {
      state.isQuotationDetailsValid = isCustomerDetailsValid(action.payload)
    },
    setLoadingQuotationDetails: (state, action: PayloadAction<boolean>) => {
      state.loadingQuotationDetails = action.payload
    },
    setTechnicalDetails: (
      state,
      action: PayloadAction<QuotationTechnicalDetails | null>
    ) => {
      state.technicalDetails = action.payload
    },
    setTechnicalDetailsValidation: (
      state,
      action: PayloadAction<QuotationTechnicalDetails | null>
    ) => {
      state.technicalDetailsValidationResult = validateTechnicalDetails(action.payload)
    },
    setAgreements: (state, action: PayloadAction<any | null>) => {
      state.listAgreements = action.payload
    },
    setAgreementsAccepted: (state, action: PayloadAction<boolean>) => {
      state.agreementsAccepted = action.payload
    },
    setPurchaseOrder: (state, action: PayloadAction<PurchaseOrder | null>) => {
      state.purchaseOrder = action.payload
    },
    setQuotationStatus: (state, action: PayloadAction<QuotationStatus | null> ) => {
      if (state.quotationDetails && action.payload) {
        state.quotationDetails.status = action.payload
      }
    },
    clearQuotation: state => {
      state.quotationDetails = null
      state.isQuotationDetailsValid = null
      state.loadingQuotationDetails = false
      state.technicalDetails = null
      state.technicalDetailsValidationResult = null
      state.listAgreements = []
      state.agreementsAccepted = false
      state.purchaseOrder = null
    }
  }
})

export const { actions } = quotationsSlice

export const refreshDisabledTabs: () => AsyncAction =
  () => (dispatch, getState) => {
    const { quotations, users } = getState()
    const disabled: QuotationTab[] = []

    const { Draft, Sent } = QuotationStatusEnum

    const isDraft = quotations.quotationDetails?.status.id === Draft
    const isSent = quotations.quotationDetails?.status.id === Sent

    if (isDraft || (isSent && !users.isInternalUser)) {
      disabled.push(QuotationTab.Agreement)
      disabled.push(QuotationTab.PurchaseOrder)
    }

    dispatch(actions.setDisabledQuotationTabs(disabled))
  }

export const updateService: (
  id: string,
  request: UpdatePropertyRequest
) => AsyncAction = (id, request) => dispatch =>
  api.services
    .patch(id, request)
    .then(service => dispatch(actions.setService(service)))
    .catch(getErrorHandler(dispatch))

export const getServices: AsyncActionCreator = () => dispatch =>
  api.services
    .readAll()
    .then(services => dispatch(actions.setServices(services)))
    .catch(getErrorHandler(dispatch))

export const getPublishedServices: AsyncActionCreator = () => dispatch =>
  api.services
    .readPublished()
    .then(services => dispatch(actions.setPublishedServices(services)))
    .catch(getErrorHandler(dispatch))

export const readAllQuotationItems: (
  ownOnly: boolean,
  ongoingOnly: boolean
) => AsyncAction = (ownOnly, ongoingOnly) => dispatch => {
  dispatch(actions.setLoadingQuotationItems(true))
  return api.quotations
    .readAll(ownOnly, ongoingOnly)
    .then(data => dispatch(actions.setQuotationItems(data)))
    .catch(getErrorHandler(dispatch))
    .finally(() => dispatch(actions.setLoadingQuotationItems(false)))
}

export const readQuotationDetails: (id: string) => AsyncAction =
  id => dispatch => {
    dispatch(actions.setLoadingQuotationDetails(true))
    dispatch(actions.setQuotationDetails(null))
    return api.quotations
      .readQuotationDetails(id)
      .then(data => {
        dispatch(actions.setQuotationDetails(data))
        dispatch(actions.setIsQuotationDetailsValid(data))
        dispatch(refreshDisabledTabs())
      })
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setLoadingQuotationDetails(false)))
  }

export const readTechnicalDetails: (id: string) => AsyncAction =
  id => dispatch => {
    dispatch(actions.setTechnicalDetails(null))
    dispatch(actions.setTechnicalDetailsValidation(null))
    return api.quotations
      .readTechnicalDetails(id)
      .then(data => {
        dispatch(actions.setTechnicalDetails(data))
        dispatch(actions.setTechnicalDetailsValidation(data))
        dispatch(refreshDisabledTabs())
      })
      .catch(getErrorHandler(dispatch))
  }

export const readAgreements: (id: string) => AsyncAction = id => dispatch => {
  dispatch(actions.setAgreements([]))
  return api.quotations
    .listAggreements(id)
    .then((data: any) => {
      dispatch(actions.setAgreements(data))
      dispatch(actions.setAgreementsAccepted(checkAgreementsAccepted(data)))
      dispatch(refreshDisabledTabs())
    })
    .catch(getErrorHandler(dispatch))
}

export const setStatus: (id: string, statusId: QuotationStatusEnum) => AsyncAction = (id, statusId) => dispatch => {
  return api.quotations
    .setStatus(id, statusId)
    .then((data: any) => {
      dispatch(actions.setQuotationStatus(data))
    })
    .catch(getErrorHandler(dispatch))
}

export const readPurchaseOrder: (id: string) => AsyncAction =
  id => dispatch => {
    dispatch(actions.setPurchaseOrder(null))
    return api.quotations
      .readPurchaseOrder(id)
      .then((po: PurchaseOrder) => {
        dispatch(actions.setPurchaseOrder(po))
        dispatch(refreshDisabledTabs())
      })
      .catch(getErrorHandler(dispatch))
  }

export const createNewTechnicalDetails: (serviceId: string) => AsyncAction =
  serviceId => dispatch => {
    dispatch(actions.setTechnicalDetails(null))
    dispatch(actions.setTechnicalDetailsValidation(null))
    return api.quotationForms
      .read(serviceId)
      .then(serviceForm => {
          const techDetails: QuotationTechnicalDetails = {
            units : MeasurementUnitsEnum.Metric,
            data: {},
            form: JSON.parse(serviceForm)
          }

          dispatch(actions.setTechnicalDetails(techDetails))
          dispatch(actions.setTechnicalDetailsValidation(techDetails))
        })
      .catch(getErrorHandler(dispatch))
  }

