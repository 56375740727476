import React, {  } from 'react'

import { api } from '../../utils'
import { QuotationDetails, User } from '../../interfaces'

import Discussions from "./Discussions"

interface Props {
  quotation: QuotationDetails
  currentUser: User
  isInternalUser: boolean
  isReadOnly: boolean
}

const QuotationDiscussions = (props: Props) => {
  const { quotation, currentUser, isInternalUser, isReadOnly } = props

  return (
    <Discussions
      objectId={quotation.id}
      currentUser={currentUser}
      isInternalUser={isInternalUser}
      isReadOnly={isReadOnly}
      title={quotation.name + " Discussions"}
      pushMessageApi= {api.quotations.postMessage}
      readMessages= {api.quotations.readMessages}
      readMessagesCount= {api.quotations.readMessagesCount}
    />
  )
}

export default React.memo(QuotationDiscussions)
