import React, { useState } from 'react'
import {
  OBadge,
  OButton,
  OCol,
  ORow
} from '@dnvgl-onefoundation/onedesign-react'

interface Props {
  options: string[]
  addOption: (optionName: string) => void
  deleteOption: (optionName: string) => void
}

const OptionFieldEditor = (props: Props) => {
  const { options, addOption, deleteOption } = props
  const [option, setOption] = useState<string>('')

  return (
    <ORow>
      <OCol col="12">
        <label>Options</label>
      </OCol>
      <OCol col="9">
        <input
          className="form-control mb-3"
          value={option}
          onChange={e => setOption(e.target.value)}
          onKeyDown={e => {
            if (e.key !== 'Enter') return
            addOption(option?.trim())
            setOption('')
          }}
        />
      </OCol>
      <OCol col="3">
        <OButton
          onClick={() => {
            addOption(option?.trim())
            setOption('')
          }}
          iconClass="fa-light fa-plus"
          className="w-100"
          variant="subtle">
          Add
        </OButton>
      </OCol>
      <OCol col="12" className="d-flex flex-wrap">
        {options.map(x => (
          <OBadge
            key={x}
            variant="secondary"
            className="mw-100 mb-2 mr-2 d-flex flex flex-start">
            <span
              className="text-truncate"
              title={x}
              style={{ lineHeight: 1.5 }}>
              {x}
            </span>
            <OButton
              onClick={() => deleteOption(x)}
              className="ml-2"
              iconClass="fal fa-trash-alt"
              variant="flat"
              size="extra-small"
            />
          </OBadge>
        ))}
      </OCol>
    </ORow>
  )
}

export default React.memo(OptionFieldEditor)
