import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  OSidemenu,
  ORow,
  OCol,
  OLogo,
  showSuccess,
  OToggleSwitch
} from '@dnvgl-onefoundation/onedesign-react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { helper, groups, api } from '../../../utils'
import AdminUsersList, { unassignedGroup } from './AdminUsersList'
import {
  GroupTypeEnum,
  TeamRightFlag,
  User,
  UserGroupItem,
  UserIdentity
} from '../../../interfaces'
import {
  getGroups,
  checkExternalUsers,
  setTeamRight,
  actions
} from '../../../store/slices/userGroups'
import { SelectItem } from '../../helpers'

interface Props {
  currentUser: User
}

const getMenuIconClass = (group: UserGroupItem) =>
  group.id === unassignedGroup.id
    ? 'fad fa-users-slash text-gray'
    : group.isInternal
    ? 'fab fa-fort-awesome'
    : groups.getIconClass(group.type)

const AdminUsers = (props: Props) => {
  const { currentUser } = props
  const [allCompanyUsers, setAllCompanyUsers] = useState([] as UserIdentity[])
  const isInternal = helper.isInternal(currentUser)

  const { userGroups, externalUsers } = useAppSelector(
    state => state.userGroups
  )

  const dispatch = useAppDispatch()

  const refresh = useCallback(() => {
    dispatch(actions.resetUserGroups())
    dispatch(getGroups())
  }, [dispatch])

  useEffect(() => {
    refresh()
    const companyIds = helper.getUserCompanyIds(currentUser)

    if (companyIds) companyIds.forEach(id => dispatch(checkExternalUsers(id)))
  }, [refresh, currentUser, dispatch])

  useEffect(() => {
    const companyIds = helper.getUserCompanyIds(currentUser)
    if (companyIds?.length) {
      let foundUsers: User[] = []
      companyIds.forEach(id => {
        if (externalUsers?.[id]?.length) {
          foundUsers = [...foundUsers, ...externalUsers?.[id]]
        }
      })
      setAllCompanyUsers(foundUsers)
    }
  }, [currentUser, externalUsers])

  const addTeamMembers = (group: UserGroupItem, userIds: string[]) => {
    if (userIds?.length)
      api.groups
        .addUsers(group.id, userIds)
        .then(() =>
          showSuccess('Users added', `"${group.name}" successfully updated!`)
        )
        .finally(refresh)
  }

  const routes = useMemo(() => {
    const groups = isInternal
      ? [unassignedGroup].concat(userGroups)
      : userGroups

    return groups?.map((group: UserGroupItem) => {
      return {
        name: (
          <span
            id={`org-${group.id}`}
            title={group.name}
            className="elipsis-name">
            {group.name}
          </span>
        ) as ReactNode,
        path: `/admin/UserGroups/${group.id}`,
        iconClass: `${getMenuIconClass(group)} ${
          group.type === GroupTypeEnum.Team && 'ml-3 text-small'
        }`,
        component: (
          <ORow className="mx-1 mt-3">
            <OCol col="12">
              {group.isInternal ? (
                <OLogo style={{ maxWidth: '150px' }} />
              ) : (
                <span className="d-inline-flex align-items-start justify-content-between w-100">
                  <h1 className="d-inline-flex mt-0">
                    <small
                      className={`${getMenuIconClass(
                        group
                      )} mr-3 mt-2`}></small>
                    {group.name}
                  </h1>
                  {group.type === GroupTypeEnum.Team && (
                    <span
                      style={{ minWidth: 550 }}
                      className="d-inline-flex align-items-center justify-content-end">
                      <OToggleSwitch
                        checked={
                          ((group?.rights ?? 0) & TeamRightFlag.GetNotifications) >
                          0
                        }
                        onChange={e =>
                          e &&
                          dispatch(
                            setTeamRight(
                              group.id,
                              TeamRightFlag.GetNotifications,
                              e.target.checked
                            )
                          )
                        }
                        small
                        className="pointer"
                        textLocation="hidden">
                        Receive Notifications
                      </OToggleSwitch>
                      <span style={{ width: 10 }} />
                      <OToggleSwitch
                        checked={
                          ((group?.rights ?? 0) & TeamRightFlag.EditProjects) >
                          0
                        }
                        onChange={e =>
                          e &&
                          dispatch(
                            setTeamRight(
                              group.id,
                              TeamRightFlag.EditProjects,
                              e.target.checked
                            )
                          )
                        }
                        small
                        className="pointer"
                        textLocation="hidden">
                        Write access
                      </OToggleSwitch>
                      {!!allCompanyUsers?.length && (
                        <span className="ml-2">
                          <SelectItem
                            titleText="Add Team Members"
                            items={allCompanyUsers}
                            multiselect
                            buttonText="Add Team Members"
                            iconClass="fal fa-users-medical"
                            variant="flat"
                            okText="Add Team Members"
                            tooltipContent="Add Team Members"
                            onSelected={(userIds: string[]) =>
                              addTeamMembers(group, userIds)
                            }
                          />
                        </span>
                      )}
                    </span>
                  )}
                </span>
              )}
            </OCol>
            <OCol col="12">
              <AdminUsersList
                currentUser={currentUser}
                currentUserGroup={group}
              />
            </OCol>
          </ORow>
        )
      }
    })
  }, [currentUser, isInternal, userGroups, allCompanyUsers]) // eslint-disable-line react-hooks/exhaustive-deps

  return <OSidemenu routes={routes} variant="light" icons collapsed={false} />
}

export default AdminUsers
