import React, { useState, useMemo, useCallback } from 'react'
import {
  OButton,
  OModal,
  OField,
  FieldType,
  ORow,
  OCol
} from '@dnvgl-onefoundation/onedesign-react'
import {
  FieldsGroupCondition,
  FieldsGroupTemplate,
  FieldTemplate,
  FormGroupType,
  isOptionsField,
  QuotationFormStepTemplate
} from '../../../interfaces/FormTemplate'
import AddFieldForm from './AddFieldForm'
import QuotationFormGroup from '../../quotations/QuotationFormGroup'
import FieldsGroupConditionEditor from './FieldsGroupConditionEditor'
import { useAppDispatch } from '../../../store/hooks'

interface Props {
  steps: QuotationFormStepTemplate[]
  onAdd: (group: FieldsGroupTemplate, stepIndex: number) => void
}

interface Form {
  stepIndex: number
  conditionIndex?: number
  group: FieldsGroupTemplate
}

const initialState: Form = {
  stepIndex: 0,
  group: {
    name: '',
    displayName: '',
    condition: {
      type: FormGroupType.Condition,
      masterFieldName: '',
      options: {}
    }
  }
}

const AddFieldsGroupForm = (props: Props) => {
  const { steps, onAdd } = props
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [form, setForm] = useState<Form>(initialState)

  const dispatch = useAppDispatch()

  const showModal = () => {
    if (steps.length) {
      setForm(initialState)
      setIsModalVisible(true)
    }
  }

  const hideModal = useCallback(() => setIsModalVisible(false), [])

  const onCancel = () => {
    if (isModalVisible) {
      hideModal()
      setForm(initialState)
    }
  }

  const onOk = () => {
    if (isModalVisible) {
      hideModal()
      onAdd(form.group, form.stepIndex)
      setForm(initialState)
    }
  }

  const updateStepIndex = useCallback(
    (index: string) =>
      setForm(x => ({ ...x, stepIndex: Number.parseInt(index) })),
    []
  )

  const updateName = useCallback(
    (name: string) => {
      setForm(x => ({ ...x, group: { ...x.group, name } }))
    },
    [dispatch]
  )

  const updateDisplayName = useCallback(
    (displayName: string) => {
      setForm(x => ({ ...x, group: { ...x.group, displayName } }))
    },
    [dispatch]
  )

  const updateCondition = useCallback(
    (condition?: FieldsGroupCondition) => {
      setForm(x => ({ ...x, group: { ...x.group, condition } }))
    },
    []
  )

  const addGroupField = useCallback(
    (field: FieldTemplate) => { 
      setForm(x => ({ ...x, group: { ...x.group, fields: [...(x.group.fields ?? []), field]} }))
    },
    []
  )

  const stepOptions = useMemo(
    () =>
      steps.map((u: QuotationFormStepTemplate, key: number) => {
        return {
          value: key,
          text: u.title
        }
      }),
    [steps]
  )

  const addField = (field: FieldTemplate, stepIndex: number) => {
    addGroupField(field)
  }

  let data = {}

  const isEnabled = steps.length && steps.some(s => s.fields?.some(f => isOptionsField(f) || f.type === 'number'))
  const nameNotUnique = steps?.[form.stepIndex] ? steps?.[form.stepIndex].groups?.some(g => g.name === form.group.name) : false
  const isValid = !!form.group.name 
    && !nameNotUnique
    && !!form.group.condition?.masterFieldName
    && !!form.group.fields?.length
    && !!form.group.condition?.options

  const bodySlot = (
    <><ORow className="text-left">
      <OCol col="12">
        <OField
          onChange={e => updateStepIndex(`${e.value}`)}
          value={form}
          field={{
            heading: 'Step',
            name: 'stepIndex',
            type: FieldType.Select,
            optionalTag: false,
            options: stepOptions
          }} />
        <OField
          value={form.group}
          validated={nameNotUnique ? true : undefined}
          onChange={e => 
            updateName(e.value as any)
          }
          field={{
            name: 'name',
            heading: 'Name',
            type: FieldType.Input,
            validate:() => {return !nameNotUnique;},
            invalidFeedback:'Name must be not empty and unique',
          }} />
        <OField
          value={form.group}
          onChange={e => 
            updateDisplayName(e.value as any)
          }
          field={{
            name: 'displayName',
            heading: 'Display Name',
            type: FieldType.Input,
            help: "Use {{#}} placeholder for group number",
            optionalTag: true
          }} />
      </OCol>
      </ORow>
        <ORow>
          <OCol col='12'>
            <FieldsGroupConditionEditor
              step={steps[form.stepIndex]}
              condition={form.group.condition}
              setCondition={(condition?: FieldsGroupCondition) => {
                updateCondition(condition) 
              } } />
          </OCol>
        </ORow>
    <ORow>
      <OCol>
        <AddFieldForm onAdd={addField} showStep={false} group={form.group} />
      </OCol>
    </ORow>
    <p>
        <strong>Preview</strong>
      </p>
    <ORow>
      <OCol>
        <QuotationFormGroup 
          group={form.group}
          isEditAllowed={true}
          isPreview={true}
          data={data}        
        />
      </OCol>
    </ORow>
    </>
  )

  return (
    <>
      <OButton
        onClick={showModal}
        disabled={!isEnabled}
        size="small"
        iconClass="fal fa-plus"
        variant="flat">
        Add Special Group
      </OButton>
      <OModal
        visible={isModalVisible}
        size="md"
        hideCloseButton
        okText="Add"
        okDisabled={!isValid}
        clickOutside={false}
        titleText={form.group.condition?.type === FormGroupType.Multiplication ? "Add Muliplication Group" : "Add Conditional Group"} 
        bodySlot={bodySlot}
        onOk={onOk}
        onCancel={onCancel}
      />
    </>
  )
}

export default React.memo(AddFieldsGroupForm)
