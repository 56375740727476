import { OBlock } from '@dnvgl-onefoundation/onedesign-react'
import React from 'react'
import { FieldsGroupTemplate, FormGroupType } from '../../interfaces/FormTemplate'
import QuotationFormFields from './QuotationFormFields'

interface Props {
  group: FieldsGroupTemplate
  data: any
  isEditAllowed?: boolean
  isPreview?: boolean
  onValueChanged?: (fieldName: string, value: string, checked?: boolean, groupName?: string, groupIndex?: number) => void
}

function QuotationFormGroup(props: Props) {
  const { group, data, isEditAllowed, isPreview, onValueChanged } = props

  const getRepeatCount = (type?: FormGroupType, masterFieldValue?: any, options?: Record<string, number>) : number => {

    if (isPreview) return 1;

    if (!masterFieldValue) return 0;

    if (type === FormGroupType.Condition) {
      return !! options ? Object.keys(options)
        .filter(o => options[o] > 0)
        .find(x => masterFieldValue === x || masterFieldValue.includes(x)) ? 1 : 0
        : 0;
    }

    if (type === FormGroupType.Multiplication) {
      if (Number.isInteger(Number(masterFieldValue)) && (!options || Object.keys(options).length === 0)) 
        return Number(masterFieldValue) > 0 ? Number(masterFieldValue) : 0;
      if (Array.isArray(masterFieldValue) && options) 
        return masterFieldValue.reduce((sum, current) => sum + (options[current] ?? 0), 0);
      if (typeof masterFieldValue === 'string' && options)
        return options[masterFieldValue] ?? 0;
    }

    return 0;
  }

  const repeatCount = getRepeatCount(group?.condition?.type,
    group?.condition?.masterFieldName ? data?.[group?.condition?.masterFieldName] : undefined,
    group?.condition?.options);

  return (
    <>
    {repeatCount > 0 && group.condition?.type === FormGroupType.Condition &&  (
      <OBlock 
      title={group.displayName || group.name}
      key={group.name}
    >
      <QuotationFormFields 
        fields={group.fields}
        data={data?.[group.name]}
        isEditAllowed={isEditAllowed}
        groupName={group.name}
        onValueChange = {onValueChanged}
      />
    </OBlock>
    )}
    {repeatCount > 0 && group.condition?.type === FormGroupType.Multiplication &&  (
      [...Array(repeatCount)].map((i, index) => (
        <OBlock 
        title={(group.displayName || group.name).replace("{{#}}", (index + 1).toString())}
        key={group.name + index.toString()}
      >
        <QuotationFormFields 
          key={group.name + index.toString() + "f"}
          fields={group.fields}
          data={data?.[group.name]?.[index]}
          isEditAllowed={isEditAllowed}
          groupName={group.name}
          groupIndex={index}
          onValueChange = {onValueChanged}
        />
      </OBlock>
      ))
    )}
    </>
  )
}

export default React.memo(QuotationFormGroup)
