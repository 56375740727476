import React from 'react'
import { ContentSection } from '../../layout'
import { getFeedbackLink } from '../../layout/Feedback'

const ContactSupportSnippet = () => (
  <ContentSection title="Need help?">
    <p>
      Please <a href={getFeedbackLink()}> send email</a> to the DNV Direct
      development team.
    </p>
  </ContentSection>
)

export default React.memo(ContactSupportSnippet)
