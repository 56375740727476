import { QuotationDetails, QuotationTechnicalDetails } from '../interfaces'

import config from './config'

export interface TechnicalDetailsValidationResult {
  isValid: boolean
  invalidFields: string[] | null
}

const isCustomerDetailsValid = (quotationDetails: QuotationDetails) => {
  let isValid = true
  config.quotationDetails.requiredFields.forEach(i => {
    const value = (quotationDetails as any)?.[i]
    if (value === null || value === undefined) isValid = false
  })
  return isValid
}

const validateTechnicalDetails = (
  technicalDetails: QuotationTechnicalDetails | null
) : TechnicalDetailsValidationResult  => {

  if (technicalDetails === null) return { isValid: false, invalidFields: null }

  const { data, form } = technicalDetails
  if (!data || !form) return { isValid: false, invalidFields: null }

  let isValid = true;
  let invalidFields:string[] = [];

  form.forEach((step: any) => {
    const stepTitle = step?.title
    step?.fields?.forEach((field: any) => {
      if (!isFieldValid(field, data?.[stepTitle]?.[field.name]))
      {
        isValid = false;
        invalidFields.push(field.name as string);
      }
    })
  })

  return {
    isValid: isValid,
    invalidFields: invalidFields
  }
}

const isFieldValid = (field: any, data: any) : boolean => {
  if (!field || !data) return false;
  if (field.type === "checkbox" && field.options.length > 1) {
    return data.length > 0;
  }
  return true;
}

const validator = {
  isCustomerDetailsValid,
  validateTechnicalDetails
}

export default validator
