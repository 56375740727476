import React from 'react'
import { OBadge, OButton, OLogo, OModal } from '@dnvgl-onefoundation/onedesign-react'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'

import { Message, User } from '../../interfaces'
import DiscussionPage from './DiscussionPage'

import styles from './Discussions.module.css'

import { momentHelper, config, api, helper } from '../../utils'

const { getPastDate } = momentHelper
const { cdnResources, messagesCheckInterval } = config

export interface MessageViewModel extends Message {
  senderDisplayName: ReactNode
}

export interface Discussion {
  messages: Message[]
  messagesCount?: number
}

interface Props {
  objectId: string
  currentUser: User
  isInternalUser: boolean
  isReadOnly: boolean
  title: string
  pushMessageApi(id: string, content: string):Promise<string>
  readMessages(id: string):Promise<Message[]>
  readMessagesCount(id:string):Promise<number>
}

const Discussions = (props: Props) => {
  const {
    objectId, 
    currentUser,
    isInternalUser,
    isReadOnly,
    title,
    pushMessageApi,
    readMessages,
    readMessagesCount
  } = props

  const [isVisible, setIsVisible] = useState(false)
  const [discussion, setDiscussion] = useState<Discussion>({
    messages: []
  })

  const buildInternalUserHeader = (displayName: string): ReactNode => (
    <div>
      <OLogo className={styles.messageLogo} />
      <span>{displayName}</span>
    </div>
  )

  const mapMessage = (message: Message): MessageViewModel => {
    const displayName = `${message.senderFirstName} ${
      message.senderLastName ?? ''
    }`
    return {
      ...message,
      senderDisplayName: message.isFromInternalUser
        ? buildInternalUserHeader(displayName)
        : displayName
    }
  }

  const messages = useMemo(
    () => discussion.messages.map(mapMessage),
    [discussion.messages]
  )

  const messagesContainer = useRef<HTMLDivElement>(null)
  const scrollBottom = useMemo(
    () => () => {
      const el: any = messagesContainer?.current
      if (el) el.scrollTo({ top: el.scrollHeight, behavior: 'smooth' })
    },
    []
  )

  const [sentSound, setSentSound] = useState(null as HTMLAudioElement | null)

  const handleClose = () => setIsVisible(false)

  useEffect(() => {
    if (discussion.messages.length !== discussion.messagesCount && readMessages) {
        readMessages(objectId)
        .then(messages => {
          setDiscussion({
            messages,
            messagesCount: messages.length
          })
          setTimeout(scrollBottom, 200)
        })
        .catch(helper.handleErrorMessage)
      }
  }, [objectId, discussion])

  useEffect(() => {
    const { baseUrl, sentMessageSound } = cdnResources
    const sound = new Audio(`${baseUrl}/${sentMessageSound}`)
    sound.volume = 0.3
    setSentSound(sound)
  }, [])

  useEffect(() => {
    if (readMessagesCount) {
      const interval = setInterval(() => {
        readMessagesCount(objectId).then(messagesCount => {
          setDiscussion(d =>
            d.messagesCount === messagesCount ? d : { ...d, messagesCount }
          )
        })
      }, messagesCheckInterval)
      return () => clearInterval(interval)
    }
  }, [objectId])

  const pushMessage = (content:string) => {
    pushMessageApi(objectId, content)
      .then(id => {
        const newMessage: Message = {
          id,
          senderId: currentUser.id,
          isFromInternalUser: isInternalUser,
          senderFirstName: currentUser.firstName ?? '',
          senderLastName: currentUser.lastName ?? '',
          content,
          sendAt: getPastDate()
        }

        setDiscussion({
          ...discussion,
          messages: [...discussion.messages, newMessage]
        })
        setTimeout(scrollBottom, 200)
        sentSound?.play()
      })
      .catch(helper.handleErrorMessage)
  }

  return (
    <>
      <OButton
        iconClass="fal fa-comments"
        variant="flat"
        className="align-self-center"
        onClick={() => {
          setIsVisible(true)
          setTimeout(scrollBottom, 500)
        }}>
        Discussions
        {!!discussion.messagesCount && (
          <OBadge variant="secondary" outline className="ml-1 p-2">
            {discussion.messagesCount}
          </OBadge>
        )}
      </OButton>
      <OModal
        visible={isVisible}
        size="lg"
        clickOutside={false}
        titleText={
          <span className="d-flex">
            <i className="fad fa-comments fa-lg mr-3"></i>
            {title}
          </span>
        }
        bodySlot={<DiscussionPage 
          currentUser={currentUser}
          isInternalUser={isInternalUser}
          isReadOnly={isReadOnly}
          pushMessage={pushMessage}
          messages={messages}
          messagesContainer={messagesContainer}
        />}

        onOk={handleClose}
        onCancel={handleClose}
        onClose={handleClose}
        className="discussions-modal"
      />
    </>
  )
}

export default React.memo(Discussions)
