import { OButton } from '@dnvgl-onefoundation/onedesign-react'
import React, { ReactNode } from 'react'

interface Props {
  showReset?: boolean
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  reset = () => this.setState({ hasError: false })

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-center">
          <h2 className="text-danger">Parsing error</h2>
          <p>Please fix the issue and press Refresh button.</p>
          {this.props.showReset && (
            <OButton
              variant="secondary"
              iconClass="fas fa-redo"
              onClick={this.reset}>
              Refresh
            </OButton>
          )}
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
