import React, { useState } from 'react'
import { OAlert } from '@dnvgl-onefoundation/onedesign-react'

interface Props {
  message: string
}

const HelperText = (props: Props) => {
  const { message } = props
  const [isVisible, setIsVisible] = useState(true)

  return isVisible ? (
    <OAlert
      onDismiss={() => setIsVisible(false)}
      variant="info"
      description={message}
      dismissable
    />
  ) : null
}

export default React.memo(HelperText)
