import React from 'react'
import Page from '../../layout/Page'
import ContentSection from '../../layout/ContentSection'
import { OTable } from '@dnvgl-onefoundation/onedesign-react'
import { AppName } from '../../helpers'

const PrivacyPage = () => (
  <Page title="Cookies policy" isFullWidthTitle>
    <ContentSection className="pt-4 pb-5">
      <p>
        The{' '}
        <a
          href="https://id.veracity.com/terms-of-use"
          target="_blank"
          rel="noreferrer">
          Veracity platform cookies policy
        </a>{' '}
        defines how we use cookies.{' '}
      </p>{' '}
      <p>
        <AppName /> is using following strictly necessary cookies.
      </p>
      <OTable
        small
        showOptionsButton={false}
        showFilterButton={false}
        showColumnsButton={false}
        fields={[
          { name: 'url', heading: 'Domain' },
          { name: 'cookie', heading: 'Name' },
          { name: 'use', heading: 'Set and/or retrieved by' },
          { name: 'lifespan', heading: 'Lifespan' }
        ]}
        allItems={[
          {
            url: 'direct.dnv.com',
            name: '.AspNetCore.Cookies',
            use: '1st Party',
            lifespan: 'Session'
          },
          {
            url: 'direct.dnv.com',
            name: '.AspNetCore.Antiforgery',
            use: '1st Party',
            lifespan: 'Session'
          },
          {
            url: 'direct.dnv.com',
            name: 'RequestVerificationToken',
            use: '1st Party',
            lifespan: 'Session'
          },
          {
            url: 'direct.dnv.com',
            name: 'ARRAffinity',
            use: '1st Party',
            lifespan: 'Session'
          },
          {
            url: 'direct.dnv.com',
            name: 'ARRAffinitySameSite',
            use: '1st Party',
            lifespan: 'Session'
          },
          {
            url: 'direct.dnv.com',
            name: 'ai_session',
            use: '1st Party',
            lifespan: '30 minutes'
          },
          {
            url: 'direct.dnv.com',
            name: 'ai_user',
            use: '1st Party',
            lifespan: '1 year'
          }
        ].map(x => ({
          ...x,
          cookie: (
            <a
              href={`https://cookiepedia.co.uk/cookies/${x.name}`}
              target="_blank"
              rel="noreferrer">
              {x.name}
            </a>
          )
        }))}
      />
      <p>
        These cookies are necessary for the <AppName /> to function and cannot
        be switched off in our systems. They are only set in response to actions
        made by you such as logging in. You can set your browser to block or
        alert you about these cookies, but some parts of the site will not then
        work. These cookies do not store any personally identifiable
        information.
      </p>
    </ContentSection>
  </Page>
)

export default React.memo(PrivacyPage)
