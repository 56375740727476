import React from 'react'

const EmptyListIndicator = () => (
  <div className="text-center p-4">
    <i className="fal fa-folder-open"></i>&nbsp;The list does not contain any
    items.
  </div>
)

export default React.memo(EmptyListIndicator)
