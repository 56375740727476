import { GroupTypeEnum } from '../interfaces'

const getIconClass = (groupType: GroupTypeEnum, variant = 'fad') =>
  variant + (groupType === GroupTypeEnum.Team ? ' fa-users' : ' fa-building')

const getDisplayType = (groupType: GroupTypeEnum) =>
  groupType === GroupTypeEnum.Company ? 'Company' : 'Team'

const groups = {
  getIconClass,
  getDisplayType
}

export default groups
