import { OCol, GridColumns } from '@dnvgl-onefoundation/onedesign-react'
import React, { ReactNode } from 'react'

interface Props {
  title: string
  gridColumns: GridColumns
  disabled?: boolean
  children: ReactNode
}

const DetailsItem = (props: Props) => {
  const { title, gridColumns, disabled, children } = props
  return (
    <OCol
      md={gridColumns}
      sm="12"
      className={`mb-3 ${disabled ? 'is-disabled' : ''}`}
      align="center">
      <strong>{title}</strong>
      <div className="mt-1">{children ? children : null}</div>
    </OCol>
  )
}

export default React.memo(DetailsItem)
