import { ReactNode } from 'react'
import { Address, AdminProjectItem, ProjectItem, QuotationItem } from './BackendGenerated'
import { HeaderRoute } from '@dnvgl-onefoundation/onedesign-react'

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export interface AdminProjectItemExtended extends AdminProjectItem {
  companyName: string
  contactPerson: string
  statusName?: ReactNode
  actions?: ReactNode
}

export interface SelectableItem {
  isSelected?: ReactNode
}

export interface ItemSelectionState
{
  itemId: string
  isSelected: boolean
}
export type ItemsSelection = Array<ItemSelectionState>;

export interface AdminProjectItemExtendedSel extends AdminProjectItemExtended, SelectableItem
{
}

export interface ProjectItemExtended extends ProjectItem {
  teams?: ReactNode
  statusName?: ReactNode
  customerContactName?: string
  actions?: ReactNode
}

export enum ProjectDocumentsSection {
  Documents = 'Files',
  Deliverables = 'Deliverables'
}

export interface HeaderRouteExtended extends HeaderRoute {
  isCompanySpecific?: boolean
}

export type value = string | number | boolean | Address | undefined

export interface QuotationItemExtended extends QuotationItem, SelectableItem {
  actions?: ReactNode
}

export enum QuotationTab {
  Technical = 0,
  CustomerInfo = 1,
  Documents = 2,
  Agreement = 3,
  PurchaseOrder = 4
}

export * from './BackendGenerated'
