import { useCallback, useMemo } from 'react'
import { GroupTypeEnum } from '../../../interfaces'
import { useAppSelector } from '../../../store/hooks'

export function useUserGroups() {
  const groups = useAppSelector(state => state.userGroups.userGroups)
  const companies = useMemo(
    () => groups?.filter(x => x.type === GroupTypeEnum.Company),
    [groups]
  )

  const groupsDictionary = useMemo(
    () => new Map(groups.map(g => [g.id, g])),
    [groups]
  )

  const getGroup = useCallback(
    (groupId: string | undefined) =>
      groupId !== undefined ? groupsDictionary.get(groupId) : undefined,
    [groupsDictionary]
  )

  const getCompany = useCallback(
    (groupId: string | undefined) => {
      const currentGroup = getGroup(groupId)
      if (currentGroup?.type === GroupTypeEnum.Company) {
        return currentGroup
      } else if (currentGroup?.companyId) {
        return getGroup(currentGroup.companyId)
      }
      return undefined
    },
    [getGroup]
  )

  return { groups, companies, getGroup, getCompany }
}
