import React from 'react'
import { QuotationFormStepTemplate } from '../../interfaces/FormTemplate'
import QuotationFormFields from './QuotationFormFields'
import QuotationFormGroups from './QuotationFormGroups'

interface Props {
  step: QuotationFormStepTemplate
  data: any
  isEditAllowed?: boolean
  setProperty?: (name: string, value: string, checked?: boolean, groupName?: string, groupIndex?: number) => void
  onChange?: () => void
}

function QuotationFormStep(props: Props) {
  const { step, data, isEditAllowed, setProperty, onChange } = props

  const onFieldValueChanged = (name: string, value: string, checked?: boolean, groupName?: string, groupIndex?: number) => {
    onChange?.()
    setProperty?.(name, value, checked, groupName, groupIndex)
  }

  return (
    <div className="p-3" style={{ minHeight: '20rem' }}>
      {!!step?.intro?.length && (
        <div className="mb-4">
          {step.intro.map((x: string, i: number) => (
            <p key={i}>{x}</p>
          ))}
        </div>
      )}
      {step?.fields?.length ? (
          <QuotationFormFields 
            fields={step.fields}
            data={data}
            isEditAllowed={isEditAllowed}
            onValueChange={onFieldValueChanged}
          />
      ) : (
        <p>&nbsp;</p>
      )}
      <div>
        <QuotationFormGroups
          step={step}
          data={data}
          isEditAllowed={isEditAllowed}
          onValueChanged={onFieldValueChanged}
        />
      </div>
    </div>
  )
}

export default React.memo(QuotationFormStep)
