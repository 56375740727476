import React, {useState, useEffect, useCallback} from 'react'

import { showSuccess, FileDrop } from '@dnvgl-onefoundation/onedesign-react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../../store'
import { api, helper, config } from '../../utils'
import { deleteConfirm } from '../modals'
import {
  readProjectFiles
} from '../../store/slices/projects'
import { SpinIndicator } from '../layout'
import { FileUpload, FileList } from '../helpers'
import { FileItem, ProjectDocumentsSection } from '../../interfaces'

interface Props {
  projectId: string
  section: ProjectDocumentsSection
  isInternalUser: boolean
  isReadOnly: boolean
  hasMessage: boolean
}

const ProjectDocumentsView = (props: Props) => {
  const { projectId, section, isInternalUser, isReadOnly, hasMessage} = props
  const [isUploading, setIsUploading] = useState(false)
  const dispatch = useDispatch()

  const { endpoints } = config

  const { projectFiles, isLoadingProjectFiles } = useSelector(
    (state: ApplicationState) => state.projects
  )

  const apiSection = isInternalUser ? api.projectsAdministration : api.projects

  const refresh = useCallback(() => {
    dispatch(readProjectFiles(projectId, section, isInternalUser))
  }, [dispatch, projectId, section, isInternalUser])

  useEffect(() => {
    refresh()
  }, [refresh])

  const onDelete = (file: FileItem) =>
    deleteConfirm(file.name).then(confirmed => {
      if (confirmed)
        apiSection
          .deleteFile(projectId, section, file.path)
          .then(refresh)
          .catch(helper.handleErrorMessage)
    })

  const onUpload = (files: FileDrop[], message: string | undefined) => {
    if (!files?.length) return;
    const fileNames = files.map(file => file.file.name);
    setIsUploading(true);
    const uploadFunction = hasMessage && !!message ? 
      apiSection
      .uploadFilesWithMessage(
        `${projectId}`,
        section,
        files.map(x => x.file),
        message as string
      )
      : apiSection
      .uploadFiles(
        `${projectId}`,
        section,
        files.map(x => x.file)
      );

      uploadFunction.then(() => {
        showSuccess('Uploaded', fileNames.join(', '))
        refresh()
      })
      .catch(helper.handleErrorMessage)
      .finally(() => setIsUploading(false))
  }

  const downloadUrlBuilder = (path: string) => {
    const baseUrl = isInternalUser
      ? endpoints.projectsAdmin
      : endpoints.projects

    return `${baseUrl}/${projectId}/${section}/${path}`
  }

  return isLoadingProjectFiles ? (
    <SpinIndicator />
  ) : (
    <>
      <h3>Upload Files</h3>
      <FileUpload
        isUploadAllowed={!isReadOnly}
        isUploading={isUploading}
        maxFiles={25}
        maxBytes={250000000}
        acceptExtensions=".pdf, .docx, .xlsx, .msg, .doc, .xls, .zip, .7z, .gif, .png, .jpg, .jpeg"
        hasMessage={hasMessage}
        message={projectFiles.uploadMessage}
        onUpload={onUpload}
      />
      <h3>Documents</h3>
      <FileList
        projectId={projectId}
        isInternalUser={isInternalUser}
        files={projectFiles[section]}
        isDeleteAllowed={!isReadOnly}
        onDelete={onDelete}
        downloadUrlBuilder={downloadUrlBuilder}
      />
    </>
  )
}

export default React.memo(ProjectDocumentsView)
