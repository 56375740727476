import React from 'react'

import {
  OButton,
  ORow,
  OCol,
  FileDrop,
  OAlert
} from '@dnvgl-onefoundation/onedesign-react'

import { FileItemButton, FileUpload } from '../../helpers'
import { config, momentHelper } from '../../../utils'
import { AgreementFile } from '../../../interfaces'
import { useAppSelector } from '../../../store/hooks'

interface Props {
  agreementFile: AgreementFile
  companyName?: string
  isAwaitingSign: boolean
  isInternalUser: boolean
  isInternalActionsEnabled: boolean
  downloadAgreementUrlBuilder: (path: string) => string
  downloadSignedUrlBuilder: (path: string) => string
  onDelete: (path: string) => void
  onSignedFileUpload: (agreementId: string, files: FileDrop[]) => void
  onAccepted: (agreementFile: AgreementFile) => void
  onRejected: (agreementFile: AgreementFile) => void
}

const QuotationPageAgreementItem = (props: Props) => {
  const {
    agreementFile,
    companyName,
    isAwaitingSign,
    isInternalUser,
    isInternalActionsEnabled,
    downloadAgreementUrlBuilder,
    downloadSignedUrlBuilder,
    onDelete,
    onSignedFileUpload,
    onAccepted,
    onRejected
  } = props

  const { toHumandFriendlyDateTime } = momentHelper

  const onUpload = (files: FileDrop[]) =>
    onSignedFileUpload(agreementFile.id, files)

  const { acceptance, rejection } = agreementFile

  const acceptSfaWithoutFile = useAppSelector(
    state => state.configuration.featureToggles.acceptSfaWithoutFile
  )
  
  const isAcceptDisabled = !acceptSfaWithoutFile && !agreementFile?.signedFile

  return (
    <OCol col="12" className="border m-2 p-2 mb-4">
      <ORow>
        {!!acceptance && (
          <OCol md="12">
            <OAlert
              message="Accepted:"
              description={`The agreement accepted by ${
                acceptance.acceptedBy?.firstName
              } ${acceptance.acceptedBy?.lastName}, ${toHumandFriendlyDateTime(
                acceptance.acceptedAt
              )}`}
              variant="success"
              dismissable={false}
            />
          </OCol>
        )}
        {!!rejection && (
          <OCol md="12">
            <OAlert
              message="Rejected:"
              description={`The agreement rejected by ${
                rejection.rejectedBy?.firstName
              } ${rejection.rejectedBy?.lastName}, ${toHumandFriendlyDateTime(
                rejection.rejectedAt
              )}. Reason: ${rejection.reason}`}
              variant="danger"
              dismissable={false}
            />
          </OCol>
        )}
        <OCol md="5" sm="12">
          <h3>Document provided by DNV</h3>
          <div>
            <FileItemButton
              dowloadURL={downloadAgreementUrlBuilder(agreementFile.path)}
              fileItem={agreementFile}
            />
          </div>
        </OCol>

        <OCol md="5" sm="12">
          <h3>Document signed by {companyName ?? 'user'}</h3>
          {!isInternalUser &&
            isAwaitingSign &&
            !acceptance &&
            !agreementFile?.signedFile &&
            !rejection && (
              <FileUpload
                isUploadAllowed
                autoHideDropArea
                maxFiles={1}
                maxBytes={250000000}
                acceptExtensions=".pdf"
                onUpload={onUpload}
              />
            )}
          {agreementFile?.signedFile && (
            <FileItemButton
              dowloadURL={downloadSignedUrlBuilder(
                agreementFile.signedFile.path
              )}
              fileItem={agreementFile?.signedFile}
            />
          )}
          {isInternalUser && !agreementFile?.signedFile && !rejection && (
            <div className="mt-3">The document is not uploaded yet.</div>
          )}
        </OCol>

        <OCol md="2" sm="12" align="center" className="text-right">
          {isInternalUser ? (
            <div>
              <OButton
                disabled={!isInternalActionsEnabled}
                onClick={() => onDelete(agreementFile.path)}
                iconClass="fal fa-trash-alt text-danger"
                className="text-left d-inline ml-2"
                variant="flat">
                Delete
              </OButton>
            </div>
          ) : !isAwaitingSign || !!acceptance || !!rejection ? null : (
            <div>
              <OButton
                onClick={() => onAccepted(agreementFile)}
                disabled={isAcceptDisabled}
                iconClass="fal fa-check text-success mr-2"
                className="text-left"
                variant="flat">
                Accept
              </OButton>
              <OButton
                onClick={() => onRejected(agreementFile)}
                iconClass="fal fa-times text-danger"
                className="text-left"
                variant="flat">
                Reject
              </OButton>
            </div>
          )}
        </OCol>
      </ORow>
    </OCol>
  )
}

export default React.memo(QuotationPageAgreementItem)
