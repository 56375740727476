import React, { CSSProperties } from 'react'
import { config } from '../../utils'

interface Props {
  statusName?: string
  style?: CSSProperties
}

const StatusIndicator = (props: Props) => {
  const { statusName, style } = props
  const { statusColors } = config
  const color = (statusColors as any)?.[`${statusName}`]
    ? (statusColors as any)?.[`${statusName}`]
    : statusColors?.default

  return (
    <>
      <i style={{ ...style, color }} className="fas fa-circle mr-1"></i>&nbsp;
      {statusName}
    </>
  )
}

export default React.memo(StatusIndicator)
