import React from 'react'
import {
  OTable,
  TableAction,
  TableField,
  TableItem
} from '@dnvgl-onefoundation/onedesign-react'
import { AdminProjectItemExtended, ProjectItemExtended } from '../../interfaces'

import { deleteConfirm } from '../modals'
import { config } from '../../utils'

interface Props {
  items: AdminProjectItemExtended[] | ProjectItemExtended[]
  isInternalUser: boolean
  showStatusName?: boolean
  showFilterButton?: boolean
  onFilter?: (field: any) => void
  onOpen: (id: string) => void
}

const ProjectsList = (props: Props) => {
  const { items, isInternalUser, showStatusName, showFilterButton, onFilter, onOpen } = props
  const { projectsList } = config

  const openProject = (project?: TableItem) =>
    project?.id && onOpen(`${project.id}`)

  const actions: TableAction[] = []

  const fields = isInternalUser
    ? projectsList.fields.forInternal
    : projectsList.fields.forCustomer

  return (
    <OTable
      className="selectable-table"
      allItems={items}
      rowsPerPage={projectsList.rowsPerPage}
      fields={fields.filter(
        field => showStatusName || field.name !== 'statusName'
      )}
      actions={actions}
      small
      selectMode={false}
      showActionIcons
      showColumnsButton={false}
      showOptionsButton={false}
      showFilterButton={showFilterButton}
      onFilter={onFilter}
      onFocusRowChange={openProject}
    />
  )
}

export default React.memo(ProjectsList)
