import React, { useState } from 'react'
import { OModal, OTooltip } from '@dnvgl-onefoundation/onedesign-react'
import { QuotationStatus } from '../../interfaces'
import { config } from '../../utils'

interface Props {
  status?: QuotationStatus
  tableView?: boolean
  isInternalUser: boolean
}

const QuotationStatusBadge = (props: Props) => {
  const { status, tableView, isInternalUser } = props
  const [isVisible, setIsVisible] = useState(false)

  const titleText = 'Quotation Request Workflow'

  return (
    <>
      {tableView ? (
        <span className="d-inline-block" style={{ minWidth: '6rem' }}>
          <i
            style={{
              color: status?.colorCode,
              border: status?.colorCode === '#FFF' ? '1px solid #CCC' : 'none'
            }}
            className="fas fa-circle mr-1 rounded-circle"></i>
          &nbsp;{isInternalUser ? status?.internalDisplayText : status?.externalDisplayText}
        </span>
      ) : (
        <OTooltip content="Click here to open the workflow diagram">
          <label
            className="badge badge-outline-primary pointer"
            style={{ backgroundColor: status?.colorCode }}
            onClick={e => {
              e.stopPropagation()
              setIsVisible(true)
            }}>
            {isInternalUser ? status?.internalDisplayText : status?.externalDisplayText}
          </label>
        </OTooltip>
      )}

      <OModal
        visible={isVisible}
        okText="OK"
        size="lg"
        hideFooter
        onClose={() => setIsVisible(false)}
        clickOutside={false}
        titleText={titleText}
        bodySlot={
          <div className="p-5 w-100">
            <img
              src={`${config.cdnResources.baseUrl}/images/workflows/${isInternalUser ? "quotation-internal-flow.jpg" : "quotation-external-flow.jpg"}`}
              alt={titleText}
              className="w-100"
            />
          </div>
        }
      />
    </>
  )
}

export default React.memo(QuotationStatusBadge)
