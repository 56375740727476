import React, { useEffect, useMemo, useState } from 'react'

import {
  ORow,
  OCol,
  OBadge,
  OButton,
  ODragAndDrop,
  FileDrop,
  OAlert
} from '@dnvgl-onefoundation/onedesign-react'
import { SpinIndicator } from '../layout'
import styles from './FileUpload.module.css'

interface Props {
  isUploadAllowed: boolean
  isUploading?: boolean
  autoHideDropArea?: boolean
  maxFiles?: number
  maxBytes?: number
  acceptExtensions?: string
  hasMessage?: boolean
  message?: string
  onUpload?: (files: FileDrop[], message: string | undefined) => any
}

const areValid = (drops: FileDrop[], maxFiles?: number, maxBytes?: number) => {
  if (maxFiles && drops.length > maxFiles) return false
  if (
    maxBytes &&
    drops.reduce((x, drop) => ({ total: x.total + drop.file.size }), {
      total: 0
    }).total > maxBytes
  )
    return false
  return true
}

const FileUpload = (props: Props) => {
  const {
    isUploadAllowed,
    isUploading,
    autoHideDropArea,
    maxFiles,
    maxBytes,
    acceptExtensions,
    hasMessage,
    message,
    onUpload,
  } = props
  const [innerFiles, setInnerFiles] = useState<FileDrop[]>([])
  const [showValidExtensions, setShowValidExtensions] = useState(false)
  const [canUpload, setCanUpload] = useState(true)

  const extensions = useMemo(
    () => acceptExtensions?.split(',').map(x => x.trimStart().toLowerCase()),
    [acceptExtensions]
  )

  const onDrop = (droppedFiles: FileDrop[]) => {
    const validFiles = droppedFiles.filter(x =>
      extensions?.some(ext => x.file.name.toLowerCase().endsWith(ext))
    )
    if (validFiles.length !== droppedFiles.length) setShowValidExtensions(true)
    validFiles.length && setInnerFiles([...innerFiles, ...validFiles])
  }

  const excludeFile = (id: string) =>
    setInnerFiles(innerFiles.filter(file => file.id !== id))

  useEffect(() => {
    setCanUpload(areValid(innerFiles, maxFiles, maxBytes))
  }, [innerFiles, maxFiles, maxBytes])

  const showDropArea = () => {
    if (!isUploadAllowed) return false
    if (autoHideDropArea && innerFiles?.length) return false
    return true
  }

  const [messageState, setMessage] = useState<string|undefined>(message)
  useEffect(() => {
    setMessage(message)
  }, [message])

  return (
    <>
    <ORow className={`mb-3 ${!!isUploading ? 'is-disabled' : ''}`}>
      <OCol md="12">
        {showValidExtensions && (
          <OAlert
            description={`Following file extensions are allowed: ${acceptExtensions}.`}
            onDismiss={() => setShowValidExtensions(false)}
          />
        )}
        {!canUpload && (
          <OAlert
            variant="danger"
            message="Cannot upload files."
            description={[
              maxFiles && `File number per upload cannot exceed ${maxFiles}.`,
              maxBytes &&
                `Total files size per upload cannot exceed ${
                  maxBytes / 1e9
                }GB.`,
              'Please add excessive files in another upload or add ZIP or 7-Zip archive.'
            ].join(' ')}
            dismissable={false}
          />
        )}
      </OCol>
      {showDropArea() && (
        <OCol md={innerFiles.length ? '6' : '12'}>
          <ODragAndDrop onDrop={onDrop} accept={acceptExtensions} />
        </OCol>
      )}
      {!!innerFiles?.length && (
        <OCol md='6'>
          <div className={styles.filesContainer}>
          {innerFiles.map((item, key) => (
            <OBadge outline className={styles.fileBadge} key={key}>
              <div className={styles.fileGrid}>
                <div className={styles.fileName}>{item.file.name}</div>
                <i
                  onClick={() => excludeFile(item.id)}
                  className="fal fa-times-circle text-danger pointer"></i>
              </div>
            </OBadge>
          ))}
          </div>
          {!!innerFiles?.length && !hasMessage && (
          <div className="mt-4">
            {isUploading ? (
              <SpinIndicator />
            ) : (
              <OButton
                iconClass="fal fa-cloud-upload"
                disabled={!canUpload}
                onClick={() => {
                  onUpload?.(innerFiles, message)
                  setInnerFiles([])
                } }>
                Upload
              </OButton>
            )}
          </div>
        )}
        </OCol>
      )}
      </ORow>
      {!!innerFiles?.length && hasMessage && (
        <>
        <ORow>
          <OCol md='6'>
            <div className='d-flex align-items-center'>
            <label className='mr-1'>Message:</label>
            <input
              className={`form-control`}
              value={messageState}
              onChange={
                e => setMessage(e.target.value as string)
              }
            />
            </div>
          </OCol>
          <OCol md='6'>
          <div className="float-right">
            {isUploading ? (
              <SpinIndicator />
            ) : (
              <OButton
                iconClass="fal fa-cloud-upload"
                disabled={!canUpload}
                onClick={() => {
                  onUpload?.(innerFiles, messageState)
                  setInnerFiles([])
                } }>
                Upload
              </OButton>
            )}
          </div>
          </OCol>
        </ORow>
        </>
      )}
      </>
  )
}

export default React.memo(FileUpload)
