import React, { useEffect } from 'react'

import { useMatch, useSearchParams } from 'react-router-dom'

import { config } from '../../utils'

import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  actions,
} from '../../store/slices/quotations'

const QuotationPageRedirect = () => {
  const { paths, quotationPage } = config
  const match = useMatch(`${paths.quotations}/:id`)
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get("serviceId");

  const navigate = useNavigate()

  const id: string = (match?.params as any)?.id

  useEffect(() => {
    if (id?.length) {
      if (id == config.quotationPage.newQuotationId && serviceId?.length) {
        dispatch(actions.setServiceId(serviceId))
      }

      navigate(`${paths.quotations}/${id}/${quotationPage.defaultTabUrl}`)
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default React.memo(QuotationPageRedirect)
