import React, { useCallback, useEffect, useState } from 'react'
import {
  ORow,
  OCol,
  FileDrop,
  showSuccess,
  showPrompt,
  showConfirm
} from '@dnvgl-onefoundation/onedesign-react'
import TabContent from '../../layout/TabContent'
import { helper, api, config } from '../../../utils'
import {
  EmptyListIndicator,
  FileUpload,
  HelperText,
  TabTitleIndicator,
  UpdateButton
} from '../../helpers'
import { AgreementFile, QuotationStatusEnum, QuotationTab } from '../../../interfaces'
import QuotationPageAgreementItem from './QuotationPageAgreementItem'
import { deleteConfirm } from '../../modals'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { readAgreements, readQuotationDetails, setStatus } from '../../../store/slices/quotations'
import { useNavigate } from 'react-router'

interface Props {
  disabled: boolean
  isInternalUser: boolean
  companyName?: string
  listAgreements: AgreementFile[]
  agreementUploadAllowed: boolean
  helperText: string
  agreementsAccepted: boolean
  promptToUpdate: (title: string, value: string) => void
  refreshQuotationDetails: () => void
}

const { endpoints, paths, quotationPage } = config
const { handleErrorMessage } = helper

const QuotationPageAgreementTab = (props: Props) => {
  const {
    disabled,
    isInternalUser,
    companyName,
    listAgreements,
    agreementUploadAllowed,
    helperText,
    agreementsAccepted,
    promptToUpdate,
    refreshQuotationDetails
  } = props

  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const [isUploading, setIsUploading] = useState(false)
  const quotationDetails = useAppSelector(s => s.quotations.quotationDetails)
  const id = quotationDetails?.id

  const refresh = useCallback(() => {
    if (id) {
      dispatch(readAgreements(id))
    } 
  }, [id, dispatch])

  useEffect(() => refresh(), [refresh])

  const onUpload = (files: FileDrop[]) => {
    if (!files?.length) return
    const fileNames = files.map(file => file.file.name)
    setIsUploading(true)
    api.quotations
      .uploadAgreements(
        `${id}`,
        files.map(x => x.file)
      )
      .then(() => {
        showSuccess('Uploaded', fileNames.join(', '))
        refresh()
      })
      .catch(handleErrorMessage)
      .finally(() => setIsUploading(false))
  }

  const downloadAgreementUrlBuilder = (path: string) =>
    `${endpoints.quotations}/${id}/Agreements/${path}`

  const downloadSignedUrlBuilder = (path: string) =>
    `${endpoints.quotations}/${id}/Agreements/Signed/${path}`

  const onDelete = (path: string) =>
    id &&
    deleteConfirm(path).then(confirmed => {
      if (confirmed)
        api.quotations
          .deleteAgreement(id, path)
          .then(() => {
            showSuccess('Successfully deleted.')
            refresh()
          })
          .catch(handleErrorMessage)
    })

  const onSignedFileUpload = (agreementId: string, files: FileDrop[]) =>
    id &&
    api.quotations
      .uploadSignedFile(
        id,
        agreementId,
        files.map(x => x.file)
      )
      .then(() => {
        showSuccess('Successfully uploaded.')
        refresh()
      })
      .catch(handleErrorMessage)

  const onAccepted = (agreementFile: AgreementFile) =>
    id &&
    showConfirm(
      'Accept agreement',
      `I have read the "${agreementFile.name}" agreement and accept it.`
    ).then(accepted => {
      if (accepted)
        api.quotations
          .acceptAgreement(id, agreementFile.id)
          .then(() => {
            showSuccess('Agreement accepted!', agreementFile.name)
            dispatch(readAgreements(id))
            dispatch(setStatus(id, QuotationStatusEnum.AwaitingOrder))
            })
          .catch(handleErrorMessage)
    })

  const onRejected = (agreementFile: AgreementFile) =>
    id &&
    showPrompt(`Reason for "${agreementFile.name}" rejection`).then(reason => {
      if (reason)
        api.quotations
          .rejectAgreement(id, agreementFile.id, reason)
          .then(newStatus => {
            showSuccess('Agreement rejected!', agreementFile.name)
            refresh()
            if (newStatus !== quotationDetails?.status.id) {
              refreshQuotationDetails()
            }
          })
          .catch(handleErrorMessage)
    })

  return (
    <TabContent
      title={
        <TabTitleIndicator
          title="DNV Agreement"
          isValid={agreementUploadAllowed ? null : agreementsAccepted}
        />
      }
      disabled={disabled}>
      <ORow>
        {!agreementsAccepted && !isInternalUser && !agreementUploadAllowed && (
          <OCol md="12">
            <HelperText message={helperText} />
          </OCol>
        )}
        {(isInternalUser || quotationDetails?.agreement?.trim()) && (
          <OCol md="12" sm="12" className="mb-3" align="center">
            <span>
              <strong>{'Accepted frame agreement or SFA number'}</strong>
              {isInternalUser && agreementUploadAllowed && (
                <UpdateButton
                  onClick={() =>
                    promptToUpdate(
                      'Accepted frame agreement or SFA number',
                      quotationDetails?.agreement ?? ''
                    )
                  }
                />
              )}
            </span>
            <div className="mt-1" style={{ whiteSpace: 'pre' }}>
              {quotationDetails?.agreement}
            </div>
          </OCol>
        )}
        {isInternalUser && agreementUploadAllowed && (
          <OCol col="12" className="mb-4 p-2">
            <FileUpload
              isUploadAllowed
              isUploading={isUploading}
              maxFiles={2}
              maxBytes={250000000}
              acceptExtensions=".pdf"
              onUpload={onUpload}
            />
          </OCol>
        )}
      </ORow>
      <ORow className="pr-3">
        {listAgreements?.map((i, key) => (
          <QuotationPageAgreementItem
            key={key}
            companyName={companyName}
            agreementFile={i}
            isAwaitingSign={
              quotationDetails?.status.id === QuotationStatusEnum.AwaitingSfaSign || quotationDetails?.status.id === QuotationStatusEnum.AwaitingOrder
            }
            isInternalUser={isInternalUser}
            isInternalActionsEnabled={agreementUploadAllowed}
            downloadAgreementUrlBuilder={downloadAgreementUrlBuilder}
            downloadSignedUrlBuilder={downloadSignedUrlBuilder}
            onDelete={onDelete}
            onSignedFileUpload={onSignedFileUpload}
            onAccepted={onAccepted}
            onRejected={onRejected}
          />
        ))}
        {!listAgreements?.length && !quotationDetails?.agreement ? (
          <OCol col="12">
            <EmptyListIndicator />
          </OCol>
        ) : null}
      </ORow>
    </TabContent>
  )
}

export default React.memo(QuotationPageAgreementTab)

