import { useEffect, useState } from 'react'

import { QuotationDetails, QuotationStatusEnum } from '../interfaces'

const useQuotationStatus = (quotationDetails: QuotationDetails | null) => {
  const [isDraft, setIsDraft] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [isOngoing, setIsOngoing] = useState(false)
  const [isRejected, setIsRejected] = useState(false)
  const [isAccepted, setIsAccepted] = useState(false)
  const [isAwaitingSFA, setIsAwaitingSfaSign] = useState(false)
  const [isAwaitingOrder, setIsAwaitingOrder] = useState(false)
  const [isOrdered, setIsOrdered] = useState(false)

  useEffect(() => {
    setIsDraft(quotationDetails?.status?.id === QuotationStatusEnum.Draft)
    setIsSent(quotationDetails?.status?.id === QuotationStatusEnum.Sent)
    setIsOngoing(quotationDetails?.status?.id === QuotationStatusEnum.Ongoing)
    setIsRejected(quotationDetails?.status?.id === QuotationStatusEnum.Rejected)
    setIsAccepted(quotationDetails?.status?.id === QuotationStatusEnum.Accepted)
    setIsAwaitingSfaSign(quotationDetails?.status?.id === QuotationStatusEnum.AwaitingSfaSign)
    setIsAwaitingOrder(quotationDetails?.status?.id === QuotationStatusEnum.AwaitingOrder)
    setIsOrdered(quotationDetails?.status?.id === QuotationStatusEnum.Ordered)
  }, [quotationDetails])

  return { isDraft, isSent, isOngoing, isRejected, isAccepted, isAwaitingSFA, isAwaitingOrder, isOrdered }
}

export default useQuotationStatus
