import React from 'react'

interface Props {
  value?: number | string
  message?: string
}

const MissingValueWrapper = (props: Props) => {
  const { value, message } = props
  return !!value ? (
    <span>{value}</span>
  ) : (
    <span className="text-danger">{message}</span>
  )
}

export default React.memo(MissingValueWrapper)
