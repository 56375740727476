import React, { useState, ReactNode, useEffect, CSSProperties } from 'react'

interface Props {
  isChecked: boolean,
  id: string
  className?: string
  style?: CSSProperties
  onSelChange?: (id: string, value: boolean) => void
}

const SelectionBox = (props: Props) => {
    const {
      isChecked,
      id,
      className,
      style,
      onSelChange
    } = props
    
    const classes = ['custom-control-input', 'm-0', 'd-flex', 'align-items-center']
    if (className) classes.push(className)

    return (
      <div 
        className="custom-control custom-checkbox"
        onClick={e => {
          if (!(e.target as HTMLElement).onclick) {
            e.stopPropagation()
          }
        }}
      >
        <input 
          autoComplete="off" 
          className="custom-control-input" 
          id={id} type="checkbox" 
          checked={isChecked} 
          onChange={e => {
            onSelChange?.(e.target.id, e.target.checked)
            e.stopPropagation()
          }}/>
        <label className="custom-control-label" htmlFor={id}>
        </label>

      </div>
      )
}

export default React.memo(SelectionBox)
