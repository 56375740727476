import React, { useCallback, useEffect } from 'react'
import Page from '../layout/Page'
import { ContentSection } from '../layout'

import { read, actions as projectActions } from '../../store/slices/projects'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { useMatch, Link } from 'react-router-dom'
import {
  OButton,
  OTabs,
  OTab,
  showSuccess,
  showPrompt,
  OToggleSwitch,
  showConfirm
} from '@dnvgl-onefoundation/onedesign-react'
import { api } from '../../utils'
import {
  BackButton,
  ProjectDiscussions,
  AccessModeIndicator,
  StatusBadge
} from '../helpers'
import ProjectDetailsView, { ProjectValue } from './ProjectDetailsView'
import ProjectDocumentsView from './ProjectDocumentsView'
import { helper, config } from '../../utils'
import { ProgressEnum, ProjectDocumentsSection } from '../../interfaces'
import {
  checkInternalUsers,
  checkExternalUsers,
  checkGroups
} from '../../store/slices/userGroups'

const { isAuthenticated, handleErrorMessage } = helper

const ProjectPage = () => {
  const { paths } = config
  const match = useMatch(`${paths.orders}/:id`)
  const id: string = (match?.params as any)?.id
  const dispatch = useAppDispatch()
  const { isLoadingProject } = useAppSelector(state => state.projects)
  const { project } = useAppSelector(state => state.projects)
  const { currentUser, isInternalUser } = useAppSelector(state => state.users)

  const loadProject = useCallback(() => dispatch(read(id)), [dispatch, id])

  useEffect(
    () => () => {
      dispatch(projectActions.resetProject())
    },
    [dispatch]
  )

  useEffect(() => {
    if (isAuthenticated(currentUser)) loadProject()
    dispatch(isInternalUser ? checkInternalUsers() : checkGroups())
  }, [currentUser, dispatch, id, isInternalUser, loadProject])

  useEffect(() => {
    if (project?.company.id) dispatch(checkExternalUsers(project.company.id))
  }, [dispatch, project?.company.id])

  const updateProject = useCallback(
    (propertyName: string, propertyValue: ProjectValue) => {
      dispatch(projectActions.setIsLoadingProject(true))
      api.projects
        .update(id, {
          propertyName,
          propertyValue
        })
        .then(data => {
          dispatch(projectActions.setProject(data))
          showSuccess('Updated', 'The order successfully updated.')
        })
        .catch(handleErrorMessage)
        .finally(() => dispatch(projectActions.setIsLoadingProject(false)))
    },
    [dispatch, id]
  )

  const toggleStatus = () => {
    const newStatusName =
      project?.status.progress === ProgressEnum.Completed
        ? 'Ongoing'
        : 'Completed'
    if (project) {
      showConfirm(project.name, `Set status to "${newStatusName}"?`).then(
        confirmed => {
          if (confirmed)
            api.projectsAdministration
              .setIsCompleted(
                project.id,
                !(project.status.progress === ProgressEnum.Completed)
              )
              .then(() => {
                showSuccess('Status successfully updated!', newStatusName)
                loadProject()
              })
        }
      )
    }
  }

  const promptToUpdate = useCallback(
    (title: string, value: string, propertyName: string) =>
      showPrompt(title, value).then(newValue => {
        if (newValue) updateProject(propertyName, newValue)
      }),
    [updateProject]
  )

  return (
    <Page
      title={
        isLoadingProject ? null : (
          <>
            <BackButton tooltip="Back to the list" linkTo={paths.orders} />
            &nbsp;
            <span>{project?.name}</span>
            {!isInternalUser && (
              <>
              <StatusBadge status={project?.status} />
              <AccessModeIndicator
                isReadOnly={!!project?.isReadOnly}
                style={{ fontSize: '60%', marginLeft: '1rem' }} />
              </>
            )}
          </>
        )
      }
      actions={
        isLoadingProject ? null : (
          <>
          {isInternalUser && (
            <OToggleSwitch
              className="pointer"
              textLocation="hidden"
              checked={project?.status.progress === ProgressEnum.Completed}
              onChange={toggleStatus}>
              {project?.status.progress === ProgressEnum.Completed
                ? 'Completed'
                : 'Ongoing'}
            </OToggleSwitch>
          )}

            <OButton
              variant="flat"
              iconClass="fal fa-edit"
              onClick={() =>
                promptToUpdate('Rename Order', project?.name as string, 'name')
              }
              disabled={project?.isReadOnly}>
              Rename
            </OButton>
            {project?.quotationId && (
              <Link to={`/quotations/${project?.quotationId}`}>
                <OButton variant="flat" iconClass="fa-light fa-link">
                  Open Quotation
                </OButton>
              </Link>
            )}
            {!!project && !!currentUser && (
              <>
                &nbsp;
                <ProjectDiscussions
                  project={project}
                  currentUser={currentUser}
                  isInternalUser={isInternalUser}
                  isReadOnly={!!project?.isReadOnly}
                />
              </>
            )}
          </>
        )
      }
      isLoading={isLoadingProject}
      disabled={isLoadingProject}>
      {!isLoadingProject && project && (
        <ContentSection>
          <OTabs>
            <OTab title="Details">
              <ProjectDetailsView
                project={project}
                promptToUpdate={promptToUpdate}
                updateProject={updateProject}
              />
            </OTab>
            <OTab title="Documents">
              <ProjectDocumentsView
                key={ProjectDocumentsSection.Documents}
                projectId={project.id}
                section={ProjectDocumentsSection.Documents}
                isInternalUser={isInternalUser}
                isReadOnly={project.isReadOnly}
                hasMessage={true}
              />
            </OTab>
            <OTab title="Deliverables">
              <ProjectDocumentsView
                key={ProjectDocumentsSection.Deliverables}
                projectId={project.id}
                section={ProjectDocumentsSection.Deliverables}
                isInternalUser={isInternalUser}
                isReadOnly={!isInternalUser}
                hasMessage={false}
              />
            </OTab>
          </OTabs>
        </ContentSection>
      )}
    </Page>
  )
}

export default React.memo(ProjectPage)
