import React from 'react'
import ReactDOM from 'react-dom'
import { OModal } from '@dnvgl-onefoundation/onedesign-react'

const addElement = (id: string): HTMLElement => {
  const element = document.createElement('div')
  element.setAttribute('id', id)
  document.body.appendChild(element)
  return element
}

export const removeElement = (id: string): void => {
  const element = document.getElementById(id)
  if (element) element.remove()
}

const modalContainerId = 'prompt-modal-container'

export const showTextAreaPrompt = (
  title: string,
  value: string | undefined
): Promise<string | false> => {
  addElement(modalContainerId)
  const hide = () => removeElement(modalContainerId)
  const state = { value }

  const setValue = (value: string) => (state.value = value)

  return new Promise(resolve => {
    const modal = (
      <OModal
        visible
        hideCloseButton
        okText="Submit"
        clickOutside={false}
        titleText={title}
        bodySlot={
          <div className="form-group mt-3">
            <textarea
              className="form-control"
              rows={5}
              defaultValue={value}
              onChange={e => setValue(e.target.value)}
            />
          </div>
        }
        onOk={() => {
          resolve(state.value !== undefined ? state.value : false)
          hide()
        }}
        onCancel={() => {
          resolve(false)
          hide()
        }}
        bodyMinHeight={50}
      />
    )
    ReactDOM.render(modal, document.getElementById(modalContainerId))
  })
}
