import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '../../utils'
import { AsyncActionCreator } from '../index'
import { getErrorHandler } from './users'

export interface FailuresState {
  relevantTransmitialFailuresCount: number
}

const initialState: FailuresState = {
  relevantTransmitialFailuresCount: 0
}

export const failuresSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setRelevantTransmitialFailuresCount: (state, action) => {
      state.relevantTransmitialFailuresCount = action.payload
    },
  }
})

export const { actions } = failuresSlice

export const getRelevantTransmitialFailuresCount: AsyncActionCreator = () => dispatch =>
  api.transmittalFailures
    .readRelevantCount()
    .then(count => {
      dispatch(actions.setRelevantTransmitialFailuresCount(count))
    })
    .catch(getErrorHandler(dispatch))
