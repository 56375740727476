import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit'

import { usersSlice, UsersState } from './slices/users'
import { projectsSlice, ProjectsState } from './slices/projects'
import { userGroupsSlice, UserGroupState } from './slices/userGroups'
import { configurationSlice, ConfigurationState } from './slices/configuration'
import { quotationsSlice, QuotationsState } from './slices/quotations'
import { failuresSlice, FailuresState } from './slices/failures'

export interface ApplicationState {
  configuration: ConfigurationState
  users: UsersState
  userGroups: UserGroupState
  projects: ProjectsState
  quotations: QuotationsState
  failures: FailuresState
}

const store = configureStore({
  reducer: {
    configuration: configurationSlice.reducer,
    users: usersSlice.reducer,
    userGroups: userGroupsSlice.reducer,
    projects: projectsSlice.reducer,
    quotations: quotationsSlice.reducer,
    failures: failuresSlice.reducer,
  }
})

export type ApplicationDispatch = typeof store.dispatch

export interface Action
  extends ThunkAction<void, ApplicationState, null, AnyAction> {}

export interface ActionCreator {
  (): Action
}

export interface AsyncAction
  extends ThunkAction<void | Promise<any>, ApplicationState, null, AnyAction> {}

export interface AsyncActionCreator {
  (): AsyncAction
}

export default store
