import React, { useState, useCallback } from 'react'
import {
  OButton,
  OModal} from '@dnvgl-onefoundation/onedesign-react'
import {
  FieldsGroupTemplate,
  FieldTemplate,
  isOptionsField,
  isOptionsFieldType,
  QuotationFormStepTemplate
} from '../../../interfaces/FormTemplate'
import FieldDetails from './FieldDetails'

interface Props {
  showStep?: boolean
  steps?: QuotationFormStepTemplate[]
  stepIndex?: number
  group?: FieldsGroupTemplate
  onAdd: (field: FieldTemplate, stepIndex: number) => void
}

interface Form {
  stepIndex: number
  field: FieldTemplate
}

const initialState: Form = {
  stepIndex: 0,
  field: {
    name: '',
    type: 'string'
  }
}

const AddFieldForm = (props: Props) => {
  const { steps, stepIndex, showStep, group, onAdd } = props
  const [isVisible, setIsVisible] = useState(false)
  const [form, setForm] = useState<Form>(initialState)

  initialState.stepIndex = stepIndex ?? 0

  const showModal = () => {
    if ((showStep && steps?.length) || group) {
      setForm(initialState)
      setIsVisible(true)
    }
  }

  const hideModal = useCallback(() => setIsVisible(false), [])

  const onOk = () => {
    if (isVisible) {
      hideModal()
      onAdd(form.field, form.stepIndex)
    }
  }

  const onStepIndexChanged = useCallback((index: number) => {
    setForm(x => (
      {...x,
        stepIndex: index}
      ))
  }, [])

  const onFieldUpdated = useCallback((field: FieldTemplate) => {
    if (isOptionsFieldType(field) && !field.options) field.options=[]

    setForm(x => ({...x, field: field}))
  }, [])

  const siblingFields = (showStep ? steps?.[form.stepIndex]?.fields : group?.fields) ?? []

  const isValid =
    form.field.name?.length 
    && !siblingFields.some(x => x.name === form.field.name)
    && (!isOptionsField(form.field) || form.field.options.length)

  return (
    <>
      <OButton
        onClick={showModal}
        disabled={!steps?.length && !group}
        size="small"
        iconClass="fal fa-plus"
        variant="flat">
        Add Field
      </OButton>
      <OModal
        visible={isVisible}
        size="md"
        hideCloseButton
        okText="Add"
        okDisabled={!isValid}
        clickOutside={false}
        titleText="Add Field"
        bodySlot={<FieldDetails 
          showStep={showStep}
          steps={steps}
          stepIndex={form.stepIndex}
          fields={siblingFields}
          field={form.field}
          onStepIndexChanged={onStepIndexChanged}
          onUpdated={onFieldUpdated}
        />}
        onOk={onOk}
        onCancel={hideModal}
      />
    </>
  )
}

export default React.memo(AddFieldForm)
