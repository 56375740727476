import React from 'react'
import { Page, ContentSection } from '../../layout'
import { AppName } from '../../helpers'
import ContactSupportSnippet from './ContactSupportSnippet'
import { ODetails } from '@dnvgl-onefoundation/onedesign-react'
import { config } from '../../../utils'

const renderIllustration = (id: string | number, fullWidth = true) => (
  <p>
    <img
      className={`d-block m-auto${fullWidth ? ' w-100' : ''}`}
      src={`/images/how-it-works/${id}.png`}
      alt={`How it works - Illustration ${id}`}
    />
  </p>
)

const renderSection = (title: string, description: string) => (
  <div className="w-100">
    <h4>{title}</h4>
    <p>{description}</p>
  </div>
)

const renderNumberIcon = (id: number) => (
  <img
    style={{ width: 30 }}
    src={`${config.cdnResources.baseUrl}/images/numbers/${id}.png`}
    alt={`${id}`}
  />
)

const HowItWorksPage = () => (
  <Page title="How it works">
    <ContentSection title="1 Introduction">
      <p>
        <AppName />
        &nbsp;is DNV Energy systems’ easy-to-use customer portal/ application,
        specifically targeted for smaller assurance and advisory services.
      </p>
      <p>
        <AppName /> provides an overview of quotations, ongoing orders, status,
        and all functionality to follow-up the order, supporting collaboration
        of the order with the company team and the DNV engineers providing the
        service.
      </p>
      <p>
        <AppName /> contains four parts:
      </p>
      <ol>
        <li>Quotations</li>
        <li>Orders</li>
        <li>User management</li>
        <li>Order follow-up</li>
      </ol>
      <p>
        This introduction supports a first pre-release of the&nbsp;
        <AppName />
        &nbsp;application, intended to collect initial user feedback and input
        for further improvements. Giving this status, inconsistencies and less
        sophisticated functionality may be experienced. With this status DNV
        apologizes for any inconvenience caused and at the same time appreciates
        your attention, engagement and feedback provided.
      </p>
    </ContentSection>
    <ContentSection title="2 Overview">
      <p>
        In the overview, DNV Direct shows all the quotations and orders as
        related to your company, your team or those assigned to you.
      </p>
      <p>
        Besides the general overview, the page shows the status, whom has the
        action and last activity on each quotation or order.
      </p>
      {renderIllustration('How-to-Section-Overview')}
    </ContentSection>
    <ContentSection title="2.1 Quotation">
      <p>
        From the overview page it is possible to create a quotation request by
        “Add Quotation Request”.
      </p>
      <p>
        Start by type the name of the project and then select the service that
        you want to request. If you are not able to find the right service,
        please contact your local DNV office. The list of services will be
        increased during 2022.
      </p>
      {renderIllustration('How-to-Section-Add-Quotation')}
      <p>
        From this page you will be transferred to a quotation information page
        with first the technical scope, customer information, here the DNV Short
        Form Agreement (SFA) and later the customer purchase order will be
        added.
      </p>
      <p>
        Please fill in questions for the technical scope in the quotation form,
        once saved you will be lead directly to the Customer Details tab. In the
        Customer Details tab all the information regarding your company is
        already listed. The only information needed to be filled out is the VAT
        /Tax Number.
      </p>
      {renderIllustration('How-to-VAT')}
      <p>
        Click to blue pensile to add the number. All prefilled information on
        the Customer Details page can be revised in the same way, it is also
        possible to add an additional invoice address.
      </p>
      <p>
        It is possible to add documents to the quotation request to be included
        in the agreement.
      </p>
      <p>
        Once it all is filled out, the quotation request should be “Save and
        Send” to DNV.
      </p>
    </ContentSection>
    <ContentSection title="2.2 Quotation process">
      <p>
        As soon as a quotation has been created and saved, whether in the
        process of filling in the technical scope or send to DNV, it will be
        visible in the overview.
      </p>
      <p>
        As the quotation is Save and Send to DNV, the status of the quotation
        will change from “draft” to “send”. The workflow of the quotation is:
      </p>
      {renderIllustration('How-to-Section-Workflow')}
    </ContentSection>
    <ContentSection title="2.3 Short form Agreement">
      <p>
        DNV will receive the request notification and work on a Short Form
        Agreement (SFA) based on the information that is provided.
      </p>
      <p>
        DNV will upload the Short Form Agreement the DNV Direct and the customer
        will receive an notification including a link to the quotation and
        location of the Short Form Agreement (SFA ). The Short Form Agreement
        (SFA) can be a physical document that need to be downloaded and signed
        or it can be a Frame Agreement or yearly approved Short Form Agreement
        (Then only the Agreement number is shown) and the customer must accept
        this.
      </p>
      <p>
        When the Short Form Agreement is sent, the specific quotation will to
        the top of the quotation request list.
      </p>
      <p>
        The customer should view and sign the Short Form Agreement or if a Frame
        Agreement is in place accept the use of this.
      </p>
      {renderIllustration('How-to-Agreement')}
      <p>
        If a signed Short Form Agreement is required, it cannot be accepted
        until the Short Form Agreement is uploaded.
      </p>
      <p>
        In the Purchase order tab it is possible to upload the purchase order or
        write the purchase order number. The press Send Order and the request
        process is completed.
      </p>
      {renderIllustration('How-to-Purchase-Order')}
    </ContentSection>
    <ContentSection title="2.4 Rejection of DNV Short Form Agreement">
      <p>
        It is possible to reject the Short Form Agreement from DNV by “Reject”
        in the DNV Agreement tab.
      </p>
      {renderIllustration('How-to-Reject')}
      <p>
        If rejecting the Agreement document a reason for this needs to be
        provided.
      </p>
      {renderIllustration('How-to-Rejected-View')}
      <p>The quotation request will then go back to DNV for further action.</p>
    </ContentSection>

    <ContentSection title="3 Order follow-up">
      <p>
        Order follow-up shows up after selecting a specific order from the order
        overview. It shows (1) the detail of the order, (2) order specific
        documentation and (3) deliverables and is further completed with (4) an
        order specific discussion board.
      </p>
      {renderIllustration('How-to-Order')}
      <ODetails
        boldLabels
        fields={
          [
            {
              name: 'first',
              heading: <span>Add&nbsp;{renderNumberIcon(1)}</span>
            },
            {
              name: 'second',
              heading: <span>Add&nbsp;{renderNumberIcon(2)}</span>
            },
            {
              name: 'third',
              heading: <span>Add&nbsp;{renderNumberIcon(3)}</span>
            },
            {
              name: 'forth',
              heading: <span>Add&nbsp;{renderNumberIcon(4)}</span>
            }
          ] as any
        }
        value={
          {
            first: renderSection(
              'Detail of the order',
              'Under ‘details’ of the order, It is possible to assign Teams to the order. Otherwise, one can re-name the order from the originally specification as provided by DNV. It is possible to open the original quotation.'
            ),
            second: renderSection(
              'Documents',
              'Under the ‘Documents’ tab one can upload new documents as related to the specific order, e.g. drawings and input as relevant for the ordered DNV service. At the same time, the tab shows the status of earlier submitted drawings.'
            ),
            third: renderSection(
              'Deliverables',
              'Giving an overview of the order deliverables as produced by DNV, e.g. letters, certificates or final reports.'
            ),
            forth: renderSection(
              'Discussion board',
              'To discuss specific technical and order related issues.'
            )
          } as any
        }
      />
    </ContentSection>
    <ContentSection title="4 User management">
      {renderIllustration('How-to-Users')}
      <p>
        Under the tab ‘Users’, you will find the users in your company as
        registered to the app.
      </p>
      <p>It provides two functionalities:</p>
      <ol>
        <li>
          Inviting new colleagues to the application by using ‘Invite User’
        </li>
        <li>
          Creating a new team which you can use in the follow up on services as
          covered by DNV Direct. There is a possibility to assign the “Write”
          rights to teams as required.
        </li>
      </ol>
    </ContentSection>
    <ContentSection title="5 Further development">
      <p>
        The March 2022 is a Beta version of DNV Direct is a first release
        intended to collect user feedback and input for further improvements.
      </p>
    </ContentSection>
    <ContactSupportSnippet />
  </Page>
)

export default React.memo(HowItWorksPage)
