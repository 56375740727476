import { useCallback, useEffect, useState } from 'react'
import { QuotationForm, QuotationFormStepTemplate } from '../../interfaces/FormTemplate'
import { api, helper } from '../../utils'

export function useQuotationFormTemplate(
  steps?: QuotationFormStepTemplate[],
  existingData?: any
) {
  const [form, setForm] = useState<QuotationForm>()

  useEffect(() => {
    if (steps) {
      const data = steps.reduce(
        (formData, step) =>
          step.fields?.length
            ? {
                ...formData,
                [step.title]: {
                  ...step.fields.reduce((stepData, field) => {
                    return {
                      ...stepData,
                      [field.name]: field.type === 'checkbox' ? [] : ''
                    }
                  }, {}),
                  ...existingData?.[step.title]
                }
              }
            : formData,
        {}
      )
      setForm({ steps, data })
    } else setForm(undefined)
  }, [steps, existingData])

  const setProp = (form: any, step: string, name: string, value: string, checked?: boolean, groupName?: string, groupIndex?: number) : any => {
    let item = (groupName === undefined 
      ? form.data?.[step]
      : (groupIndex === undefined ? form.data?.[step]?.[groupName] : form.data?.[step]?.[groupName]?.[groupIndex]))
      ?? {};

    setPropertyValue(item, name, value, checked);

    if (groupName === undefined ) {
      return { ...form,
        data: {
          ...form?.data,
          [step]: item
        }
      }
    } else if (groupIndex === undefined) {
      return { ...form,
        data: {
          ...form?.data,
          [step]: {
            ...form?.data?.[step],
            [groupName]: item
          }
        }
      }
    } else {
      let groupArray = Array.isArray(form.data?.[step]?.[groupName]) ? [...form.data?.[step]?.[groupName]] : [];
      groupArray[groupIndex] = item;
      return { ...form,
        data: {
          ...form?.data,
          [step]: {
            ...form.data[step],
            [groupName]: groupArray
          }
        }
      }
    }
  }

  function setPropertyValue(dataItem: any, name: string, value: string, checked?: boolean) {
    if (checked === undefined) {
      dataItem[name] = value
    } else {
      if (checked) {
        dataItem[name] = [...dataItem[name], value]
      } else {
        dataItem[name] = dataItem[name].filter((o: string) => o !== value)
      }
    }
  }

  const setStepProperty = useCallback(
    (stepTitle: string) => (name: string, value: string, checked?: boolean, groupName?: string, groupIndex?: number) =>
      setForm((form: any) => {
        const newForm = setProp(form, stepTitle, name, value, checked, groupName, groupIndex)
        return newForm;
      }),
      []
  )

  return { form, setStepProperty}
}

export const useQuotationForm = (quotationId?: string) => {
  const [steps, setSteps] = useState<QuotationFormStepTemplate[]>()
  const { form, setStepProperty } = useQuotationFormTemplate(steps)

  useEffect(() => {
    if (!quotationId) setSteps(undefined)
    else {
      let isCanceled = false
      api.quotationForms
        .read(quotationId)
        .then(x => {
          if (!isCanceled) setSteps(JSON.parse(x))
        })
        .catch(x => {
          if (!isCanceled) helper.handleErrorMessage(x)
        })
      return () => {
        isCanceled = true
      }
    }
  }, [quotationId])

  return { form, setStepProperty }
}
