import React from 'react'
import { Page } from '../../layout'
import ContactSupportSnippet from './ContactSupportSnippet'

const ContactSupportPage = () => (
  <Page title="Contact support">
    <ContactSupportSnippet />
  </Page>
)

export default React.memo(ContactSupportPage)
