import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '../../utils'
import { FeatureToggles } from '../../interfaces/BackendGenerated'
import { AsyncActionCreator } from '../index'
import { getErrorHandler } from './users'

export interface ConfigurationState {
  featureToggles: FeatureToggles
}

const initialState: ConfigurationState = {
  featureToggles: {
    quotations: false,
    acceptSfaWithoutFile: false
  }
}

export const configurationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setFeatureToggles: (state, action: PayloadAction<FeatureToggles>) => {
      state.featureToggles = action.payload
    }
  }
})

export const { actions } = configurationSlice

export const getFeatureToggles: AsyncActionCreator = () => dispatch =>
  api.featureToggles
    .fetch()
    .then(data => {
      dispatch(actions.setFeatureToggles(data))
    })
    .catch(getErrorHandler(dispatch))
