import React, { useState, ReactNode, useEffect } from 'react'
import {
  OButton,
  OList,
  OModal,
  OTooltip,
  ButtonVariant
} from '@dnvgl-onefoundation/onedesign-react'
import { FilterInput } from '.'
import {
  UserIdentity,
  User,
  UserGroupItem,
  GroupTypeEnum
} from '../../interfaces'

type Item = UserIdentity | User | UserGroupItem

enum ViewItemType {
  User,
  Company,
  Team,
}

interface ViewItem {
  type?: ViewItemType,
  id?: string | undefined,
  text?: string | undefined,
  displayText?: string | undefined,
  iconClass?: string
}

interface Props {
  titleText: ReactNode
  items?: Item[]
  selectedItems?: Item[]
  multiselect?: boolean
  selectionRequired?: boolean
  buttonText?: ReactNode
  iconClass: string
  tooltipContent?: string
  variant?: ButtonVariant
  isInside?: boolean
  disabled?: boolean
  sort?:boolean
  okText: string
  onSelected?: (ids: string[]) => void
}

const SelectItem = (props: Props) => {
  const {
    titleText,
    items,
    multiselect = false,
    selectionRequired = true,
    buttonText,
    iconClass,
    tooltipContent = 'Update',
    variant = 'flat',
    isInside = false,
    disabled = false,
    sort = true,
    okText,
    onSelected
  } = props

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedItems, setSelectedItems] = useState<string[]>(
    props.selectedItems?.map(x => x.id) ?? []
  )
  const [viewItems, setViewItems] = useState<ViewItem[]>([])
  const [filteredItems, setFilteredItems] = useState<ViewItem[]>([])

  const getUserViewItem = (user: UserIdentity):ViewItem =>  {
    return {
      id: user.id,
      type: ViewItemType.User,
      text: `${user.firstName} ${user.lastName} ${user.email}`,
      displayText: `${user.firstName} ${user.lastName} ( ${user.email})`,
      iconClass: "fal fa-user fg-cyan mr-2"
    }
  }
  const getGroupViewItem = (group: UserGroupItem):ViewItem =>  {
    return {
      id: group.id,
      type: group.type == GroupTypeEnum.Company ? ViewItemType.Company : ViewItemType.Team,
      text: group.name,
      displayText: group.type == GroupTypeEnum.Company ? `All in ${group.name}` : group.name,
      iconClass: group.type == GroupTypeEnum.Company ? "fas" : "fal" + " fa-users fg-cyan mr-2"
    }
  }
  
  function mapToViewItem(item: Item) : ViewItem {
    if (!!(item as UserIdentity)?.fullName) {
      return getUserViewItem(item as UserIdentity);
    } else if (!!(item as UserGroupItem)?.companyId) {
      return getGroupViewItem(item as UserGroupItem);
    } else {
      return {};
    }
  }

  useEffect(() => {
    const viewItems : ViewItem[] = items?.map(mapToViewItem) ?? []
    if (sort) {
      viewItems.sort((i1, i2) => {
        const text1 = i1.text ?? '';
        const text2 = i2.text ?? '';
        return text1.localeCompare(text2, 'en');
      })
    }
    setViewItems(viewItems);
  }, [items, sort])
  
  useEffect(() => {
    if (props.selectedItems)
      setSelectedItems(props.selectedItems.map(x => x.id))
  }, [props.selectedItems])

  const onOK = () => {
    onSelected?.(selectedItems)
    hideModal()
  }

  const hideModal = () => {
    setIsModalVisible(false)
    setSelectedItems([])
  }

  const filterItems = (value?: string) => {
    if (!multiselect) setSelectedItems([])
    if (!value || !viewItems) return setFilteredItems(viewItems ?? [])
    const query = value.toLocaleLowerCase('en')
    setFilteredItems(
      viewItems.filter( (i: ViewItem) => i?.text?.toLocaleLowerCase('en')?.includes(query)
      )
    )
  }

  const onItemSelected = (u: any) => {
    const selected = items?.find(i => i?.id === u?.id)
    if (selected) {
      if (multiselect) {
        setSelectedItems(
          selectedItems?.includes(selected.id)
            ? [...selectedItems?.filter((i: string) => i !== selected.id)]
            : [...selectedItems, selected?.id]
        )
      } else setSelectedItems([selected.id])
    }
  }

  const buildRecord = (item: ViewItem) => (
    <span className="fa-1x">
      <i className={`${item.iconClass}`}></i>
      {`${item.displayText}`}
    </span>
  )

  return items?.length ? (
    <>
      <OTooltip content={tooltipContent} placement="bottom">
        <OButton
          disabled={disabled}
          iconClass={iconClass}
          variant={variant}
          onClick={(e) => {
            filterItems()
            setIsModalVisible(true)
            if (isInside) {
              e.stopPropagation()
            }
          }}>
          {buttonText}
        </OButton>
      </OTooltip>
      <OModal
        visible={isModalVisible}
        titleText={
          <div style={{ minWidth: '28rem', textAlign: 'left' }}>
            <span>{titleText}</span>
            <div style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
              <FilterInput defaultValue="" onChange={filterItems} />
            </div>
          </div>
        }
        okText={okText}
        hideCloseButton
        onOk={onOK}
        onCancel={hideModal}
        okDisabled={selectionRequired && !selectedItems.length}
        clickOutside={false}
        bodySlot={
          <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
            <OList
              onSelected={onItemSelected}
              selected={selectedItems}
              flush
              small
              items={filteredItems.map((item: any) => {
                return {
                  id: item.id,
                  content: buildRecord(item)
                }
              })}
            />
          </div>
        }
      />
    </>
  ) : null
}

export default React.memo(SelectItem)
