import React, { useState, useEffect } from 'react'
import {
  OButton,
  OModal,
  OField,
  ORow,
  OCol,
  FieldType,
  Option,
  OAlert
} from '@dnvgl-onefoundation/onedesign-react'

import helper from '../../utils/helper'
import { ApplicationState } from '../../store'
import { useSelector, useDispatch } from 'react-redux'
import {
  GroupTypeEnum,
  UserGroupItem,
  UserIdentity,
  value
} from '../../interfaces'
import { checkExternalUsers } from '../../store/slices/userGroups'

interface Payload {
  name?: string
  teamcenterId?: string
  groupId?: string
  customerContactId?: string
}

interface Props {
  entityName: string
  disabled?: boolean
  onOK?: (payload: any) => void
}

const ProjectAdd = (props: Props) => {
  const { entityName, disabled = false, onOK } = props
  const { deepClone } = helper
  const { isLoadingUserGroups, userGroups, externalUsers } = useSelector(
    (state: ApplicationState) => state.userGroups
  )
  const dispatch = useDispatch()

  const [isVisible, setIsVisible] = useState(false)
  const [payload, setPayload] = useState({} as Payload)
  const [companyUsers, setCompanyUsers] = useState([] as UserIdentity[])

  const handleOK = () => {
    setIsVisible(false)
    onOK?.(payload)
    setPayload({})
  }

  useEffect(() => {
    setCompanyUsers(
      externalUsers?.[`${payload?.groupId}`]
        ? externalUsers?.[`${payload?.groupId}`]
        : []
    )
  }, [externalUsers, payload])

  const handleCancel = () => setIsVisible(false)

  const checkCompanyUsers = (id: string) => {
    dispatch(checkExternalUsers(id))
  }

  const update = (key: string, val: value) => {
    const newValue = deepClone(payload)
    newValue[key] = val
    setPayload(newValue)
  }

  const getCompanyName = (groupId: string) =>
    userGroups?.find((g: UserGroupItem) => g.id === groupId)?.name

  const bodySlot = isLoadingUserGroups ? (
    <ORow>
      <OCol col="12">Loaindg...</OCol>
    </ORow>
  ) : (
    <ORow>
      <OCol md="6" sm="12">
        <OField
          onChange={e => update('name', `${e.value}`)}
          value={{}}
          field={{
            heading: 'Order name *',
            name: 'name',
            type: FieldType.Input,
            required: true
          }}
        />
      </OCol>
      <OCol md="6" sm="12">
        <OField
          onChange={e => update('teamcenterId', `${e.value}`)}
          value={{}}
          field={{
            heading: 'Teamcenter ID',
            name: 'teamcenterId',
            type: FieldType.Input,
            required: false
          }}
        />
      </OCol>
      <OCol md="6" sm="12">
        <OField
          onChange={e => {
            const groupId = `${e.value}`
            update('groupId', groupId)
            checkCompanyUsers(groupId)
          }}
          value={{}}
          field={{
            heading: 'Company *',
            name: 'groupId',
            type: FieldType.Select,
            optionalTag: false,
            options: userGroups
              ?.filter(
                (g: UserGroupItem) =>
                  g.type === GroupTypeEnum.Company && !g.isInternal
              )
              .map((g: UserGroupItem) => {
                return {
                  value: g.id,
                  text: g.name
                } as Option
              }),
            required: true
          }}
        />
      </OCol>
      <OCol
        md="6"
        sm="12"
        className={companyUsers?.length ? '' : 'is-disabled'}>
        <OField
          onChange={e => update('customerContactId', `${e.value}`)}
          value={{}}
          field={{
            heading: 'Contact Person *',
            name: 'customerContactId',
            type: FieldType.Select,
            optionalTag: false,
            options: companyUsers.map((u: UserIdentity) => {
              return {
                value: u.id,
                text: `${u.firstName} ${u.lastName}`
              } as Option
            }),
            required: true
          }}
        />
      </OCol>
      <OCol col="12">
        {!companyUsers?.length && payload.groupId && (
          <OAlert
            variant="warning"
            className="mt-2"
            dismissable={false}
            message={`${getCompanyName(
              payload?.groupId
            )} does not include any users yet.`}
          />
        )}
      </OCol>
    </ORow>
  )

  return (
    <>
      <OButton
        disabled={disabled}
        iconClass="far fa-plus"
        onClick={() => setIsVisible(true)}>
        New {entityName}
      </OButton>
      <OModal
        visible={isVisible}
        hideCloseButton
        okText="Add"
        clickOutside={false}
        titleText={`Add ${entityName}`}
        bodySlot={bodySlot}
        onOk={handleOK}
        onCancel={handleCancel}
        okDisabled={
          !payload?.name ||
          !payload?.groupId ||
          !payload?.customerContactId ||
          !companyUsers?.length
        }
      />
    </>
  )
}

export default React.memo(ProjectAdd)
