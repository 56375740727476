import {
  HttpMethod,
  AdminProjectItem,
  AdminProjectsList,
  CompanyInfo,
  CreateProject,
  CreateQuotation,
  CreateService,
  CreateUserGroup,
  DeleteResponse,
  Document,
  FeatureToggles,
  FileItem,
  UploadItem,
  Message,
  ProjectDetails,
  ProjectDocumentsSection,
  ProjectItem,
  QuotationDetails,
  QuotationItem,
  QuotationTechnicalDetails,
  ServiceDetails,
  ServiceItem,
  TransmittalFailure,
  UpdatePropertyRequest,
  UpdateUserRole,
  User,
  UserGroupItem,
  UserIdentity,
  UserRole,
  QuotationStatusEnum,
  TeamRightFlag,
  QuotationStatus,
  BatchUpdatePropertyRequest,
  BatchOperationResult,
  ProjectFiles,
  InviteRequest
} from '../interfaces'

import config from './config'

const { endpoints } = config

// HINT: To differentiate from human requests that redirects
// to login page instead of returning 401.
const ajaxHeader = {
  'X-Requested-With': 'XMLHttpRequest'
}
const jsonContentType = 'application/json'
const jsonContentTypeHeader = {
  accept: jsonContentType,
  'content-type': jsonContentType,
  ...ajaxHeader
}

const getAntiforgeryHeader = () => {
  const requestVerificationToken =
    document?.cookie
      ?.split('; ')
      ?.find(row => row.startsWith('RequestVerificationToken='))
      ?.split('=')[1] ?? ''

  return { RequestVerificationToken: requestVerificationToken }
}

const fetchFactory = (method: HttpMethod) => {
  const getHeaders = ['POST', 'PUT', 'PATCH', 'DELETE'].find(x => x === method)
    ? (isFormData: boolean) =>
        isFormData
          ? { ...getAntiforgeryHeader(), ...ajaxHeader }
          : { ...getAntiforgeryHeader(), ...jsonContentTypeHeader }
    : (isFormData: boolean) => (isFormData ? ajaxHeader : jsonContentTypeHeader)

  return async function fetch<TInput = any | FormData, TOutput = any>(
    path: string,
    input?: TInput
  ): Promise<TOutput> {
    const isFormData = input instanceof FormData
    const response = await window.fetch(path, {
      method,
      body: isFormData ? input : JSON.stringify(input),
      headers: getHeaders(isFormData)
    })

    const output = response.headers
      .get('content-type')
      ?.includes(jsonContentType)
      ? await response.json()
      : undefined

    return response.ok
      ? output
      : Promise.reject({
          response: {
            status: response.status,
            data:
              output ??
              (response.status === 401 ? undefined : await response.json())
          }
        })
  }
}

const postFiles = (path: string, files: File[]) => {
  const form = new FormData()
  const lenght = files?.length
  for (var i = 0; i < lenght; i++) form.append('files', files[i])
  return post<FormData, void>(path, form)
}

const postFilesWithMessage = (path: string, files: File[], message: string) => {
  const form = new FormData()
  const lenght = files?.length
  for (var i = 0; i < lenght; i++) form.append('files', files[i])
  form.append('message', message)
  return post<FormData, void>(path, form)
}

async function getText(path: string) {
  const response = await window.fetch(path, {
    method: 'GET',
    headers: jsonContentTypeHeader
  })

  return response.ok
    ? await response.text()
    : Promise.reject({
        response: {
          status: response.status,
          data: await response.json()
        }
      })
}

const get: <TOutput = any>(path: string) => Promise<TOutput> =
    fetchFactory('GET'),
  post = fetchFactory('POST'),
  put = fetchFactory('PUT'),
  patch = fetchFactory('PATCH'),
  del: (path: string) => Promise<void> = fetchFactory('DELETE'),
  deleteWithConfirmation: (path: string) => Promise<DeleteResponse> =
    fetchFactory('DELETE'),
  batchDeleteWithConfirmation: (path: string, payload:any) => Promise<DeleteResponse> =
    fetchFactory('DELETE'),
  batchDelete: (path: string, payload:any) => Promise<BatchOperationResult> =
    fetchFactory('DELETE')


const api = {
  featureToggles: {
    fetch: () => get<FeatureToggles>(endpoints.toggles)
  },
  users: {
    fetchCurrentUser: () => get<User | undefined>(endpoints.currentUser),
    fetchByGroup: (groupId: string | undefined) => {
      const path = groupId
        ? `${endpoints.groups}/${groupId}/Users`
        : `${endpoints.userAdministration}/UnassignedUsers`
      return get<UserIdentity[]>(path)
    },
    fetchNotificationsCount: () => get<number>(`${endpoints.my}/messages/count`)
  },
  userAdministration: {
    fetchRoles: () => get<UserRole[]>(`${endpoints.userAdministration}/Roles`),
    patchRole: (payload: UpdateUserRole) =>
      patch<UpdateUserRole, void>(
        `${endpoints.userAdministration}/Role`,
        payload
      ),
    findVeracityUser: (email: string) =>
      post<string, UserIdentity[]>(
        `${endpoints.userAdministration}/FindVeracityUser`,
        email
      ),
    addVeracityUser: (userId: string, isSilent?: boolean | undefined) => {
      const url1 = `${endpoints.userAdministration}/AddVeracityUser/${userId}`;
      const url = url1 + (isSilent === undefined ? '' : `?isSilent=${isSilent}`);
      return post<void, void>(
         url
      )
    },
    inviteUser: (email: string, isSilent: boolean | undefined) =>
      post<InviteRequest, string>(`${endpoints.userAdministration}/InviteUser`, {email, isSilent}),
    fetchInternalUsers: () =>
      get<UserIdentity[]>(`${endpoints.userAdministration}/InternalUsers`)
  },
  groups: {
    fetch: () => get<UserGroupItem[]>(endpoints.groups),
    fetchGroupUsers: (groupId: string) =>
      get<User[]>(`${endpoints.groups}/${groupId}/Users`),
    post: (payload: CreateUserGroup) =>
      post<CreateUserGroup, string>(endpoints.groups, payload),
    addUser: (groupId: string, userId: string) =>
      put<void, void>(`${endpoints.groups}/${groupId}/Users/${userId}`),
    addUsers: (groupId: string, userIds: string[]) =>
      put<string[], void>(`${endpoints.groups}/${groupId}/Users`, userIds),
    inviteUser: (groupId: string, email: string) =>
      put<void, void>(`${endpoints.groups}/${groupId}/Users/${email}`),
    deleteUser: (groupId: string, userId: string) =>
      del(`${endpoints.groups}/${groupId}/Users/${userId}`)
  },
  groupsAdministration: {
    patch: (groupId: string, payload: UpdatePropertyRequest) =>
      patch<UpdatePropertyRequest, UserGroupItem>(
        `${endpoints.groupsAdmin}/${groupId}`,
        payload
      )
  },
  companies: {
    readAll: () => get<UserGroupItem>(endpoints.companies),
    read: (companyId: string) =>
      get<CompanyInfo>(`${endpoints.companies}/${companyId}`)
  },
  teams: {
    readAll: () => get<UserGroupItem>(endpoints.teams),
    setRight: (teamId: string, right: TeamRightFlag, isSet: boolean) =>
      patch<boolean, UserGroupItem>(
        `${endpoints.teams}/${teamId}/Rights/${right}`,
        isSet
      )
  },
  projectsAdministration: {
    create: (payload: CreateProject) =>
      post<CreateProject, AdminProjectItem>(endpoints.projectsAdmin, payload),
    deleteProject: (projectId: string, deleteFiles: boolean = false) =>
      deleteWithConfirmation(
        `${endpoints.projectsAdmin}/${projectId}?deleteFiles=${deleteFiles}`
      ),
    readAll: (ownOnly: boolean) =>
      get<AdminProjectsList>(`${endpoints.projectsAdmin}?ownOnly=${ownOnly}`),
    setIsCompleted: (projectId: string, isCompleted: boolean) =>
      patch<boolean>(
        `${endpoints.projectsAdmin}/${projectId}/isCompleted`,
        isCompleted
      ),
    uploadFiles: (
      projectId: string,
      section: ProjectDocumentsSection,
      files: File[]
    ) => postFiles(`${endpoints.projectsAdmin}/${projectId}/${section}`, files),
    uploadFilesWithMessage: (
      projectId: string,
      section: ProjectDocumentsSection,
      files: File[],
      message: string
    ) => postFilesWithMessage(`${endpoints.projectsAdmin}/${projectId}/${section}`, files, message),
    deleteFile: (
      projectId: string,
      section: ProjectDocumentsSection,
      path: string
    ) => del(`${endpoints.projectsAdmin}/${projectId}/${section}/${path}`),
    listFiles: (projectId: string) =>
      get<ProjectFiles>(`${endpoints.projectsAdmin}/${projectId}/Files`),
    listDeliverables:(projectId: string) =>
      get<UploadItem[]>(`${endpoints.projectsAdmin}/${projectId}/Deliverables`),
    retryTransmittals: (projectId: string) =>
      put<void, void>(`${endpoints.projectsAdmin}/${projectId}/Transmittals`),
    deleteProjects: (projectIds: string[], deleteFiles: boolean = false) =>
      batchDeleteWithConfirmation(
        `${endpoints.projectsAdmin}/batch?deleteFiles=${deleteFiles}`,
        {projectIds}
      ),
  },
  projects: {
    readAll: (ownOnly: boolean) =>
      get<ProjectItem[]>(`${endpoints.projects}?ownOnly=${ownOnly}`),
    read: (projectId: string) =>
      get<ProjectDetails>(`${endpoints.projects}/${projectId}`),
    update: (projectId: string, payload: UpdatePropertyRequest) =>
      patch<UpdatePropertyRequest, ProjectDetails>(
        `${endpoints.projects}/${projectId}`,
        payload
      ),
    uploadFiles: (
      projectId: string,
      section: ProjectDocumentsSection,
      files: File[]
    ) => postFiles(`${endpoints.projects}/${projectId}/${section}`, files),
    uploadFilesWithMessage: (
      projectId: string,
      section: ProjectDocumentsSection,
      files: File[],
      message: string
    ) => postFilesWithMessage(`${endpoints.projects}/${projectId}/${section}`, files, message),
    deleteFile: (
      projectId: string,
      section: ProjectDocumentsSection,
      path: string
    ) => del(`${endpoints.projects}/${projectId}/${section}/${path}`),
    listFiles: (projectId: string) =>
      get<ProjectFiles>(`${endpoints.projects}/${projectId}/Files`),
    listDeliverables:(projectId: string) =>
      get<UploadItem[]>(`${endpoints.projects}/${projectId}/Deliverables`),
    readDocuments: (projectId: string) =>
      get<Document[]>(`${endpoints.projects}/${projectId}/Documents`),
    postMessage: (projectId: string, content: string) =>
      post<string, string>(
        `${endpoints.projects}/${projectId}/Messages`,
        content
      ),
    readMessagesCount: (projectId: string) =>
      get<number>(`${endpoints.projects}/${projectId}/Messages/Count`),
    readMessages: (projectId: string) =>
      get<Message[]>(`${endpoints.projects}/${projectId}/Messages`),
    batchUpdate: (payload: BatchUpdatePropertyRequest) =>
      patch<BatchUpdatePropertyRequest, ProjectDetails[]>(
        `${endpoints.projects}/batch`,
        payload
      ),
  },
  quotations: {
    create: (payload: CreateQuotation) =>
      post<CreateQuotation, string>(endpoints.quotations, payload),
    setStatus: (quotationId: string, statusId: QuotationStatusEnum) =>
      patch<QuotationStatusEnum, QuotationStatus>(
        `${endpoints.quotations}/${quotationId}/Status`,
        statusId
      ),
    readAll: (ownOnly: boolean, ongoingOnly: boolean) =>
      get<QuotationItem[]>(
        `${endpoints.quotations}?ownOnly=${ownOnly}&ongoingOnly=${ongoingOnly}`
      ),
    readQuotationDetails: (id: string) =>
      get<QuotationDetails>(`${endpoints.quotations}/${id}`),
    readTechnicalDetails: (id: string) =>
      get<QuotationTechnicalDetails>(
        `${endpoints.quotations}/${id}/TechnicalDetails`
      ),
    readAgreementDetails: (id: string) =>
      get<QuotationTechnicalDetails>(
        `${endpoints.quotations}/${id}/AgreementDetails`
      ),
    delete: (id: string) => del(`${endpoints.quotations}/${id}`),
    readFormData: (id: string) => get(`${endpoints.quotations}/${id}/Data`),
    listAggreements: (id: string) =>
      get<FileItem>(`${endpoints.quotations}/${id}/Agreements`),
    deleteAgreement: (id: string, path: string) =>
      del(`${endpoints.quotations}/${id}/Agreements/${path}`),
    update: (quotationId: string, payload: UpdatePropertyRequest) =>
      patch<UpdatePropertyRequest, QuotationDetails>(
        `${endpoints.quotations}/${quotationId}`,
        payload
      ),
    uploadAgreements: (id: string, files: File[]) =>
      postFiles(`${endpoints.quotations}/${id}/Agreements`, files),
    acceptAgreement: (quotationId: string, agreementId: string) =>
      put<string>(
        `${endpoints.quotations}/${quotationId}/Agreements/${agreementId}/Acceptance`
      ),
    rejectAgreement: (
      quotationId: string,
      agreementId: string,
      reason: string
    ) =>
      put<string, QuotationStatusEnum>(
        `${endpoints.quotations}/${quotationId}/Agreements/${agreementId}/Rejection`,
        reason
      ),
    updateTechnicalData: (quotationId: string, payload: any) =>
      put<FormData, any>(
        `${endpoints.quotations}/${quotationId}/Data`,
        payload
      ),
    uploadSignedFile: (
      quotationId: string,
      agreementId: string,
      files: File[]
    ) =>
      postFiles(
        `${endpoints.quotations}/${quotationId}/Agreements/${agreementId}/Signed`,
        files
      ),
    readPurchaseOrder: (id: string) =>
      get(`${endpoints.quotations}/${id}/PurchaseOrder`),
    uploadPurchaseOrders: (id: string, files: File[]) =>
      postFiles(`${endpoints.quotations}/${id}/PurchaseOrder/Files`, files),
    deletePurchaseOrderFile: (id: string, path: string) =>
      del(`${endpoints.quotations}/${id}/PurchaseOrder/Files/${path}`),
    updatePurchaseOrderReference: (quotationId: string, value: string) =>
      patch<string>(
        `${endpoints.quotations}/${quotationId}/PurchaseOrder/Reference`,
        value
      ),
    uploadFiles: (quotationId: string, files: File[]) =>
      postFiles(`${endpoints.quotations}/${quotationId}/Files`, files),
    deleteFile: (quotationId: string, path: string) =>
      del(`${endpoints.quotations}/${quotationId}/Files/${path}`),
    listFiles: (quotationId: string) =>
      get<UploadItem[]>(`${endpoints.quotations}/${quotationId}/Files`),
    postMessage: (quotationId: string, content: string) =>
      post<string, string>(
        `${endpoints.quotations}/${quotationId}/Messages`,
        content
      ),
    readMessagesCount: (quotationId: string) =>
      get<number>(`${endpoints.quotations}/${quotationId}/Messages/Count`),
    readMessages: (quotationId: string) =>
      get<Message[]>(`${endpoints.quotations}/${quotationId}/Messages`),

    batchUpdate: (payload: BatchUpdatePropertyRequest) =>
      patch<BatchUpdatePropertyRequest, BatchOperationResult>(
        `${endpoints.quotations}/batch`,
        payload
      ),
    batchDelete: (quotationIds: string[]) =>
      batchDelete(
        `${endpoints.quotations}/batch`,
        {quotationIds}
      ),
  },
  quotationForms: {
    update: (id: string, json: string, name: string) => {
      const form = new FormData()
      form.append('file', new File([json], name, { type: jsonContentType }))
      return put<FormData, void>(`${endpoints.quotationForms}/${id}`, form)
    },
    read: (id: string) => getText(`${endpoints.quotationForms}/${id}`)
  },
  services: {
    create: (payload: CreateService) =>
      post<CreateService, string>(endpoints.services, payload),
    patch: (id: string, payload: UpdatePropertyRequest) =>
      patch<UpdatePropertyRequest, ServiceDetails>(
        `${endpoints.services}/${id}`,
        payload
      ),
    readAll: () => get<ServiceDetails[]>(endpoints.services),
    readPublished: () => get<ServiceItem[]>(`${endpoints.services}/Published`),
    read: (id: string) => get<ServiceDetails>(`${endpoints.services}/${id}`),
    delete: (id: string) => del(`${endpoints.services}/${id}`)
  },
  transmittalFailures: {
    readAll: () => get<TransmittalFailure[]>(`${endpoints.transmittalFailures}`),
    readRelevantCount: () => get<number>(`${endpoints.transmittalFailures}/RelevantCount`),
    setRelevant: (id:string) => patch<TransmittalFailure>(`${endpoints.transmittalFailures}/${id}/Relevant`),
    setIrrelevant: (id:string) => patch<TransmittalFailure>(`${endpoints.transmittalFailures}/${id}/Irrelevant`)
  }
}

export default api
