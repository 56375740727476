import React, {useEffect, useMemo, useState } from 'react'
import {
  OButton,
  OCol,
  OLogo,
  ORow,
  OTooltip,
} from '@dnvgl-onefoundation/onedesign-react'
import { AdminProjectItemExtended, ItemSelectionState } from '../../interfaces'

import ProjectsList from './ProjectsList'
import SelectionBox from './SelectionBox'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import SelectItem from './SelectItem'
import ItemActionsBox from './ItemActionsBox'
import { checkInternalUsers } from '../../store/slices/userGroups'
import InputWithIcon from './InputWithIcon'

interface Props {
  items: AdminProjectItemExtended[] 
  isInternalUser: boolean
  showStatusName?: boolean
  openProject: (id: string) => void
  deleteProjects: (ids: string[]) => void
  setPM: (projectIds: string[], userId: string) => void
}

const SelectableProjectsList = (props: Props) => {
  const { items, isInternalUser, openProject, deleteProjects, setPM } = props

  const [filter, setFilter] = useState<string>("");
  const onFilterChange = (value: string) => {
    setFilter(value.toLowerCase());
  }
  const onClearFilter = () => {
    setFilter("");
  }
  
  function isItemVisible(i: AdminProjectItemExtended): boolean {
    if (!filter) return true;

    return i.name.toLowerCase().includes(filter)
      || i.companyName.toLowerCase().includes(filter)
      || (!!i.teamcenterId && i.teamcenterId.toString().toLowerCase().includes(filter))
      || i.contactPerson.toLowerCase().includes(filter);
  }
  
  const visibleItems = items.filter(i => isItemVisible(i))
  const [selectedItems, setSelectedItems] = useState<ItemSelectionState[]>([]);

  useEffect(() => {
    setSelectedItems(visibleItems.map(i => ({itemId: i.id, isSelected: false} as ItemSelectionState)));
  }, [items, filter])

  const onSelectAllClick = (val: boolean) => {
    setSelectedItems(visibleItems.map(i => ({itemId: i.id, isSelected:val} as ItemSelectionState)));
  }

  const onItemSelectChanged = (id:string, val: boolean) =>
  {
    setSelectedItems( (prevSelection) => {
      const item = prevSelection?.find(x => x.itemId == id);
      if (item != null) {
        item.isSelected = val;
        return prevSelection.map(i => ({itemId: i.itemId, isSelected:i.isSelected} as ItemSelectionState));
      } else {
        return [...prevSelection, {itemId: id, isSelected: val} as ItemSelectionState];
      }
    });
  }

  const isAllSelected = selectedItems != null && selectedItems.length > 0 && !selectedItems?.find(x => !x.isSelected);
  const isItemSelected = (id: string) : boolean => {
    return selectedItems?.find(x => x.itemId === id)?.isSelected ?? false;
  }

  const selectableCount = selectedItems.length;
  const selectedCount = selectedItems.filter(i => i.isSelected).length;

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(checkInternalUsers())
  }, [dispatch])

const internalUsers = useAppSelector(state => state.userGroups.internalUsers)

const onBatchContactPersonAsign = (userIds: string[]) => {
  const projectIds = selectedItems.filter(i => i.isSelected).map(i => i.itemId);
  const userId = userIds[0];
  setPM(projectIds, userId);
}
const onProjectContactPersonAsign = (projectId: string, userIds: string[]) => {
  setPM([projectId], userIds[0]);
}

const onDeleteProject = (projectId: string) => {
  deleteProjects([projectId]);
}
const onDeleteProjects = () => {
  deleteProjects(selectedItems.filter(i => i.isSelected).map(i => i.itemId));
}

const tableItems = useMemo(() => {
  const vmItems = visibleItems.map(i => ({
    ...i,
    isSelected: <SelectionBox isChecked={isItemSelected(i.id)} id={i.id} onSelChange = {onItemSelectChanged} />,
    actions: <ItemActionsBox 
      itemId={i.id} 
      users={internalUsers} 
      userAssignTooltip="Assign DNV PM"
      onUserAssign={onProjectContactPersonAsign} 
      deleteTooltip="Delete project"
      onDelete={onDeleteProject} />
  }))
  return vmItems;
},[items, selectedItems, filter])

  return (
    <>
    <ORow className="text-right align-items-center white-space">
      <OCol col='12'>
        <span className='d-inline-flex align-items-start justify-content-between w-100'>
          <div className="custom-control custom-checkbox" style={{maxWidth: '220px'}}>
            <input 
              autoComplete="off"
              className="custom-control-input"
              id="selectAllCheckBox"
              type="checkbox" 
              disabled = {selectedItems.length == 0}
              checked={isAllSelected}
              onChange={e => {
                onSelectAllClick(e.target.checked);
              }}
              />
            <label 
              className="custom-control-label"
              htmlFor="selectAllCheckBox">
            Select All
            </label>
          </div>
          <span className='d-inline-flex align-items-center justify-content-end'>
            <div style={{margin:'5px'}}>
              {selectedCount} from {selectableCount} items selected
            </div>
            <InputWithIcon
              icon='fas fa-filter'
              placeholder="Filter ..."
              value={filter}
              style={{maxWidth: '220px'}}
              onChange={onFilterChange}
            />
            <div>
              <OTooltip
              className=''
              content="Clear filter"
              placement='bottom'
              >
                <OButton 
                  disabled={!filter}
                  iconClass='fas fa-filter text-danger' 
                  variant='flat' 
                  onClick={onClearFilter} />
              </OTooltip>
              <SelectItem
                disabled={selectedCount == 0}
                tooltipContent="Assign DNV Contact Person"
                titleText={<OLogo style={{ maxWidth: '80px'}} />}
                items={internalUsers}
                iconClass="fas fa-user-edit"
                okText="Assign Contact Person"
                onSelected={onBatchContactPersonAsign}
              />
              <OTooltip
                content="Delete projects"
                placement='bottom' >
              <OButton
                disabled={selectedCount == 0}
                iconClass='fas fa-trash text-danger'
                variant='flat'
                onClick={onDeleteProjects}
                >
              </OButton>
              </OTooltip>
            </div>
          </span>
        </span>
      </OCol>
    </ORow>
    <ProjectsList
        items={tableItems}
        isInternalUser={isInternalUser}
        showStatusName
        showFilterButton={false}
        onOpen={openProject}
         />
    </>
  )
}

export default React.memo(SelectableProjectsList)

