import React, { useEffect, useState, useMemo, useCallback } from 'react'
import {
  OToggleSwitch,
  showSuccess,
  showError,
  showConfirm,
  ORow,
  OCol
} from '@dnvgl-onefoundation/onedesign-react'
import { useNavigate } from 'react-router-dom'
import Page from '../layout/Page'
import { ContentSection } from '../layout'
import {
  ProjectAdd,
  ProjectsList,
  StatusIndicator,
  AccessModeIndicator,
  TeamBadges
} from '../helpers'
import { AddQuotation } from '../quotations'
import { api, helper, config } from '../../utils'

import {
  readAllAdminProjects,
  readAllProjects
} from '../../store/slices/projects'
import { readAllQuotationItems } from '../../store/slices/quotations'
import { checkGroups } from '../../store/slices/userGroups'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

import {
  CreateProject,
  AdminProjectItem,
  AdminProjectItemExtended,
  ProjectItemExtended,
  ProjectItem,
  ProgressEnum,
  QuotationItem,
  QuotationStatusEnum,
  QuotationTab
} from '../../interfaces'
import { useUserGroups } from '../helpers/hooks'
import SelectableProjectsList from '../helpers/SelectableProjectsList'

interface Props {
  title: string
}

const ProjectsOverviewPage = (props: Props) => {
  const { title } = props
  const { paths, quotationPage } = config
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isLoadingProjects, adminProjectsList, projects } = useAppSelector(
    state => state.projects
  )
  const { getGroup } = useUserGroups()
  const { currentUser, isInternalUser } = useAppSelector(state => state.users)
  const quotationsEnabled = useAppSelector(
    state => state.configuration.featureToggles.quotations
  )
  const [ownOrdersOnly, setOwnOrdersOnly] = useState(true)

  useEffect(() => {
    dispatch(checkGroups())
  }, [dispatch])

  const readOrders = useCallback(() => {
    dispatch(
      isInternalUser
        ? readAllAdminProjects(ownOrdersOnly)
        : readAllProjects(ownOrdersOnly)
    )
  }, [dispatch, isInternalUser, ownOrdersOnly])

  useEffect(() => {
    readOrders()
  }, [readOrders])

  const openOrder = (id: string) => navigate(`${paths.orders}/${id}`)
  const onAdd = (payload: CreateProject) =>
    !!payload?.name &&
    api.projectsAdministration
      .create(payload)
      .then(data => {
        showSuccess('Successfully added', data.name)
        openOrder(data.id)
      })
      .catch(() => showError('An error occurred.'))

  const onDelete = (id: string, deleteFiles: boolean = false) =>
    api.projectsAdministration
      .deleteProject(id, deleteFiles)
      .then(data => {
        if (data.error)
          showConfirm('Delete', data.error).then((confirmed: boolean) => {
            if (confirmed) onDelete(id, true)
          })
        else if (!data.isFailure) {
          showSuccess('Deleted', 'The project successfully deleted.')
          readOrders()
        } else showError('Delete', 'Failed to delete the project.')
      })
      .catch(helper.handleErrorMessage)

    const onDeleteProjects = (ids: string[], deleteFiles: boolean = false) =>
      api.projectsAdministration
        .deleteProjects(ids, deleteFiles)
        .then(data => {
          if (data.error)
            showConfirm('Delete', data.error).then((confirmed: boolean) => {
              if (confirmed) onDeleteProjects(ids, true)
            })
          else if (!data.isFailure) {
            showSuccess('Deleted', ids.length +' projects successfully deleted.')
          } else showError('Delete', 'Failed to delete the project(s).')
          readOrders()
        })
        .catch(helper.handleErrorMessage)

    const onAssignPM = (projectIds: string[], userId: string) =>
        api.projects.batchUpdate({itemIds: projectIds, propertyName: 'projectManagerId', propertyValue: userId})
        .then(data => {
          if (data.length == projectIds.length) {
            showSuccess('Updated', projectIds.length +' projects updated successfully.')
          } else showError('Update', 'Failed to update the project(s).')
          readOrders()
        })
        .catch(helper.handleErrorMessage)
  
  const orders = useMemo(() => {
    if (isInternalUser) {
      const mapItems = (
        items: AdminProjectItem[]
      ): AdminProjectItemExtended[] =>
        items?.map(item => ({
          ...item,
          statusName: <StatusIndicator statusName={item.status.name} />,
          companyName: getGroup(item.companyId)?.name ?? '–',
          contactPerson: item.dnvContact?.fullName ?? '-'
        }))

      const orderViewModels = mapItems(
        adminProjectsList?.items?.length ? adminProjectsList.items : []
      )

      return {
        ongoing: orderViewModels.filter(
          i => i.status.progress !== ProgressEnum.Completed
        ),
        completed: orderViewModels.filter(
          i => i.status.progress === ProgressEnum.Completed
        )
      }
    } else {
      const mapItems = (items: ProjectItem[]): ProjectItemExtended[] =>
        items?.map(item => ({
          ...item,
          statusName: <StatusIndicator statusName={item.status.name} />,
          accessMode: <AccessModeIndicator isReadOnly={item.isReadOnly} />,
          teams: item.teamIds.length ? (
            <TeamBadges
              teams={item.teamIds.map(teamId => getGroup(teamId))}
              maxWidth={200}
              initials
            />
          ) : (
            <></>
          ),
          customerContactName: item.customerContact
            ? `${item.customerContact.firstName} ${item.customerContact.lastName}`
            : '–'
        }))

      const orderViewModels = mapItems(projects)

      return {
        ongoing: orderViewModels.filter(
          i => i.status.progress !== ProgressEnum.Completed
        ),
        completed: orderViewModels.filter(
          i => i.status.progress === ProgressEnum.Completed
        )
      }
    }
  }, [adminProjectsList?.items, isInternalUser, projects, getGroup])

  const isLoading = isLoadingProjects

  return (
    <Page
      title={title}
      isLoading={isLoading}
      disabled={isLoading}
      actions={
        isInternalUser ? (
          <ProjectAdd entityName="Order" onOK={onAdd} />
        ) : (
          !!quotationsEnabled &&
          !!currentUser && <AddQuotation currentUser={currentUser} />
        )
      }>
      <ContentSection>
        <div>
          <ORow>
            <OCol md="6">
              <h3 className="m-0">
                <StatusIndicator statusName="Ongoing" />
              </h3>
            </OCol>
            <OCol md="6" className="text-right pt-1">
              <OToggleSwitch
                small
                textLocation="hidden"
                className="d-inline-block pointer"
                checked={ownOrdersOnly}
                onChange={() => setOwnOrdersOnly(!ownOrdersOnly)}>
                My orders only
              </OToggleSwitch>
            </OCol>
          </ORow>
          {isInternalUser &&
          <SelectableProjectsList
          items={orders.ongoing as AdminProjectItemExtended[]}
          isInternalUser={isInternalUser}
          showStatusName
          openProject={openOrder}
          deleteProjects={onDeleteProjects}
          setPM={onAssignPM}
        />
          }
          {!isInternalUser &&
          <ProjectsList
          items={orders.ongoing}
          isInternalUser={isInternalUser}
          onOpen={openOrder}
        />
          }
        </div>
        <div className="mt-4">
          <h3 className="m-0">
            <StatusIndicator statusName="Completed" />
          </h3>
          <ProjectsList
            items={orders.completed}
            isInternalUser={isInternalUser}
            onOpen={openOrder}
          />
        </div>
      </ContentSection>
    </Page>
  )
}

export default React.memo(ProjectsOverviewPage)
