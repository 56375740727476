import React from 'react'
import { useAppSelector } from '../../store/hooks'
import { OButton, colors } from '@dnvgl-onefoundation/onedesign-react'
import { config } from '../../utils'
import { NavLink } from 'react-router-dom'

const HomePage = () => {
  const users = useAppSelector(state => state.users)
  const { appFullName, paths, links, cdnResources } = config

  const welcomeMessage = users.currentUser?.id
    ? `${users.currentUser.firstName}, welcome to ${appFullName}.`
    : `Welcome to ${appFullName}!`

  return (
    <main
      className="container-fluid p-0 position-relative"
      style={{ flex: '1' }}>
      <div style={{ backgroundColor: colors.darkBlue, minHeight: '200px' }}>
        <div className="position-absolute w-100">
          <video
            className="w-100"
            autoPlay
            loop
            muted
            src={`${cdnResources.baseUrl}/${cdnResources.backgroundVideo}`}
          />
        </div>
        <div className="position-absolute w-100 text-center">
          <h1 className="dnv-display-regular fg-primary-white d-inline-block mt-5">
            {welcomeMessage}
          </h1>
          {!users.isLoadingCurrentUser && (
            <div className="mt-5">
              {users.currentUser?.id ? (
                <>
                <NavLink to={paths.quotations}>
                  <OButton
                    iconClass="fal fa-clipboard-list-check"
                    variant="primary">
                    Quotations
                  </OButton>
                </NavLink>
                <NavLink to={paths.orders}>
                  <OButton
                    iconClass="fal fa-clipboard-list-check"
                    variant="primary">
                    Orders
                  </OButton>
                </NavLink>
                </>
            ) : (
                <>
                  <a href={links.header.signUp}>
                    <OButton iconClass="fal fa-user-plus" variant="primary">
                      Sign up
                    </OButton>
                  </a>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <a
                    href={`${links.header.logIn}?returnUrl=${encodeURIComponent(
                      window.location.pathname
                    )}`}>
                    <OButton iconClass="fal fa-sign-in-alt" variant="primary">
                      Log in
                    </OButton>
                  </a>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </main>
  )
}

export default React.memo(HomePage)
