import moment, { Moment } from 'moment-timezone'
import config from './config'

const { dateFormat, shortDateFormat, dateTimeFormat, defaultTimeZone } = config

const getValidDate = (date: string) => (date !== 'Invalid date' ? date : '–')

const momentHelper = {
  toHumandFriendlyDate: (date?: Date | Moment, short = false) =>
    getValidDate(moment(date).format(short ? shortDateFormat : dateFormat)),
  toHumandFriendlyDateTime: (date?: Date | Moment | string) =>
    getValidDate(moment(date).format(dateTimeFormat)),
  getPastDate: (hours: number = 0) =>
    moment().tz(defaultTimeZone).subtract(hours, 'hours').toDate()
}

export default momentHelper
