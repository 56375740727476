import React, { ReactNode } from 'react'

interface Props {
  title?: ReactNode
  icon?: string
  disabled?: boolean
  className?: string
  noMarginTop?: boolean
  children?: ReactNode
}

const ContentSection = (props: Props) => {
  const { title, icon, disabled, className = '', noMarginTop, children } = props
  const pointerEvents: 'none' | 'auto' = disabled ? 'none' : 'auto'
  const opacity: number = disabled ? 0.3 : 1

  return (
    <div
      className={`card card-dnvgl p-4 mt-${noMarginTop ? 0 : 3} ${className}`}
      style={{ pointerEvents, opacity }}>
      <div className={`color-dark-blue ${!!title ? 'mb-3' : ''}`}>
        {title && <span className="font-weight-bold">{title}</span>}
        {icon && <i className={`${icon} float-right mt-1`}></i>}
      </div>
      <div className="mt-0">{children}</div>
    </div>
  )
}

export default ContentSection
