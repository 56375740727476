import React, { useCallback, useEffect, useState } from 'react'
import {
  ORow,
  OCol,
  FileDrop,
  showSuccess,
  showPrompt
} from '@dnvgl-onefoundation/onedesign-react'
import TabContent from '../../layout/TabContent'
import {
  FileUpload,
  HelperText,
  FileList,
  DetailsItem,
  UpdateButton,
  MissingValueWrapper,
  TabTitleIndicator
} from '../../helpers'
import { FileItem, PurchaseOrder } from '../../../interfaces'
import { api, helper, config } from '../../../utils'
import { deleteConfirm } from '../../modals'
import { useAppDispatch } from '../../../store/hooks'
import { readPurchaseOrder } from '../../../store/slices/quotations'

interface Props {
  id?: string
  disabled?: boolean
  purchaseOrder: PurchaseOrder | null
  purchaseOrderUpdateAllowed: boolean
  isInternalUser: boolean
  helperText: string
}

const { endpoints } = config
const { handleErrorMessage } = helper

const QuotationPagePurchaseOrderTab = (props: Props) => {
  const {
    id,
    disabled,
    purchaseOrder,
    purchaseOrderUpdateAllowed,
    isInternalUser,
    helperText
  } = props

  const dispatch = useAppDispatch()
  const [isUploading, setIsUploading] = useState(false)

  const downloadUrlBuilder = (path: string) =>
    `${endpoints.quotations}/${id}/PurchaseOrder/Files/${path}`

  const refresh = useCallback(() => {
    if (id) dispatch(readPurchaseOrder(id))
  }, [id, dispatch])

  useEffect(() => refresh(), [refresh])

  const promptToUpdateReference = (title: string, value: string) =>
    showPrompt(title, value).then(newValue => {
      if (id && !!newValue) {
        api.quotations
          .updatePurchaseOrderReference(id, newValue as string)
          .then(() => {
            showSuccess('Successfully updated!')
            refresh()
          })
          .catch(handleErrorMessage)
      }
    })

  const onUpload = (files: FileDrop[]) => {
    if (!files?.length) return
    const fileNames = files.map(file => file.file.name)
    setIsUploading(true)
    api.quotations
      .uploadPurchaseOrders(
        `${id}`,
        files.map(x => x.file)
      )
      .then(() => {
        showSuccess('Uploaded', fileNames.join(', '))
        refresh?.()
      })
      .catch(handleErrorMessage)
      .finally(() => setIsUploading(false))
  }

  const onDelete = (file: FileItem) =>
    deleteConfirm(file.name).then(confirmed => {
      if (confirmed)
        api.quotations
          .deletePurchaseOrderFile(`${id}`, file.path)
          .then(() => {
            showSuccess('Deleted', file.name)
            refresh?.()
          })
          .catch(handleErrorMessage)
    })

  return (
    <TabContent
      title={
        <TabTitleIndicator
          title="Purchase Order"
          isValid={
            isInternalUser
              ? null
              : !!purchaseOrder?.files?.length || !!purchaseOrder?.reference
          }
        />
      }
      disabled={disabled}>
      <ORow>
        <OCol md="12">
          {!isInternalUser &&
            !purchaseOrder?.files?.length &&
            purchaseOrderUpdateAllowed && <HelperText message={helperText} />}
        </OCol>

        <DetailsItem
          title="Purchase order reference (optional)"
          gridColumns="6"
          disabled={!purchaseOrder}>
          <MissingValueWrapper value={purchaseOrder?.reference} message="N/A" />
          {purchaseOrderUpdateAllowed && (
            <UpdateButton
              onClick={() =>
                promptToUpdateReference(
                  'Purchase order reference',
                  `${purchaseOrder?.reference ?? ''}`
                )
              }
            />
          )}
        </DetailsItem>

        {!isInternalUser && purchaseOrderUpdateAllowed && (
          <OCol md="6" sm="12">
            <FileUpload
              isUploadAllowed
              isUploading={isUploading}
              autoHideDropArea
              maxFiles={5}
              maxBytes={250000000}
              acceptExtensions=".pdf"
              onUpload={onUpload}
            />
          </OCol>
        )}

        <OCol md="12">
          {!!purchaseOrder?.files?.length && (
            <FileList
              isInternalUser={isInternalUser}
              files={purchaseOrder?.files ? purchaseOrder.files : []}
              isDeleteAllowed={!isInternalUser && purchaseOrderUpdateAllowed}
              notifyEmptyList={false}
              onDelete={onDelete}
              downloadUrlBuilder={downloadUrlBuilder}
            />
          )}
        </OCol>
      </ORow>
    </TabContent>
  )
}

export default React.memo(QuotationPagePurchaseOrderTab)
