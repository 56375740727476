import React from 'react'
import { Page, ContentSection } from '../../layout'
import { AppName } from '../../helpers'

const AboutPage = () => (
  <Page title="About">
    <ContentSection>
      <p>
        <AppName /> is DNV Energy systems’ easy-to-use customer portal/
        application, specifically targeted for smaller assurance and advisory
        services.
      </p>
      <p>
        <AppName /> provides an overview of ongoing quotations and orders,
        status and all functionality to follow-up the order, supporting
        collaboration of the order with the company team and the DNV engineers
        providing the service.
      </p>
      <p>
        This introduction supports a Beta release of the&nbsp;
        <AppName />
        &nbsp; application, intended to collect initial user feedback and input
        for further improvements. Giving this status, inconsistencies and less
        sophisticated functionality may be experienced. With this status DNV
        apologizes for any inconvenience caused and at the same time appreciates
        your attention, engagement and feedback provided.
      </p>
    </ContentSection>
  </Page>
)

export default React.memo(AboutPage)
