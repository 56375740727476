import React, { CSSProperties } from 'react'
import { OButton } from '@dnvgl-onefoundation/onedesign-react'

export const getFeedbackLink = () => {
  const toEmail = 'l.schmidt@dnv.com'
  const ccEmail = 'damian.fijorek@dnv.com'
  const subject = `DNV Direct Feedback`
  const url = window.location.href
  return `mailto:${toEmail}?subject=${subject}&cc=${ccEmail}&body=%0D%0A%0D%0ALink: ${url}`
}

const Feedback = () => {
  const fixedContainerStyles = {
    position: 'fixed',
    top: '40%',
    right: '-28px',
    transform: 'rotate(-90deg)'
  }

  return (
    <div style={fixedContainerStyles as CSSProperties}>
      <a href={getFeedbackLink()}>
        <OButton className="no-print" variant="primary" size="small">
          Feedback
        </OButton>
      </a>
    </div>
  )
}

export default React.memo(Feedback)
