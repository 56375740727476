import React, { useState, ReactNode, useEffect, CSSProperties } from 'react'
import {
  OButton,
  OTooltip,
} from '@dnvgl-onefoundation/onedesign-react'
import SelectItem from './SelectItem'
import { User } from '../../interfaces'

interface Props {
    itemId:string
    users: User[]
    className?: string
    style?: CSSProperties
    userAssignTooltip?: string
    onUserAssign?: (projectId: string, userIds: string[]) => void
    deleteTooltip?: string
    onDelete?: (projectId: string) => void
}

const ItemActionsBox = (props: Props) => {
    const {
        itemId,
        users,
        className,
        style,
        userAssignTooltip,
        onUserAssign,
        deleteTooltip,
        onDelete,
      } = props
   
    const classes = ['container', 'text-right']
    if (className) classes.push(className)

    const isDeleteSupported: boolean = !!onDelete;
    const isUserAssignSupported: boolean = !!onUserAssign;
  
    return (
        <div className={classes.join(' ')} 
          style={{minWidth:90, padding:0}}>
          {isUserAssignSupported && (
            <SelectItem
              tooltipContent={userAssignTooltip}
              titleText={userAssignTooltip}
              items={users}
              isInside={true}
              iconClass="fas fa-user-edit"
              okText="Assign Contact Person"
              onSelected={userIds => {onUserAssign?.(itemId, userIds)}}
            />
        )}
        {isDeleteSupported && (
            <OTooltip content={deleteTooltip}>
            <OButton 
              iconClass='fas fa-trash text-danger' 
              variant='flat' 
              onClick={ e => {
                onDelete?.(itemId);
                e.stopPropagation();
              }}>
            </OButton>
            </OTooltip>
        )}
        </div>
      )
}

export default React.memo(ItemActionsBox)
