import React, { CSSProperties } from 'react'
import { OTooltip } from '@dnvgl-onefoundation/onedesign-react'

interface Props {
  isReadOnly: boolean
  style?: CSSProperties
}

export const getAccessModeText = (isReadOnly: boolean) =>
  isReadOnly ? 'Read Only' : 'Read and Write'

const AccessModeIndicator = (props: Props) => {
  const { isReadOnly, style } = props

  return (
    <OTooltip content={getAccessModeText(isReadOnly)}>
      <i
        style={{ ...style }}
        className={`fal fa-${isReadOnly ? 'eye' : 'pen-alt'} p-1`}></i>
    </OTooltip>
  )
}

export default React.memo(AccessModeIndicator)
