import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api, helper, transform, momentHelper } from '../../utils'
import { AsyncAction } from '../index'

import {
  AdminProjectsList,
  FileItem,
  UploadItem,
  ProjectDetails,
  ProjectDocumentsSection,
  ProjectItem,
  Document,
  ProjectFiles
} from '../../interfaces'
import { getErrorHandler } from './users'

export interface ProjectsState {
  isLoadingProjects: boolean
  adminProjectsList: AdminProjectsList | null
  projects: ProjectItem[]
  isLoadingProject: boolean
  project: ProjectDetails | null
  isLoadingProjectFiles: boolean
  projectFiles: {
    uploadMessage?: string
    [ProjectDocumentsSection.Documents]: FileItem[] | UploadItem[]
    [ProjectDocumentsSection.Deliverables]: FileItem[]
  }
  projectDocuments: Document[]
}

const initialState: ProjectsState = {
  isLoadingProjects: false,
  adminProjectsList: null,
  projects: [],
  isLoadingProject: false,
  project: null,
  isLoadingProjectFiles: false,
  projectFiles: {
    [ProjectDocumentsSection.Documents]: [],
    [ProjectDocumentsSection.Deliverables]: []
  },
  projectDocuments: []
}

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setIsLoadingProjects: (state, action) => {
      state.isLoadingProjects = action.payload
    },
    setAdminProjectsList: (state, action: PayloadAction<AdminProjectsList>) => {
      const data = action.payload

      data.items = transform(data.items, [
        {
          property: 'updatedAt',
          handler: momentHelper.toHumandFriendlyDateTime
        },
        {
          property: 'teamcenterId',
          handler: helper.nullHandler
        }
      ])

      state.adminProjectsList = data
    },
    setProjects: (state, action: PayloadAction<ProjectItem[]>) => {
      const data = action.payload

      const projects = transform(data, [
        {
          property: 'updatedAt',
          handler: momentHelper.toHumandFriendlyDateTime
        },
        {
          property: 'teamcenterStatus',
          handler: helper.nullHandler
        }
      ])

      state.projects = projects
    },
    setIsLoadingProject: (state, action: PayloadAction<boolean>) => {
      state.isLoadingProject = action.payload
    },
    setProject: (state, action: PayloadAction<ProjectDetails | null>) => {
      state.project = action.payload
    },
    resetProject: state => {
      state.project = null
    },
    setisLoadingProjectFiles: (state, action: PayloadAction<boolean>) => {
      state.isLoadingProjectFiles = action.payload
    },
    clearProjectFiles: (state, action: PayloadAction<ProjectDocumentsSection>) => {
      state.projectFiles[action.payload] = []
    },
    setProjectFiles: (
      state,
      action: PayloadAction<{
        data: ProjectFiles
      }>
    ) => {
      state.projectFiles[ProjectDocumentsSection.Documents] = action.payload.data.files
      state.projectFiles.uploadMessage = action.payload.data.message
    },
    setProjectDeliverables: (
      state,
      action: PayloadAction<{
        files: FileItem[]
      }>
    ) => {
        state.projectFiles[ProjectDocumentsSection.Deliverables] = action.payload.files
    },
    setProjectDocuments: (state, action) => {
      state.projectDocuments = action.payload
    }
  }
})

export const { actions } = projectsSlice

export const readAllProjects: (ownOnly: boolean) => AsyncAction =
  ownOnly => dispatch => {
    dispatch(actions.setIsLoadingProjects(true))
    return api.projects
      .readAll(ownOnly)
      .then(data => {
        dispatch(actions.setProjects(data))
      })
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsLoadingProjects(false)))
  }

export const readAllAdminProjects: (ownOnly: boolean) => AsyncAction =
  ownOnly => dispatch => {
    dispatch(actions.setIsLoadingProjects(true))
    return api.projectsAdministration
      .readAll(ownOnly)
      .then(data => {
        dispatch(actions.setAdminProjectsList(data))
      })
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsLoadingProjects(false)))
  }

export const read: (projectId: string) => AsyncAction =
  projectId => dispatch => {
    dispatch(actions.setIsLoadingProject(true))
    dispatch(actions.setProject(null))
    return api.projects
      .read(projectId)
      .then(data => {
        dispatch(actions.setProject(data))
      })
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsLoadingProject(false)))
  }

export const readProjectFiles: (
  projectId: string,
  section: ProjectDocumentsSection,
  isInternalUser: boolean
) => AsyncAction = (projectId, section, isInternalUser) => dispatch => {
  dispatch(actions.setisLoadingProjectFiles(true))
  dispatch(actions.clearProjectFiles(section))
  
  const read = isInternalUser
    ? (section == ProjectDocumentsSection.Documents ? api.projectsAdministration.listFiles : api.projectsAdministration.listDeliverables)
    : (section == ProjectDocumentsSection.Documents ? api.projects.listFiles : api.projects.listDeliverables)

  return read(projectId)
    .then(data => {
      if (section == ProjectDocumentsSection.Documents) {
        dispatch( actions.setProjectFiles({ data: data as ProjectFiles }))
      }
      else {
        dispatch( actions.setProjectDeliverables({ files: data as FileItem[] }))
      }
    })
    .catch(getErrorHandler(dispatch))
    .finally(() => dispatch(actions.setisLoadingProjectFiles(false)))
}

export const readProjectDocuments: (projectId: string) => AsyncAction =
  projectId => dispatch => {
    dispatch(actions.setProjectDocuments([]))

    return api.projects
      .readDocuments(projectId)
      .then(data => {
        if (data.length) dispatch(actions.setProjectDocuments(data))
      })
      .catch(getErrorHandler(dispatch))
  }
