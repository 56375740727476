import React, { ReactNode, useEffect, useState } from 'react'
import {
  ORow,
  GridColumns,
  OToggleSwitch,
  OTooltip,
  OAlert,
  OCol
} from '@dnvgl-onefoundation/onedesign-react'

import { useAppSelector } from '../../../store/hooks'
import TabContent from '../../layout/TabContent'
import {
  AddressEditor,
  DetailsItem,
  HelperText,
  MissingValueWrapper,
  UpdateButton
} from '../../helpers'
import { value } from '../../../interfaces'

interface Props {
  title?: ReactNode
  disabled?: boolean
  isEditable?: boolean
  isInternalUser?: boolean
  helperText: string
  promptToUpdate: (title: string, value: string, propertyName: string) => void
  onUpdate: (propertyName: string, propertyValue: value) => void
}

const QuotationPageCustomerInfoTab = (props: Props) => {
  const {
    title,
    disabled,
    isEditable,
    isInternalUser,
    helperText,
    promptToUpdate,
    onUpdate
  } = props

  const [items, setItems] = useState<any[]>([])

  const { quotationDetails, isQuotationDetailsValid } = useAppSelector(
    s => s.quotations
  )

  const toggleBillingAddress = () =>
    onUpdate(
      'billingAddress',
      quotationDetails?.billingAddress
        ? undefined
        : quotationDetails?.companyAddress
    )

  useEffect(() => {
    setItems([
      {
        title: 'Company Name',
        content: (
          <>
            {quotationDetails?.companyName}
            {isEditable && (
              <UpdateButton
                onClick={() =>
                  promptToUpdate(
                    'Company Name',
                    `${quotationDetails?.companyName ?? ''}`,
                    'companyName'
                  )
                }
              />
            )}
          </>
        ),
        isVisible: true,
        gridColumns: '6'
      },
      {
        title: 'VAT/Tax Number',
        content: (
          <>
            <MissingValueWrapper
              value={quotationDetails?.vat}
              message="Please add VAT/Tax Number"
            />
            {isEditable && (
              <UpdateButton
                onClick={() =>
                  promptToUpdate(
                    'VAT/Tax Number',
                    `${quotationDetails?.vat ?? ''}`,
                    'vat'
                  )
                }
              />
            )}
          </>
        ),
        isVisible: true,
        gridColumns: '6'
      },
      {
        title: 'Company Address',
        content: (
          <AddressEditor
            isEditable={isEditable}
            title="Company Address"
            address={quotationDetails?.companyAddress}
            missingValueText="Please add address"
            onUpdate={address => onUpdate('companyAddress', address)}
          />
        ),
        isVisible: true,
        gridColumns: '6'
      },
      {
        title: (
          <span>
            Invoice Address&nbsp;
            {!!quotationDetails?.companyAddress && (
              <OTooltip
                content={
                  !quotationDetails?.billingAddress
                    ? 'Toggle to unique address'
                    : 'Toggle to same as company address'
                }
                placement="top">
                {isEditable && (
                  <OToggleSwitch
                    small
                    className="pointer d-inline"
                    textLocation="hidden"
                    checked={!!quotationDetails?.billingAddress}
                    onChange={toggleBillingAddress}
                  />
                )}
              </OTooltip>
            )}
          </span>
        ),
        content: quotationDetails?.billingAddress ? (
          <AddressEditor
            isEditable={isEditable}
            title="Invoice Address"
            address={quotationDetails?.billingAddress}
            onUpdate={address => onUpdate('billingAddress', address)}
          />
        ) : (
          <p>Same as company address</p>
        ),
        isVisible: true,
        gridColumns: '6',
        disabled: !quotationDetails?.companyAddress
      }
    ])
  }, [quotationDetails]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TabContent title={title} disabled={disabled}>
      <ORow>
        {!isInternalUser && !isQuotationDetailsValid && (
          <OCol md="12">
            <HelperText message={helperText} />
          </OCol>
        )}
        {!quotationDetails?.companyAddress && !isInternalUser && (
          <OCol md="12">
            <OAlert
              variant="warning"
              description={
                <span>
                  Company Address is required. Please add it using the&nbsp;
                  <i className="fal fas fa-edit fg-warm-grey"></i>&nbsp;button
                  to the right.
                </span>
              }
              dismissable={false}
            />
          </OCol>
        )}
        {!quotationDetails?.vat && !isInternalUser && (
          <OCol md="12">
            <OAlert
              variant="warning"
              description={
                <span>
                  VAT/Tax Number is required. Please add it using the&nbsp;
                  <i className="fal fas fa-edit fg-warm-grey"></i>&nbsp;button
                  to the right.
                </span>
              }
              dismissable={false}
            />
          </OCol>
        )}
        {items
          ?.filter(item => item.isVisible)
          .map(item => (
            <DetailsItem
              gridColumns={item.gridColumns as GridColumns}
              title={item.title}
              key={item.title}
              disabled={item?.disabled}>
              {item.content}
            </DetailsItem>
          ))}
      </ORow>
    </TabContent>
  )
}

export default React.memo(QuotationPageCustomerInfoTab)
