import React from 'react'
import { OButton, OTooltip } from '@dnvgl-onefoundation/onedesign-react'
import { Link } from 'react-router-dom'

interface Props {
  tooltip: string
  linkTo: string
}

const BackButton = (props: Props) => {
  const { tooltip, linkTo } = props
  return (
    <Link to={linkTo}>
      <OTooltip content={tooltip}>
        <OButton variant="flat">
          <i className="fal fa-chevron-left"></i>
        </OButton>
      </OTooltip>
    </Link>
  )
}

export default React.memo(BackButton)
