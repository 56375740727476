import React from 'react'
import { OBadge } from '@dnvgl-onefoundation/onedesign-react'
import { UserGroupItem } from '../../interfaces'
import styles from './TeamsBadges.module.css'

interface IProps {
  teams: (UserGroupItem | undefined)[]
  maxWidth?: number
  initials?: boolean
}

const toInitials = (text: string) => {
  const words = text.split(' ')
  return words.length > 1 ? words.map(x => x[0].toUpperCase()).join('') : text
}

const TeamBadges = (props: IProps) => {
  const { maxWidth, initials = false } = props
  return (
    <div
      className={[styles.teamBadges, initials ? styles.wrap : undefined].join(
        ' '
      )}
      style={{ maxWidth }}>
      {props.teams.map(
        team =>
          team && (
            <div key={team.id} title={team.name} className={styles.teamBadge}>
              <OBadge key={team.id} variant="info">
                {initials ? toInitials(team.name) : team.name}
              </OBadge>
            </div>
          )
      )}
    </div>
  )
}

export default React.memo(TeamBadges)
