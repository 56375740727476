import React, { useCallback, useRef, useState } from 'react'
import { ORow, OCol, showSuccess, OToggleSwitch } from '@dnvgl-onefoundation/onedesign-react'

import QuotationWizzard from '../../quotations/QuotationWizzard'

import { useQuotationFormTemplate } from '../../quotations/hooks'
import TabContent from '../../layout/TabContent'
import { MeasurementUnitsEnum, QuotationTechnicalDetails } from '../../../interfaces'
import { HelperText, TabTitleIndicator } from '../../helpers'
import Button from '@dnvgl-onefoundation/onedesign-react/lib/cjs/Button/Button'
import { TechnicalDetailsValidationResult } from '../../../utils/validator'

interface Props {
  disabled?: boolean
  technicalDetails: QuotationTechnicalDetails | null
  validationResult: TechnicalDetailsValidationResult | null
  isEditAllowed: boolean
  id: string
  isInternalUser?: boolean
  helperText: string
  disableActionButtons: boolean
  isNew:boolean
  onCancel: () => void
  onChange: () => void
  onOK: (data: any) => void
}

const EditTechnicalScopeTab = (props: Props) => {
  const {
    disabled,
    technicalDetails,
    validationResult,
    isEditAllowed,
    isInternalUser,
    helperText,
    disableActionButtons,
    isNew,
    onOK,
    onCancel,
    onChange
  } = props

  const [metricUnits, setMetricUnits] = useState<boolean>(technicalDetails?.units == MeasurementUnitsEnum.Metric)

  const { form, setStepProperty } = useQuotationFormTemplate(
    technicalDetails?.form,
    technicalDetails?.data
  )

  const titleTooltip = (!validationResult?.isValid && !!validationResult?.invalidFields && validationResult.invalidFields.length > 0) ?
  (validationResult.invalidFields.length == 1 ? `Field ${validationResult.invalidFields[0]} is invalid` 
  : `Fields ${validationResult.invalidFields.join(', ')} are invalid`) 
  : null

  return (
    <TabContent
      title={
        <>
        <ORow>
          <OCol>
          <TabTitleIndicator
          title="Technical Scope"
          isValid={validationResult?.isValid}
          tooltip={titleTooltip} />
          </OCol>
        </ORow>
        </>
      }
      disabled={disabled}>
      <ORow>
        <OCol col="12">
          {!!form && (
            <>
              {!isInternalUser && !validationResult?.isValid && (
                <HelperText message={helperText} />
              )}
              <QuotationWizzard
                steps={form.steps}
                data={form.data}
                setStepProperty={setStepProperty}
                isEditAllowed={isEditAllowed}
                onOk={onOK}
                onCancel={onCancel}
                btnFinishText={isNew ? "Create & Save Technical" : "Save Technical"}
                onChange={onChange}
                disableActionButtons={disableActionButtons}
              />
            </>
          )}
        </OCol>
      </ORow>
    </TabContent>
  )
}

const ReviewTechnicalScopeTab = (props: {
  technicalDetails: QuotationTechnicalDetails | null
}) => {
  const { technicalDetails } = props
  const technicalDetailsRef = useRef<HTMLDivElement>(null)

  const copyToClipboard = useCallback(() => {
    if (technicalDetailsRef.current) {
      navigator.clipboard
        .write([
          new ClipboardItem({
            'text/html': new Blob([technicalDetailsRef.current.innerHTML], {
              type: 'text/html'
            })
          })
        ])
        .then(() => showSuccess('Technical Scope copied to clipboard.'))
    }
  }, [])

  return (
    <TabContent
      title="Technical Scope"
      actions={
        <Button
          variant="flat"
          iconClass="fal fa-copy"
          onClick={copyToClipboard}>
          Copy to clipboard
        </Button>
      }>
      {!!technicalDetails?.data && (
        <div ref={technicalDetailsRef}>
          {Object.entries(technicalDetails.data).map(([title, section]) => (
            <ORow key={title}>
              <OCol col="12">
                <h3>{title}</h3>
              </OCol>
              {Object.entries(section as any).map(([key, value]) => (
                <OCol key={key} md="6" sm="12" className="mb-3">
                  <strong>{key}</strong>
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    {Array.isArray(value)
                      ? value.map(x => <div key={x}>{x}</div>)
                      : `${value}`}
                  </div>
                </OCol>
              ))}
            </ORow>
          ))}
        </div>
      )}
    </TabContent>
  )
}

const QuotationPageTechnicalTab = (props: Props) =>
  props.isEditAllowed ? (
    <EditTechnicalScopeTab {...props} />
  ) : (
    <ReviewTechnicalScopeTab technicalDetails={props.technicalDetails} />
  )

export default React.memo(QuotationPageTechnicalTab)
