import React from 'react'
import { OWizard, OWizardStep } from '@dnvgl-onefoundation/onedesign-react'
import QuotationFormStep from './QuotationFormStep'
import styles from './QuotationWizzard.module.css'
import { QuotationFormStepTemplate } from '../../interfaces/FormTemplate'

interface Props {
  steps: QuotationFormStepTemplate[]
  data: any
  btnFinishText?: string
  btnCancelText?: string
  disableActionButtons?: boolean
  isEditAllowed?: boolean
  className?: string
  setStepProperty?: (stepTitle: string) => (name: string, value: string) => void
  onOk?: (submitData?: any) => void
  onCancel?: () => void
  onChange?: () => void
}

const QuotationWizzard = ({
  steps,
  data,
  btnFinishText = 'Save',
  btnCancelText = 'Reset',
  disableActionButtons,
  isEditAllowed,
  className,
  setStepProperty,
  onOk,
  onCancel,
  onChange
}: Props) => {
  const classes = [styles.quotationWizzard]
  if (className) classes.push(className)
  if (disableActionButtons) classes.push('disabled-action-buttons')

  return (
    <OWizard
      className={classes.join(' ')}
      fullPageWizard={false}
      btnFinishText={btnFinishText}
      btnCancelText={btnCancelText}
      onFinished={() => onOk?.(data)}
      onCancelled={onCancel}>
      {steps?.map((step, key) => (
        <OWizardStep title={step.title} key={key}>
          <QuotationFormStep
            step={step}
            data={data?.[step.title]}
            setProperty={setStepProperty?.(step.title)}
            isEditAllowed={isEditAllowed}
            onChange={onChange}
          />
        </OWizardStep>
      ))}
    </OWizard>
  )
}

export default React.memo(QuotationWizzard)
