export interface FormField {
    name: string
  }
  
  export interface StringField extends FormField {
    type: 'string'
  }
  
  export interface TextareaField extends FormField {
    type: 'textarea'
  }
  
  export interface NumberField extends FormField {
    type: 'number'
  }
  
  export interface OptionsField extends FormField {
    options: string[]
    type: 'select' | 'checkbox'
  }
  
  export function isOptionsField(field: FieldTemplate) : field is OptionsField {
    return (field as OptionsField)?.options !== undefined
  }

  export function isOptionsFieldType(field: FieldTemplate) : field is OptionsField {
    return (field as OptionsField)?.type === "checkbox" || (field as OptionsField)?.type === "select"
  }
  
  export interface SelectField extends OptionsField {
    type: 'select'
  }
  
  export interface CheckBoxField extends OptionsField {
    type: 'checkbox'
  }
  
  export type FieldTemplate =
    | StringField
    | TextareaField
    | NumberField
    | SelectField
    | CheckBoxField
    | OptionsField
  
  export type FormInputType =
    | 'string'
    | 'textarea'
    | 'number'
    | 'select'
    | 'checkbox'

  export enum FormGroupType {
    Condition = 'condition',
    Multiplication = 'multiplication'
  }

  export interface FieldsGroupTemplate {
    name: string
    displayName?: string
    condition?: FieldsGroupCondition
    fields?: FieldTemplate[]
  }

  export interface FieldsGroupCondition {
    type: FormGroupType
    masterFieldName: string 
    options: Record<string, number>
  }
  
  export interface FieldsCondition {
    conditionFieldName?: string 
    conditionalGroupName: string 
  }
    
  export interface QuotationFormStepTemplate {
    title: string
    intro?: string[]
    fields?: FieldTemplate[]
    groups?: FieldsGroupTemplate[]
  }
  
  export interface QuotationForm {
    steps: QuotationFormStepTemplate[]
    data: any
  }
