import { FieldType, OCol, OField, ORow } from '@dnvgl-onefoundation/onedesign-react'
import React, { useMemo } from 'react'
import { FieldTemplate, isOptionsFieldType, QuotationFormStepTemplate } from '../../../interfaces/FormTemplate'
import { config } from '../../../utils'
import OptionFieldEditor from './OptionFieldEditor'

interface Props {
    showStep?: boolean
    steps?: QuotationFormStepTemplate[]
    stepIndex?: number

    fields: FieldTemplate[]
    field: FieldTemplate

    onStepIndexChanged: (index: number) => void
    onUpdated: (field: FieldTemplate) => void
}

const { quotationPage } = config

const FieldDetails = (props: Props) => {
    const { showStep, steps, stepIndex, fields, field, onStepIndexChanged, onUpdated } = props

    const { supportedInputTypes } = quotationPage

    function UpdateName(newName: any): void {

        onUpdated({...field, name:newName})
    }

    const options = useMemo(
        () =>
            steps?.map((u: QuotationFormStepTemplate, key: number) => {
            return {
                value: key,
                text: u.title
            }
            }),
        [steps]
    )

    const addOption = (name: string) => {
        if (!name?.length || !isOptionsFieldType(field)) return

        onUpdated({...field, options: [...field.options, name]})
    }
    
    const deleteOption = (name: string) => {
        if (!name?.length || !isOptionsFieldType(field)) return

        onUpdated({...field, options: field.options.filter(x => x !== name)})
    }

    const isNameValid = !!field?.name && !fields.some(x => x.name === field.name)

    return (
        <ORow className="text-left">
        <OCol col="12">
          {showStep && (
            <OField
            onChange={e => onStepIndexChanged(Number.parseInt(`${e.value}`))}
            value={{stepIndex:stepIndex}}
            field={{
              heading: 'Step',
              name: 'stepIndex',
              type: FieldType.Select,
              optionalTag: false,
              options: options
            }}
          />
          )}
          <OField
            value={field}
            onChange={e => UpdateName(e.value) }
            validated={!isNameValid}
            field={{
              name: 'name',
              heading: 'Name',
              invalidFeedback:'Name must be not empty and unique',
              type: FieldType.Input,
              validate:() => {return isNameValid;}
            }}
          />
          <OField
            onChange={e => onUpdated({...field, type: e.value as any})}
            value={field}
            field={{
              heading: 'Type',
              name: 'type',
              type: FieldType.Select,
              optionalTag: false,
              options: supportedInputTypes?.map((t: string) => {
                return {
                  value: t,
                  text: t
                }
              })
            }}
          />
          {isOptionsFieldType(field) && (
            <OptionFieldEditor
              options={field.options}
              addOption={addOption}
              deleteOption={deleteOption}
            />
          )}
        </OCol>
      </ORow>
    )
}

export default React.memo(FieldDetails)