import { useCallback, useEffect, useState } from 'react'
import {
  FieldType,
  OButton,
  OTable,
  OTooltip,
  showSuccess,
  TableItem
} from '@dnvgl-onefoundation/onedesign-react'
import { useNavigate } from 'react-router-dom'
import Page from '../../layout/Page'
import { ContentSection } from '../../layout'
import { api, helper, config, momentHelper } from '../../../utils'
import { TransmittalFailure } from '../../../interfaces'
import ToggleBox from '../../helpers/ToggleBox'
import { useAppDispatch } from '../../../store/hooks'
import { getRelevantTransmitialFailuresCount } from '../../../store/slices/failures'
import InputWithIcon from '../../helpers/InputWithIcon'

const { paths } = config

const fields = [
  {
    name: 'irrelevant',
    heading: 'Irrelevant',
    sortable: false,
  },
  {
    name: 'projectName',
    heading: 'Project',
    sortable: true,
    type: FieldType.Input
  },
  {
    name: 'uploaderEmail',
    heading: 'Uploader',
    sortable: true,
    type: FieldType.Input
  },
  {
    name: 'failedAt',
    heading: 'Failed at',
    type: FieldType.Input
  },
  {
    name: 'message',
    heading: 'Message',
    type: FieldType.Input
  }
]

const TransmittalFailuresPage = () => {
  const navigate = useNavigate()
  const [failures, setFailures] = useState<TransmittalFailure[]>()

  const loadTransmittalFailures = useCallback(() => {
    let isCanceled = false
    api.transmittalFailures
      .readAll()
      .then(x => {
        if (!isCanceled) {
          setFailures(x)
        }
      })
      .catch(x => {
        if (!isCanceled) helper.handleErrorMessage(x)
      })
    return () => {
      isCanceled = true
    }

  }, []);

  useEffect(() => {
    loadTransmittalFailures()
  }, [loadTransmittalFailures])

  const dispatch = useAppDispatch()
  const onRelevanceChanged = (id:string, val: boolean) =>
  {
    const setVal = val ? api.transmittalFailures.setIrrelevant(id) : api.transmittalFailures.setRelevant(id);

    setVal.then(() => {
      showSuccess(`Failure marked as ${val ? "irrelevant" : "relevant"}`)
    })
    .catch(helper.handleErrorMessage)
    .finally(() => {
      loadTransmittalFailures();
      dispatch(getRelevantTransmitialFailuresCount())
    });
  }

  const normalize = (failures: TransmittalFailure[]) =>
  failures
    .filter(i => isItemVisible(i))
    .map(x => ({
      irrelevant: <ToggleBox 
        id={x.failureId}
        isChecked={x.isIrrelevant} 
        checkedIcon="fas fa-check-circle text-success"
        uncheckedIcon="fas fa-exclamation-circle text-danger"
        onToggle = {onRelevanceChanged} />,
      failureId: x.failureId,
      projectId: x.projectId,
      projectName: x.projectName,
      uploaderEmail: x.uploader?.email,
      failedAt: momentHelper.toHumandFriendlyDateTime(x.failedAt),
      message: x.message
  }))

  function isItemVisible(i: TransmittalFailure): boolean {
    if (!filter) return true;

    return i.projectName.toLowerCase().includes(filter)
      || i.message.toLowerCase().includes(filter)
      || momentHelper.toHumandFriendlyDateTime(i.failedAt).toLowerCase().includes(filter)
      || (!!i.uploader?.email && i.uploader.email.toLowerCase().includes(filter))
      ;
  }
  const [filter, setFilter] = useState<string>("");
  const onFilterChange = (value: string) => {
    setFilter(value.toLowerCase());
  }
  const onClearFilter = () => {
    setFilter("");
  }

  const openOrder = (item?: TableItem) => {
    if (item?.projectId) navigate(`${paths.orders}/${item.projectId}`)
  }

  return (
    <Page title="Transmittal Failures" isLoading={!failures}>
      <ContentSection>
        {!!failures && (
          <>
          <span className='d-inline-flex float-right justify-content-end '>
            <InputWithIcon
              icon='fas fa-filter'
              placeholder="Filter ..."
              value={filter}
              style={{ maxWidth: '220px' }}
              onChange={onFilterChange} 
              />
              <OTooltip
                className=''
                content="Clear filter"
                placement='bottom'
                >
                <OButton 
                  disabled={!filter}
                  iconClass='fas fa-filter text-danger' 
                  variant='flat' 
                  onClick={onClearFilter} />
              </OTooltip>
            </span>
            <OTable
              className="selectable-table"
              allItems={normalize(failures)}
              rowsPerPage={20}
              fields={fields}
              small
              showColumnsButton={false}
              showOptionsButton={false}
              showFilterButton={false}
              onFocusRowChange={openOrder} 
            />
          </>
        )}
      </ContentSection>
    </Page>
  )
}

export default TransmittalFailuresPage
