import React from 'react'
import { QuotationFormStepTemplate } from '../../interfaces/FormTemplate'
import QuotationFormGroup from './QuotationFormGroup'

interface Props {
  step: QuotationFormStepTemplate
  data: any
  isEditAllowed?: boolean
  onValueChanged?: (fieldName: string, value: string, checked?: boolean, groupName?: string) => void
}

function QuotationFormGroups(props: Props) {
  const { step, data, isEditAllowed, onValueChanged } = props

  return (
    <>
    {step?.groups?.length ? (
      step?.groups.map((group) => (
        <QuotationFormGroup
          key={group.name}
          group={group}
          data={data}
          isEditAllowed={isEditAllowed}
          onValueChanged={onValueChanged}
        />
        ))
    ) : (
      <p>&nbsp;</p>
    )}
    </>
  )
}

export default React.memo(QuotationFormGroups)
