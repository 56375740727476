import React, { ReactNode } from 'react'
import { ORow, OCol } from '@dnvgl-onefoundation/onedesign-react'

interface Props {
  title?: ReactNode
  disabled?: boolean
  actions?: ReactNode
  children?: ReactNode
}

const TabContent = (props: Props) => {
  const { title, disabled, actions, children } = props
  return (
    <ORow className={disabled ? 'is-disabled' : ''}>
      <OCol col={actions ? '8' : '12'} align="center">
        <h2>{title}</h2>
      </OCol>
      {!!actions && (
        <OCol col="4" className="text-right" align="center">
          {actions}
        </OCol>
      )}
      <OCol>{children}</OCol>
    </ORow>
  )
}

export default TabContent
