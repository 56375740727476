import React, { useCallback, useEffect, useState } from 'react'
import Page from '../layout/Page'
import { ContentSection } from '../layout'

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { useMatch, Link, useParams } from 'react-router-dom'
import {
  OTabs,
  OTab,
  OButton,
  showPrompt,
  showSuccess,
  showConfirm,
  OAlert,
  showError,
  OModal,
} from '@dnvgl-onefoundation/onedesign-react'
import { BackButton, TabTitleIndicator, QuotationStatusBadge, QuotationDiscussions } from '../helpers'
import { helper, config, api } from '../../utils'
import { useQuotationStatus } from '../../hooks'

import { checkInternalUsers, checkGroups } from '../../store/slices/userGroups'
import {
  readQuotationDetails,
  actions as quotationActions,
  readTechnicalDetails,
  readAgreements,
  createNewTechnicalDetails
} from '../../store/slices/quotations'

import { deleteConfirm, resetDataConfirm } from '../modals'
import { useNavigate } from 'react-router-dom'

import {
  QuotationPageTechnicalTab,
  QuotationPageCustomerInfoTab,
  QuotationPageAgreementTab,
  QuotationPagePurchaseOrderTab,
  QuotationPageDocumentsTab
} from './QuotationTabs'

import { CreateQuotation, GroupTypeEnum, MeasurementUnitsEnum, QuotationStatusEnum, QuotationTab, value } from '../../interfaces'
import { showTextAreaPrompt } from '../helpers/ShowTextAreaPrompt'
import SelectService from '../quotations/SelectService'

const { paths, quotationPage } = config
const { isAuthenticated, handleErrorMessage, addPluralS } = helper

const QuotationPage = () => {

  const match = useMatch(`${paths.quotations}/:id/:tabUrl`)
  const id: string = (match?.params as any)?.id
  const isNew:boolean = id === quotationPage.newQuotationId
  const tabUrl: string = (match?.params as any)?.tabUrl

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [isTechnicalDataUpdated, setIsTechnicalDataUpdated] = useState(false)
  const [tabId, setTabId] = useState<QuotationTab | undefined>(undefined)
  const [newQuotationName, setNewQuotationName] = useState<string|undefined>(undefined)

  useEffect(
    () => () => {
      // HINT: Clear Quotation state so it does not interfere with another quotation.
      // TODO: Opened quotation state should not be global to avoid just global state antipattern.
      // See `actions as quotationActions` import. This component is managing the state already
      // and no other component subsribes to that state but there is Redux boilerplate
      // with all the consequnces like stale state!
      dispatch(quotationActions.clearQuotation())
    },
    [dispatch]
  )

  useEffect(() => {
    for (const [id, url] of Object.entries(quotationPage.tabUrls)) {
      if (url === tabUrl) {
        setTabId(id as unknown as QuotationTab)
        return
      }
    }
  }, [tabUrl])

  const {
    disabledQuotationTabs,
    quotationDetails,
    isQuotationDetailsValid,
    loadingQuotationDetails,
    technicalDetails,
    technicalDetailsValidationResult,
    listAgreements,
    agreementsAccepted,
    purchaseOrder,
    serviceId
  } = useAppSelector(s => s.quotations)

  const { isDraft, isSent, isAwaitingOrder, isRejected, isAccepted } =
    useQuotationStatus(quotationDetails)

  const { currentUser, isInternalUser } = useAppSelector(s => s.users)

  const redirectToTab = (tabId?: QuotationTab) => {
    if (tabId !== undefined)
      navigate([paths.quotations, id, quotationPage.tabUrls[tabId]].join('/'))
  }

  const refreshQuotationDetails = useCallback(
    () => dispatch(readQuotationDetails(id)),
    [dispatch, id]
  )

  useEffect(() => {
    if (isNew) {
      if (serviceId) {
        dispatch(createNewTechnicalDetails(serviceId))
      }
    }
    else if (isAuthenticated(currentUser)) {
      dispatch(readQuotationDetails(id))
      dispatch(readTechnicalDetails(id))
      dispatch(readAgreements(id))
    }
    dispatch(isInternalUser ? checkInternalUsers() : checkGroups())
  }, [currentUser, dispatch, id, isInternalUser])

  useEffect(() => setIsTechnicalDataUpdated(false), [technicalDetails])
  useEffect(() => {
    if (
      technicalDetailsValidationResult?.isValid &&
      tabUrl === config.quotationPage.tabUrls[QuotationTab.Technical] &&
      quotationDetails?.status.id === QuotationStatusEnum.Draft
    )
      autoRedirectToDetailsTab()
  }, [technicalDetailsValidationResult]) // eslint-disable-line react-hooks/exhaustive-deps

  const navigateToOverview = () => navigate(paths.quotations)

  const onDelete = () =>
    deleteConfirm(`${quotationDetails?.name}`).then((confirmed: boolean) => {
      confirmed &&
        api.quotations
          .delete(id)
          .then(() => {
            showSuccess('Successfully deleted.', quotationDetails?.name)
            navigateToOverview()
          })
          .catch(handleErrorMessage)
    })

  const onUpdateQuotationDetails = (
    propertyName: string,
    propertyValue: value
  ) => {
    dispatch(quotationActions.setLoadingQuotationDetails(true))
    api.quotations
      .update(id, {
        propertyName,
        propertyValue
      })
      .then(data => {
        dispatch(quotationActions.setQuotationDetails(data))
        dispatch(quotationActions.setIsQuotationDetailsValid(data))
        showSuccess('Successfully updated!', data.name)
      })
      .catch(handleErrorMessage)
      .finally(() =>
        dispatch(quotationActions.setLoadingQuotationDetails(false))
      )
  }

  const autoRedirectToDetailsTab = () =>
    navigate(
      `${paths.quotations}/${id}/${
        config.quotationPage.tabUrls[QuotationTab.CustomerInfo]
      }`
    )

  const promptToUpdateQuotationDetails = (
    title: string,
    value: string,
    propertyName: string
  ) =>
    showPrompt(title, value).then(
      (newValue: value) =>
        !!newValue && onUpdateQuotationDetails(propertyName, newValue)
    )

  const promptToUpdateAgreement = (title: string, value: string) =>
    showTextAreaPrompt(title, value).then(
      (newValue: value) =>
        newValue !== false && onUpdateQuotationDetails('agreement', newValue)
    )

  const promptToCopy = () => {
    if (!quotationDetails) return
    showPrompt('New quotation name', quotationDetails.name).then(newName => {
      if (!newName) {
        if (newName !== false)
          showError('Add copy', 'Please provide new quotation name.')
        return
      }
      dispatch(quotationActions.setLoadingQuotationDetails(true))
      api.quotations
        .create({
          serviceId: quotationDetails.serviceId,
          name: newName,
          units: quotationDetails.units,
          quotationFormData: technicalDetails?.data,
          groupId: quotationDetails.groupId,
          companyName: quotationDetails.companyName,
          vat: quotationDetails.vat,
          companyAddress: quotationDetails.companyAddress,
          billingAddress: quotationDetails.billingAddress
        })
        .then(id => navigate(`${paths.quotations}/${id}`))
        .catch(handleErrorMessage)
    })
  }

  const confirmResetTechnicalData = () =>
    resetDataConfirm('the Technical tab').then(
      confirmed => {
        if (confirmed) {
          if (isNew) {
            serviceId && dispatch(createNewTechnicalDetails(serviceId))
          }
          else {
            dispatch(readTechnicalDetails(id))
          }
        }
      }
    )

  const createQuotation = (newData: any) =>
  {
    if (serviceId && newQuotationName) {
      const userCompanyId = currentUser?.groups?.find(
        group => group.type === GroupTypeEnum.Company
      )?.id
  
      if (userCompanyId === undefined) {
        showError(
          'Unknown company',
          'Please contact Veracity support to assign you to company.'
        )
        return
      }
      const payload: CreateQuotation = {
        serviceId: serviceId,
        name: newQuotationName,
        units: MeasurementUnitsEnum.Metric,
        customerContactId: currentUser?.id,
        groupId: userCompanyId,
        quotationFormData: newData
      }
      api.quotations.create(payload)
        .then(id => {
          showSuccess(
            'Quotation request successfully created',
            `${newQuotationName}`
          )
          navigate(`${paths.quotations}/${id}`)
      })
      .catch(handleErrorMessage)
    }
  }

  const updateTechnicalData = (newData: any) =>
  {
    if (isNew ) {
      createQuotation(newData)
    }
    else {
      api.quotations
      .updateTechnicalData(id, newData)
      .then(() => {
        showSuccess(
          'Technical details successfully updated.',
          quotationDetails?.name
        )
        setIsTechnicalDataUpdated(false)
        dispatch(readTechnicalDetails(id))
      })
      .catch(handleErrorMessage)
    }
  }

  const onSendDraftClick = () =>
    showConfirm(
      `${quotationDetails?.name}`,
      quotationPage.sendTextMessage
    ).then(confirmed => {
      if (confirmed)
        api.quotations
          .setStatus(id, QuotationStatusEnum.Sent)
          .then(() => {
            showSuccess(
              'Quotation request successfully sent',
              `${quotationDetails?.name}`
            )
            navigateToOverview()
          })
          .catch(handleErrorMessage)
    })

  const onCancelClick = () => {
    navigate(paths.quotations)
  }

  const onOrderClick = () =>
    showConfirm(
      `${quotationDetails?.name}`,
      'Please confirm the order. You could also optionaly upload a Purchase Order file at the corresponding tab.'
    ).then(confirmed => {
      if (confirmed)
        api.quotations
          .setStatus(id, QuotationStatusEnum.Accepted)
          .then(() => {
            navigate([paths.orders, id].join('/'))
            showSuccess(`Order "${quotationDetails?.name}" created.`)
          })
          .catch(handleErrorMessage)
    })

  const onApproveOrderClick = () =>
    showConfirm(
      `${quotationDetails?.name}`,
      'Please confirm all documents are correct and there should be set the "Ordered" status.'
    ).then(confirmed => {
      if (confirmed)
        api.quotations
          .setStatus(id, QuotationStatusEnum.Ordered)
          .then(() => {
            showSuccess(
              'Successfully set the "Ordered" status.',
              quotationDetails?.name
            )
            navigateToOverview()
          })
          .catch(handleErrorMessage)
    })

  const onSendAgreementsClick = () => {
    const names = [
      ...(quotationDetails?.agreement?.trim()
        ? [quotationDetails?.agreement]
        : []),
      ...(listAgreements?.map(i => `"${i.name}"`) ?? [])
    ]

    if (!names.length) return

    const prompt = `Please confirm, if you want to send agreement${addPluralS(
      names?.length
    )}: ${names.join(', ')} to ${
      quotationDetails?.companyName
    }. You won't be able to add, edit nor delete SFAs unless Customer rejects them.`

    showConfirm(`${quotationDetails?.name}`, prompt).then(confirmed => {
      if (confirmed)
        api.quotations
          .setStatus(id, QuotationStatusEnum.AwaitingSfaSign)
          .then(() => {
            showSuccess('SFA request successfully sent', names.join(', '))
            navigateToOverview()
          })
          .catch(handleErrorMessage)
    })
  }

  const [selectedServiceId, setSelectedServiceId] = useState<string | undefined>()
  const handleSelectServiceOk = () => {
    const userCompanyId = currentUser?.groups?.find(
      group => group.type === GroupTypeEnum.Company
    )?.id

    if (userCompanyId === undefined) {
      showError(
        'Unknown company',
        'Please contact Veracity support to assign you to company.'
      )
      return
    }

    if (selectedServiceId) {
      dispatch(quotationActions.setServiceId(selectedServiceId));
      dispatch(createNewTechnicalDetails(selectedServiceId))
    }
  }

  const handleSelectServiceCancel = useCallback(() => {
    navigate(paths.quotations)
  }, [])

  const selectServiceOkDisabled = () => !selectedServiceId
  const onSelectServiceSelected = (id?: string) => setSelectedServiceId(id)

  return (
    <Page
      title={
        <>
          <BackButton tooltip="Back to the list" linkTo={paths.quotations} />
          {isNew ? (
            <input
              type="text"
              placeholder="Title quotation"
              onChange={e => setNewQuotationName(`${e.target.value}`)}
              value={newQuotationName}
            />
          ) : (
            <> 
            &nbsp;{quotationDetails?.name}&nbsp;
            <QuotationStatusBadge status={quotationDetails?.status} isInternalUser={isInternalUser} />
            </>
          )}
        </>
      }
      actions={
        <div className={loadingQuotationDetails ? 'd-none' : 'd-inline-block'}>
          {isDraft && (
            <OButton
              onClick={onDelete}
              className="text-danger"
              iconClass="fal fa-trash-alt text-danger"
              variant="flat">
              Delete
            </OButton>
          )}
          {isDraft && (
            <OButton
              variant="flat"
              className="mr-1"
              iconClass="fal fa-edit"
              onClick={() =>
                promptToUpdateQuotationDetails(
                  'Rename Quatataion Request',
                  quotationDetails?.name as string,
                  'name'
                )
              }>
              Rename
            </OButton>
          )}
          {!isInternalUser && !isDraft && !isNew && (
            <OButton
              variant="flat"
              className="mr-1"
              iconClass="fal fa-copy"
              onClick={promptToCopy}>
              Add copy
            </OButton>
          )}
          {isDraft && (
            <OButton
              disabled={!isQuotationDetailsValid || !technicalDetailsValidationResult?.isValid}
              variant="cta"
              iconClass="fal fa-cloud-upload"
              onClick={onSendDraftClick}>
              Save and Send
            </OButton>
          )}
          {isNew && (
            <OButton
              className="text-danger"
              iconClass="fal fa-trash-alt text-danger"
              variant="flat"
              onClick={onCancelClick}>
              Cancel
            </OButton>
          )}
          {(isSent || isRejected) && isInternalUser && (
            <OButton
              disabled={
                !(
                  quotationDetails?.agreement?.trim() ||
                  listAgreements.some(x => !x.rejection)
                )
              }
              variant="cta"
              iconClass="fal fa-cloud-upload"
              onClick={onSendAgreementsClick}>
              Send Agreement&nbsp;to&nbsp;
              {quotationDetails?.companyName}
            </OButton>
          )}
          {isAwaitingOrder && !isInternalUser && (
            <OButton
              variant="cta"
              iconClass="fal fa-cloud-upload"
              disabled={
                !(agreementsAccepted || quotationDetails?.agreement?.trim())
              }
              onClick={onOrderClick}>
              Send Order
            </OButton>
          )}

          {isInternalUser && isAccepted && (
            <OButton
              variant="cta"
              iconClass="fal fa-cloud-upload"
              onClick={onApproveOrderClick}>
              Approve Order
            </OButton>
          )}

          {quotationDetails?.orderId && (
            <Link to={`/orders/${quotationDetails.orderId}`}>
              <OButton variant="flat" iconClass="fa-light fa-link">
                Open Order
              </OButton>
            </Link>
          )}
          {!!quotationDetails && !!currentUser && (
              <>
                &nbsp;
                <QuotationDiscussions
                  quotation={quotationDetails}
                  currentUser={currentUser}
                  isInternalUser={isInternalUser}
                  isReadOnly={false}
                />
              </>
            )}
        </div>
      }
      isLoading={loadingQuotationDetails}>
      {loadingQuotationDetails ? (
        <div className="mt-4 text-center">Loading...</div>
      ) : (
        <>
          <OModal
            visible={isNew && !serviceId}
            hideCloseButton
            okText="Select"
            clickOutside={false}
            titleText="Please select service type"
            bodySlot={<SelectService onServiceSelected={onSelectServiceSelected} />}
            onOk={handleSelectServiceOk}
            onCancel={handleSelectServiceCancel}
            okDisabled={selectServiceOkDisabled()}
          />
          {isRejected && quotationDetails?.rejection && (
            <OAlert
              variant="danger"
              message="Rejection reason:"
              description={
                <span>
                  {quotationDetails.rejection.reason}&nbsp;
                  {tabUrl !== quotationPage.tabUrls[QuotationTab.Agreement] && (
                    <span className="ml-5">
                      Please open the "DNV Agreement" tab for more details
                    </span>
                  )}
                </span>
              }
              dismissable={false}
            />
          )}
          <ContentSection>
            <OTabs selectedTab={tabId} onTabSelect={redirectToTab}>
              <OTab
                title="Technical Scope"
                disabled={ !isNew && disabledQuotationTabs.includes(
                  QuotationTab.Technical
                )}>
                <QuotationPageTechnicalTab
                  disabled={!isNew && disabledQuotationTabs.includes(
                    QuotationTab.Technical
                  )}
                  technicalDetails={technicalDetails}
                  validationResult={technicalDetailsValidationResult}
                  isEditAllowed={
                    isNew || isDraft
                  }
                  id={id}
                  isInternalUser={isInternalUser}
                  helperText={quotationPage.helperTexts[QuotationTab.Technical]}
                  disableActionButtons={(isNew && !newQuotationName) || (!isNew && !isTechnicalDataUpdated)}
                  isNew={isNew}
                  onCancel={confirmResetTechnicalData}
                  onChange={() => setIsTechnicalDataUpdated(true)}
                  onOK={updateTechnicalData}
                />
              </OTab>

              <OTab
                title="Customer Details"
                disabled={isNew || isTechnicalDataUpdated ||
                  disabledQuotationTabs.includes(QuotationTab.CustomerInfo)
                }>
                <QuotationPageCustomerInfoTab
                  isEditable={ isDraft }
                  title={
                    <TabTitleIndicator
                      title="Customer Details"
                      isValid={isQuotationDetailsValid}
                    />
                  }
                  disabled={
                    isNew || isTechnicalDataUpdated ||
                    disabledQuotationTabs.includes(QuotationTab.CustomerInfo)
                  }
                  promptToUpdate={promptToUpdateQuotationDetails}
                  onUpdate={onUpdateQuotationDetails}
                  isInternalUser={isInternalUser}
                  helperText={
                    quotationPage.helperTexts[QuotationTab.CustomerInfo]
                  }
                />
              </OTab>

              <OTab 
                title="Documents"
                disabled={isNew || disabledQuotationTabs.includes(
                  QuotationTab.Documents
                )}>

                <QuotationPageDocumentsTab
                  quotationId={id}
                  isInternalUser={isInternalUser}
                  isUploadAllowed={isDraft}
                  isDeleteAllowed={isDraft}
                />
              </OTab>

              <OTab
                title="DNV Agreement"
                disabled={isNew || disabledQuotationTabs.includes(
                  QuotationTab.Agreement
                )}>
                <QuotationPageAgreementTab
                  disabled={isNew || disabledQuotationTabs.includes(
                    QuotationTab.Agreement
                  )}
                  agreementUploadAllowed={isSent || isRejected}
                  isInternalUser={isInternalUser}
                  companyName={quotationDetails?.companyName}
                  listAgreements={listAgreements}
                  helperText={quotationPage.helperTexts[QuotationTab.Agreement]}
                  agreementsAccepted={agreementsAccepted}
                  promptToUpdate={promptToUpdateAgreement}
                  refreshQuotationDetails={refreshQuotationDetails}
                />
              </OTab>

              <OTab
                title="Purchase Order"
                disabled={isNew || disabledQuotationTabs.includes(
                  QuotationTab.PurchaseOrder
                )}>
                <QuotationPagePurchaseOrderTab
                  id={id}
                  disabled={isNew || disabledQuotationTabs.includes(
                    QuotationTab.PurchaseOrder
                  )}
                  purchaseOrder={purchaseOrder}
                  purchaseOrderUpdateAllowed={
                    isInternalUser
                      ? isSent || isRejected || isAccepted
                      : isAwaitingOrder
                  }
                  isInternalUser={isInternalUser}
                  helperText={
                    quotationPage.helperTexts[QuotationTab.PurchaseOrder]
                  }
                />
              </OTab>
            </OTabs>
          </ContentSection>
        </>
      )}
    </Page>
  )
}

export default React.memo(QuotationPage)
