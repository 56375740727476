import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  OButton,
  OModal,
  OAlert,
  OField,
  FieldType,
  showSuccess,
  ORow,
  OCol
} from '@dnvgl-onefoundation/onedesign-react'
import { SpinIndicator } from '../../layout'
import { helper, api } from '../../../utils'
import { useAppDispatch } from '../../../store/hooks'
import { getServices } from '../../../store/slices/quotations'
import { CreateService } from '../../../interfaces'

const initialState = { name: '' }

interface Props {
  operationName: string
}

const AddQuotationForm = (props: Props) => {
  const { operationName } = props
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [payload, setPayload] = useState<CreateService>(initialState)
  const dispatch = useAppDispatch()

  const showModal = () => {
    setPayload(initialState)
    setIsSubmitting(false)
    setIsVisible(true)
  }
  const hideModal = () => setIsVisible(false)
  const putQuotationForm = () => {
    if (isSubmitting) return undefined
    setIsSubmitting(true)
    api.services
      .create(payload)
      .then(serviceId => {
        dispatch(getServices())
        showSuccess('Quotation Form created.')
        navigate(`/admin/quotations/${serviceId}`)
        //HINT: To update existing OLink-s state.
        window.dispatchEvent(new PopStateEvent('popstate'))
      })
      .catch(helper.handleErrorMessage)
      .finally(() => {
        setIsSubmitting(false)
        hideModal()
      })
  }

  const bodySlot = (
    <ORow className="text-left">
      <OCol col="12">
        <OField
          value={payload}
          onChange={e => setPayload({ name: e.value as string })}
          field={{
            name: 'name',
            heading: 'Name',
            type: FieldType.Input
          }}
        />
        {!payload.name?.length && (
          <OAlert
            variant="danger"
            description="Name is required"
            dismissable={false}
          />
        )}
      </OCol>
    </ORow>
  )

  return (
    <>
      <OButton iconClass="fal fa-clipboard-list" onClick={showModal}>
        {operationName}
      </OButton>
      <OModal
        visible={isVisible}
        size="sm"
        hideCloseButton
        okText="Create"
        okDisabled={!payload.name?.length}
        clickOutside={false}
        titleText={operationName}
        bodySlot={
          isSubmitting ? (
            <div className="text-center mt-5">
              <SpinIndicator />
            </div>
          ) : (
            bodySlot
          )
        }
        onOk={putQuotationForm}
        onCancel={hideModal}
      />
    </>
  )
}

export default React.memo(AddQuotationForm)
