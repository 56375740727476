import React, { useEffect, useState, useMemo, useCallback } from 'react'
import {
  OToggleSwitch,
  ORow,
  OCol,
  showError,
  showSuccess
} from '@dnvgl-onefoundation/onedesign-react'
import { useNavigate } from 'react-router-dom'
import Page from '../layout/Page'
import { ContentSection } from '../layout'
import {
  QuotationItemsList, StatusIndicator, SelectableQuotationsList
} from '../helpers'
import { AddQuotation } from '../quotations'
import { api, helper, config } from '../../utils'

import { readAllQuotationItems } from '../../store/slices/quotations'
import { checkGroups } from '../../store/slices/userGroups'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

import {
  QuotationItem,
  QuotationStatusEnum,
  QuotationTab
} from '../../interfaces'

interface Props {
  title: string
}

const QuotationsOverviewPage = (props: Props) => {
  const { title } = props
  const { paths, quotationPage } = config
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { loadingQuotationItems, quotationItems } = useAppSelector(
    state => state.quotations
  )
  const { currentUser, isInternalUser } = useAppSelector(state => state.users)
  const quotationsEnabled = useAppSelector(
    state => state.configuration.featureToggles.quotations
  )
  const [ownQuotationsOnly, setOwnQuotationsOnly] = useState(true)
  const [ongoingQuotationsOnly, setOngoingQuotationsOnly] = useState(true)

  useEffect(() => {
    dispatch(checkGroups())
  }, [dispatch])

  const readQuotations = useCallback(() => {
    dispatch(readAllQuotationItems(ownQuotationsOnly, ongoingQuotationsOnly))
  }, [dispatch, ownQuotationsOnly, ongoingQuotationsOnly])

  useEffect(() => {
    readQuotations()
  }, [readQuotations])

  const quotations = useMemo(() => {
    return {
      draft: quotationItems.filter(
        i => i.status.id === QuotationStatusEnum.Draft
      ),
      ongoing: quotationItems.filter(
        i => i.status.id !== QuotationStatusEnum.Draft && i.status.id !== QuotationStatusEnum.Ordered
      ),
      completed: quotationItems.filter(
        i => i.status.id === QuotationStatusEnum.Ordered
      )
    }
  }, [quotationItems])

  const openQuotationDetails = (item?: QuotationItem) =>
    navigate(
      `${paths.quotations}/${item?.id}/${
        item?.status?.id === QuotationStatusEnum.AwaitingSfaSign
          ? quotationPage.tabUrls[QuotationTab.Agreement]
          : item?.status?.id === QuotationStatusEnum.AwaitingOrder ? quotationPage.tabUrls[QuotationTab.PurchaseOrder] 
          : quotationPage.defaultTabUrl
      }`
    )

  const deleteQuotations = (ids: string[]) =>
    api.quotations.batchDelete(ids)
      .then(result => {
        if (result.errors.length == 0) {
          showSuccess('Deleted', ids.length +' quotations successfully deleted.')
        } else showError('Delete', 'Failed to delete ' + result.errors.length + ' the quotation(s).')
        readQuotations()
      })
      .catch(helper.handleErrorMessage)

  const assignPM = (quotationIds: string[], userId: string) =>
      api.quotations.batchUpdate({itemIds: quotationIds, propertyName: 'handlerId', propertyValue: userId})
      .then(result => {
        if (result.errors.length == 0) {
          showSuccess('Updated', quotationIds.length +' quotations updated successfully.')
        } else showError('Update', 'Failed to update ' + result.errors.length + ' the quotation(s).')
        readQuotations()
      })
      .catch(helper.handleErrorMessage)

  return (
    <Page
      title={title}
      isLoading={loadingQuotationItems}
      disabled={loadingQuotationItems}
      actions={
          !!quotationsEnabled &&
          !!currentUser && <AddQuotation currentUser={currentUser} />
      }>
      {!!quotationsEnabled && (
        <ContentSection>
          <ORow>
            <OCol md="5">
              <h2 className="m-0">Quotation&nbsp;Requests</h2>
            </OCol>
            <OCol md="7" className="text-right pt-1">
              <OToggleSwitch
                small
                textLocation="hidden"
                className="d-inline-block pointer"
                checked={ongoingQuotationsOnly}
                onChange={() =>
                  setOngoingQuotationsOnly(!ongoingQuotationsOnly)
                }>
                Ongoing quotations only
              </OToggleSwitch>
              <OToggleSwitch
                small
                textLocation="hidden"
                className="d-inline-block pointer ml-5"
                checked={ownQuotationsOnly}
                onChange={() => setOwnQuotationsOnly(!ownQuotationsOnly)}>
                My quotations only
              </OToggleSwitch>
            </OCol>
          </ORow>
          {isInternalUser && (
            <>
            {!ongoingQuotationsOnly && (
              <div className="mt-4">
                <h3 className="m-0">
                  <StatusIndicator statusName="Draft" />
                </h3>
                <SelectableQuotationsList
                  items={quotations.draft}
                  isInternalUser={isInternalUser}
                  openQuotation={openQuotationDetails}
                  setPM={assignPM}
                  deleteQuotations={deleteQuotations}
                />
              </div>
            )}
            <div className="mt-4">
              {!ongoingQuotationsOnly && (
              <h3 className="m-0">
                <StatusIndicator statusName="Ongoing" />
              </h3>
              )}
              <SelectableQuotationsList
                items={quotations.ongoing}
                isInternalUser={isInternalUser}
                openQuotation={openQuotationDetails}
                setPM={assignPM}
                />
            </div>
            {!ongoingQuotationsOnly && (
              <div className="mt-4">
              <h3 className="m-0">
                <StatusIndicator statusName="Closed" />
              </h3>
              <QuotationItemsList
                items={quotations.completed}
                isInternalUser={isInternalUser}
                onOpen={openQuotationDetails}
              />
              </div>
            )}
            </>
          )}
          {!isInternalUser && (
              <QuotationItemsList
              items={quotationItems}
              isInternalUser={isInternalUser}
              onOpen={openQuotationDetails}
            />
          )}
        </ContentSection>
      )}
    </Page>
  )
}

export default React.memo(QuotationsOverviewPage)

