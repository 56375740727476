import React from 'react'
import {
  FieldInputType,
  FieldType,
  OField
} from '@dnvgl-onefoundation/onedesign-react'
import Checkbox from '@dnvgl-onefoundation/onedesign-react/lib/cjs/Field/Checkbox'
import { FieldTemplate } from '../../interfaces/FormTemplate'

interface Props {
  fields?: FieldTemplate[]
  data: any
  isEditAllowed?: boolean
  groupName?:string
  groupIndex?:number
  onValueChange?: (fieldName: string, value: string, checked?: boolean, groupName?: string, groupIndex?: number) => void
}

function QuotationFormFields(props: Props) {
  const { fields, data, isEditAllowed, groupName, groupIndex, onValueChange } = props

  const getKey = (groupName: string | undefined, groupIndex: number | undefined, fieldIndex: number) => {
    if (!groupName && !groupIndex) return fieldIndex;
    let prefix = !!groupName ? groupName + "_" : "";
    if (groupIndex) {
      prefix += groupIndex.toString() + "_";
    }
    return prefix + fieldIndex.toString();
  }
  
  const GetFieldControl = (field: FieldTemplate, fieldIndex: number, data: any, onValueChange?: (fieldName: string, value: string, checked?: boolean, groupName?: string, groupIndex?:number) => void) : JSX.Element | undefined => {
    if (field.type === 'string') {
      return (
        <OField
          key={getKey(groupName, groupIndex, fieldIndex)}
          value={data}
          onChange={e => {
            onValueChange?.(field.name, e.value as string, undefined, groupName, groupIndex)
          } }
          field={{
            name: field.name,
            heading: field.name,
            type: FieldType.Input,
          }} /> 
      )
    }
    else if (field.type === 'number') {
      return (
        <OField
          key={getKey(groupName, groupIndex, fieldIndex)}
          value={data}
          onChange={e => {
            onValueChange?.(field.name, e.value as string, undefined, groupName, groupIndex)
          } }
          field={{
            name: field.name,
            heading: field.name,
            type: FieldType.Input,
            inputType: FieldInputType.Number
          }} /> 
      )
    } else if (field.type === 'textarea') {
      return (
        <OField
          key={getKey(groupName, groupIndex, fieldIndex)}
          value={data}
          onChange={e => {
            onValueChange?.(field.name, e.value as string, undefined, groupName, groupIndex)
          } }
          field={{
            name: field.name,
            heading: field.name,
            type: FieldType.Textarea,
          }} /> 
      )
    } else if (field.type === 'select') {
      return (
        <OField
          key={getKey(groupName, groupIndex, fieldIndex)}
          value={data}
          onChange={e => {
            onValueChange?.(field.name, e.value as string, undefined, groupName, groupIndex)
          }}
          field={{
            name: field.name,
            heading: field.name,
            type: FieldType.Select,
            options: field.options.map(o => ({ text: o, value: o }))
          }}
        />
      )
    } else if (field.type === 'checkbox') {
      return (
        <div key={getKey(groupName, groupIndex, fieldIndex)}>
          <label>{field.name}</label>
          {field.options.map(option => (
            <Checkbox
              key={getKey(groupName, groupIndex, fieldIndex).toString() + option}
              id={`${field.name}-${fieldIndex}-${option}`}
              isValid={true}
              disabled={false}
              checked={data?.[field.name]?.some(
                (x: string) => x === option
              )}
              onChange={e => {
                onValueChange?.(field.name, option, e.target.checked, groupName, groupIndex)
              }}>
              {option}
            </Checkbox>
          ))}
        </div>
      )
    }
    else {
      return undefined;
    }
  }
  
  return (
    <>
      {fields?.length && fields.length > 0 ? (
        fields.map((field, fieldIndex) => {
          return isEditAllowed ?  GetFieldControl(field, fieldIndex, data, onValueChange)
           : (
              <div className="form-group" key={(groupName ?? '') + fieldIndex}>
                <label>{field.name}</label>
                <div>{data?.[field.name]}</div>
              </div>
            )
        })
      ) : (
        <p>&nbsp;</p>
      )}
    </>
  )
}

export default React.memo(QuotationFormFields)
