import React from 'react'

import { OTooltip } from '@dnvgl-onefoundation/onedesign-react'

import { User } from '../../interfaces'
import { helper } from '../../utils'

interface Props {
  user?: User | null
  className?: string
}

const UserRoleIndicator = (props: Props) => {
  const { user, className } = props
  const { isSuperAdministrator, isInternal } = helper

  if (isSuperAdministrator(user as User))
    return (
      <OTooltip content="Super Administrator">
        <div className={`nav-item ${className}`}>
          <i className="fad fa-user-astronaut fg-dark-blue"></i>
        </div>
      </OTooltip>
    )

  return isInternal(user as User) ? (
    <OTooltip content="DNV Internal User">
      <div className={`nav-item ${className}`}>
        <i className="fad fa-user-tie fg-dark-blue"></i>
      </div>
    </OTooltip>
  ) : null
}

export default React.memo(UserRoleIndicator)
