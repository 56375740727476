import React, { useState, RefObject } from 'react'
import {
  OButton,
  ORow,
  OCol,
  OToast
} from '@dnvgl-onefoundation/onedesign-react'

import { momentHelper } from '../../utils'
import {  User } from '../../interfaces'
import { MessageViewModel } from './Discussions'

interface Props {
  currentUser: User
  isInternalUser: boolean
  isReadOnly: boolean
  messages: MessageViewModel[]
  messagesContainer: RefObject<HTMLDivElement>
  pushMessage(content: string):void
}

const { toHumandFriendlyDateTime } = momentHelper

const DiscussionPage = (props: Props) => {
    const { 
        currentUser,
        isReadOnly,
        messages,
        messagesContainer,
        pushMessage
     } = props

    const [content, setContent] = useState('')

    const onReplyClick = () => {
      pushMessage(content);
      setContent('');
    }
  
    return (
      <>
      <ORow className="fade-container">
        <div ref={messagesContainer} className="messages-container w-100 pt-4">
          {messages.map(message => (
            <OCol
              col="10"
              offset={currentUser?.id === message.senderId ? '2' : '0'}
              key={message.id}
              className="mb-2">
              <OToast
                variant={message.isFromInternalUser ? 'info' : 'success'}
                dismissable={false}
                message={message.senderDisplayName ?? message.senderFirstName}
                messageInfo={toHumandFriendlyDateTime(message.sendAt)}
                description={message.content}
              />
            </OCol>
          ))}
          {!messages?.length && (
            <p className="text-center">
              The discussion board does not have any messages yet.
            </p>
          )}
        </div>
      </ORow>
      {!isReadOnly && (
        <ORow className="pt-4 bg-primary">
          <OCol col="10">
            <div className="form-group">
              <textarea
                className="form-control required"
                rows={1}
                value={content}
                onChange={e => setContent(e.target.value)}></textarea>
            </div>
          </OCol>
          <OCol col="2">
            <OButton
              iconClass="fal fa-paper-plane"
              className="w-100"
              disabled={!content?.length}
              onClick={onReplyClick}>
              Reply
            </OButton>
          </OCol>
        </ORow>
      )}
    </>
    )
}

export default React.memo(DiscussionPage)
