import React from 'react'
import { OBadge } from '@dnvgl-onefoundation/onedesign-react'
import { ProgressEnum, Status } from '../../interfaces'

interface Props {
  status?: Status
}

const StatusBadge = (props: Props) => {
  const { status } = props
  const isCompleted = status?.progress === ProgressEnum.Completed

  return (
    <OBadge
      className="ml-2"
      variant={isCompleted ? 'success' : 'primary'}
      outline={!isCompleted}>
      {isCompleted ? 'Completed' : 'Ongoing'}
    </OBadge>
  )
}

export default React.memo(StatusBadge)
