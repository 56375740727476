import React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { config, helper } from '../../utils'
import { OButton } from '@dnvgl-onefoundation/onedesign-react'
import { Page, ContentSection, SpinIndicator } from '.'
import { UserRoleEnum } from '../../interfaces'

interface Props {
  children: JSX.Element
  roles?: UserRoleEnum[]
}

const ProtectedContent = (props: Props) => {
  const { roles, children } = props
  const { currentUser, isLoadingCurrentUser, isAuthSessionExpired } =
    useSelector((state: ApplicationState) => state.users)

  if (isLoadingCurrentUser) return <SpinIndicator />

  if (isAuthSessionExpired || !helper.isAuthenticated(currentUser)) {
    return (
      <Page title="Authentication required">
        <ContentSection
          icon="fas fa-user-shield text-danger"
          title="You must be logged in to access this page">
          <div className="mt-4">
            <a
              href={`${
                config.links.header.logIn
              }?returnUrl=${encodeURIComponent(window.location.pathname)}`}>
              <OButton iconClass="fal fa-sign-in-alt">Log in</OButton>
            </a>
          </div>
        </ContentSection>
      </Page>
    )
  }

  return roles !== undefined &&
    currentUser?.roles?.find(x => props.roles!.includes(x.id)) === undefined ? (
    <Page title="Access Denied">
      <ContentSection
        title={
          <span className="text-danger">
            You do not have permission to access this page.
          </span>
        }
        icon="fal fa-alien-monster text-danger"
      />
    </Page>
  ) : (
    children
  )
}

export default ProtectedContent
