import React, { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { OSidemenu } from '@dnvgl-onefoundation/onedesign-react'
import { Page } from '../../layout'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { getServices } from '../../../store/slices/quotations'
import { checkInternalUsers } from '../../../store/slices/userGroups'
import AddQuotationForm from './AddQuotationForm'
import QuotationFormDesigner from './QuotationFormDesigner'

const QuotationForms = () => {
  const services = useAppSelector(x => x.quotations.services)

  const dispatch = useAppDispatch()

  const refresh = useCallback(() => {
    dispatch(getServices())
    dispatch(checkInternalUsers())
  }, [dispatch])

  useEffect(() => {
    refresh()
  }, [refresh])

  const routes = useMemo(
    () =>
      services.map(service => {
        return {
          name: (
            <span
              id={`service-${service.id}`}
              title={service.name}
              className="elipsis-name">
              {service.name}
            </span>
          ) as ReactNode,
          path: `/admin/quotations/${service.id}`,
          iconClass: `${service.isPublished ? 'fas' : 'fal'} fa-file-alt`,
          isPublished: service.isPublished,
          component: <QuotationFormDesigner service={service} />
        }
      }),
    [services]
  )

  const newFormButtonLabel = 'Create Quotation Form'

  return (
    <Page
      title="Quotation Forms"
      actions={<AddQuotationForm operationName={newFormButtonLabel} />}>
      <div className="card card-dnvgl mt-3">
        {routes?.length ? (
          <OSidemenu routes={routes} variant="light" icons collapsed={false} />
        ) : (
          <p className="p-3 text-center">
            Please click the "{newFormButtonLabel}" to add the first form.
          </p>
        )}
      </div>
    </Page>
  )
}

export default React.memo(QuotationForms)
