import {
  ORow,
  OCol,
  OModal,
  OField,
  FieldType,
  GridColumns
} from '@dnvgl-onefoundation/onedesign-react'
import React, { useState } from 'react'
import { UpdateButton } from '.'
import { Address } from '../../interfaces'
import { helper, config } from '../../utils'

interface Props {
  title: string
  isEditable?: boolean
  address?: Address
  missingValueText?: string
  onUpdate?: (address: Address) => void
}

const AddressEditor = (props: Props) => {
  const { defaultCountryCode } = config
  const { countryOptions, addressToString } = helper
  const getCleanAddress = () => {
    return {
      addressLine1: address?.addressLine1 ?? '',
      addressLine2: address?.addressLine2 ?? '',
      city: address?.city ?? '',
      countryCode: address?.countryCode ?? defaultCountryCode,
      postalCode: address?.postalCode ?? ''
    }
  }
  const { title, isEditable, address, missingValueText, onUpdate } = props
  const [isEditVisible, setIsEditVisible] = useState(false)
  const [updatedAddress, setUpdatedAddress] = useState<Address>(
    getCleanAddress()
  )

  const showModal = () => {
    setUpdatedAddress(getCleanAddress())
    setIsEditVisible(true)
  }

  const patchPayload = (name: string, value: string) =>
    setUpdatedAddress(
      Object.assign({
        ...updatedAddress,
        ...{ [name]: value }
      })
    )

  const fields = [
    {
      name: 'addressLine1',
      heading: 'Address Line 1',
      type: FieldType.Input,
      required: true,
      col: '12'
    },
    {
      name: 'addressLine2',
      heading: 'Address Line 2 (Optional)',
      type: FieldType.Input,
      col: '12'
    },
    {
      name: 'city',
      heading: 'City',
      type: FieldType.Input,
      required: true,
      col: '4'
    },
    {
      name: 'countryCode',
      heading: 'Country',
      type: FieldType.Select,
      options: countryOptions,
      col: '4'
    },
    {
      name: 'postalCode',
      heading: 'Postal Code',
      type: FieldType.Input,
      required: true,
      col: '4'
    }
  ]

  const okDisabled = !!fields
    .filter(f => f.required)
    .find(f => !(updatedAddress as any)?.[f?.name]?.length)

  const bodySlot = (
    <ORow>
      {fields.map(field => (
        <OCol key={`${field?.name}`} col={field.col as GridColumns}>
          <OField
            value={{
              [`${field?.name}`]: (updatedAddress as any)?.[`${field?.name}`]
                ? (updatedAddress as any)?.[`${field?.name}`]
                : ''
            }}
            onChange={e => patchPayload(`${field?.name}`, e.value as string)}
            validated={field.required}
            required={field.required}
            field={{
              name: field.name,
              heading: field.heading,
              type: field.type,
              options: field?.options ?? []
            }}
          />
        </OCol>
      ))}
    </ORow>
  )

  return (
    <>
      <>
        {!!missingValueText && !address && (
          <span className="text-danger">{missingValueText}</span>
        )}
        {addressToString(address)}
        {isEditable && <UpdateButton onClick={showModal} />}
      </>
      <OModal
        visible={isEditVisible}
        bodySlot={bodySlot}
        titleText={title}
        onCancel={() => setIsEditVisible(false)}
        onOk={() => {
          setIsEditVisible(false)
          onUpdate?.(updatedAddress)
        }}
        clickOutside={false}
        okText="Submit"
        okDisabled={okDisabled}
      />
    </>
  )
}

export default React.memo(AddressEditor)
