import React, { useState, useEffect, useCallback } from 'react'

import { showSuccess, FileDrop } from '@dnvgl-onefoundation/onedesign-react'
import { api, helper, config } from '../../../utils'
import { deleteConfirm } from '../../modals'
import { SpinIndicator } from '../../layout'
import { FileUpload, FileList } from '../../helpers'
import { FileItem } from '../../../interfaces'

interface Props {
  quotationId: string
  isInternalUser: boolean
  isUploadAllowed: boolean
  isDeleteAllowed: boolean
}

const QuotationPageDocumentsTab = (props: Props) => {
  const { quotationId, isInternalUser, isUploadAllowed, isDeleteAllowed } =
    props
  const [isUploading, setIsUploading] = useState(false)
  const [files, setFiles] = useState<FileItem[] | undefined>(undefined)
  const isLoadingFiles = files === undefined

  const refresh = useCallback(() => {
    setFiles(undefined)
    api.quotations
      .listFiles(quotationId)
      .then(x => {
        setFiles(x)
      })
      .catch(e => {
        helper.handleErrorMessage(e)
        setFiles([])
      })
  }, [quotationId])

  useEffect(() => {
    refresh()
  }, [refresh])

  const onDelete = (file: FileItem) =>
    deleteConfirm(file.name).then(confirmed => {
      if (confirmed)
        api.quotations
          .deleteFile(quotationId, file.path)
          .then(refresh)
          .catch(helper.handleErrorMessage)
    })

  const onUpload = (files: FileDrop[]) => {
    if (!files?.length) return
    setIsUploading(true)
    api.quotations
      .uploadFiles(
        `${quotationId}`,
        files.map(x => x.file)
      )
      .then(() => {
        refresh()
        showSuccess('Uploaded')
      })
      .catch(helper.handleErrorMessage)
      .finally(() => setIsUploading(false))
  }

  const downloadUrlBuilder = (path: string) =>
    `${config.endpoints.quotations}/${quotationId}/Files/${path}`

  return isLoadingFiles ? (
    <SpinIndicator />
  ) : (
    <>
      <FileUpload
        isUploadAllowed={isUploadAllowed}
        isUploading={isUploading}
        maxFiles={25}
        maxBytes={250000000}
        acceptExtensions=".pdf, .docx, .xlsx, .msg, .doc, .xls, .zip, .7z, .gif, .png, .jpg, .jpeg"
        onUpload={onUpload}
      />
      <FileList
        isInternalUser={isInternalUser}
        files={files}
        isDeleteAllowed={isDeleteAllowed}
        onDelete={onDelete}
        downloadUrlBuilder={downloadUrlBuilder}
      />
    </>
  )
}

export default React.memo(QuotationPageDocumentsTab)
