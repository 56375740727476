import { FieldType, colors } from '@dnvgl-onefoundation/onedesign-react'
import { QuotationTab } from '../interfaces'

const config = {
  version: '1.0.0',
  appName: 'DNV Direct',
  appFullName: 'DNV Direct',
  endpoints: {
    currentUser: '/api/Account/CurrentUser',
    my: '/my',
    toggles: '/api/FeatureToggles',
    userAdministration: '/api/UserAdministration',
    groupsAdmin: '/api/GroupAdministration/Groups',
    groups: '/api/Groups',
    companies: '/api/Companies',
    teams: '/api/Teams',
    projectsAdmin: '/api/ProjectAdministration/Projects',
    projects: '/api/Projects',
    quotations: '/api/Quotations',
    quotationForms: '/api/QuotationForms',
    services: '/api/Services',
    transmittalFailures: '/api/TransmittalFailures'
  },
  dateFormat: 'DD MMMM YYYY',
  dateTimeFormat: 'DD MMM YYYY, HH:mm',
  shortDateFormat: 'MM/DD/YYYY',
  authCheckInterval: 1860000,
  messagesCheckInterval: 60000,
  paths: {
    orders: '/orders',
    quotations: '/quotations'
  },
  links: {
    header: {
      logIn: '/api/Account/SignIn',
      logOut: '/api/Account/SignOut',
      signUp: `https://id.veracity.com/sign-up?return-url=${window.location.origin}/api/Account/SignIn`,
      notifications: 'https://services.veracity.com/Notifications'
    },
    footer: {
      big: [
        {
          title: 'Product',
          links: [
            {
              name: 'About',
              path: '/about',
              guestUsers: true
            },
            {
              name: 'How it works',
              path: '/how-it-works',
              guestUsers: true
            }
          ]
        },
        {
          title: 'Legal',
          links: [
            {
              name: 'Privacy statement',
              path: '/privacy',
              guestUsers: true
            },
            {
              name: 'Terms of use',
              path: '/tou',
              guestUsers: true
            },
            {
              name: 'Cookies policy',
              path: '/cookies',
              guestUsers: true
            }
          ]
        }
      ]
    }
  },
  routes: {
    guest: [],
    authenticated: [
      {
        name: 'Quotations',
        path: '/quotations',
        isCompanySpecific: false
      },
      {
        name: 'Orders',
        path: '/orders',
        isCompanySpecific: false
      },
      {
        name: 'Users',
        path: '/admin/UserGroups',
        isCompanySpecific: true
      }
    ],
    admin: [
      {
        name: 'Forms',
        path: '/admin/quotations',
        isCompanySpecific: false
      },
      {
        name: 'Failures',
        path: '/admin/transmittal-failures',
        isCompanySpecific: false
      }
    ]
  },
  defaultTimeZone: 'UTC',
  defaultCountryCode: 'NO',
  projectsList: {
    rowsPerPage: 10,
    fields: {
      forInternal: [
        {
          name: 'isSelected',
          heading: '',
          sortable: false,
        },
        {
          name: 'name',
          heading: 'Name',
          sortable: true,
          type: FieldType.Input
        },
        {
          name: 'teamcenterId',
          heading: 'Teamcenter ID',
          sortable: true,
          type: FieldType.Input
        },
        {
          name: 'companyName',
          heading: 'Company',
          sortable: true,
          type: FieldType.Input
        },
        {
          name: 'contactPerson',
          heading: 'DNV PM',
          sortable: true,
          type: FieldType.Input
        },
        {
          name: 'updatedAt',
          heading: 'Last activity'
        },
        {
          name: 'actions'
        }
      ],
      forCustomer: [
        {
          name: 'statusName',
          heading: 'Status'
        },
        {
          name: 'accessMode',
          heading: 'Access'
        },
        {
          name: 'name',
          heading: 'Name',
          sortable: true,
          type: FieldType.Input
        },
        {
          name: 'teams',
          heading: 'Teams'
        },
        {
          name: 'customerContactName',
          heading: 'Customer contact',
          sortable: true,
          type: FieldType.Input
        },
        {
          name: 'updatedAt',
          heading: 'Last activity'
        }
      ]
    }
  },
  QuotationItemsList: {
    forInternal: {
      rowsPerPage: 10,
      fields: [
        {
          name: 'isSelected',
          heading: '',
          sortable: false,
        },
        {
          name: 'statusName',
          heading: 'Status'
        },
        {
          name: 'name',
          heading: 'Name',
          sortable: true,
          type: FieldType.Input
        },
        {
          name: 'action',
          heading: 'Action'
        },
        {
          name: 'groupName',
          heading: 'Customer company',
          sortable: true,
          type: FieldType.Input
        },
        {
          name: 'customerContact',
          heading: 'Customer contact',
          sortable: true,
          type: FieldType.Input
        },
        {
          name: 'dnvContact',
          heading: 'DNV PM',
          sortable: true,
          type: FieldType.Input
        },
        {
          name: 'updatedAt',
          heading: 'Last activity'
        },
        {
          name: 'actions'
        }
      ]
    },
    forCustomer: {
      rowsPerPage: 6,
      fields: [
        {
          name: 'statusName',
          heading: 'Status'
        },
        {
          name: 'name',
          heading: 'Name',
          sortable: true,
          type: FieldType.Input
        },
        {
          name: 'action',
          heading: 'Action'
        },
        {
          name: 'customerContact',
          heading: 'Customer contact',
          sortable: true,
          type: FieldType.Input
        },
        {
          name: 'dnvContact',
          heading: 'DNV PM',
          sortable: true,
          type: FieldType.Input
        },
        {
          name: 'updatedAt',
          heading: 'Last activity'
        }
      ]
    }
  },
  documentsList: {
    fields: [
      {
        name: 'statusName'
      },
      {
        name: 'name',
        heading: 'Name',
        sortable: true,
        type: FieldType.Input
      },
      {
        name: 'revision',
        heading: 'Rev no.'
      },
      {
        name: 'updateByFullName',
        heading: 'Updated by',
        sortable: true,
        type: FieldType.Input
      },
      {
        name: 'updateAtFormatted',
        heading: 'Updated at'
      },
      {
        name: 'actions',
        heading: 'Actions'
      }
    ]
  },
  statusColors: {
    default: colors.neutral,
    'Not started': colors.skyBlue,
    'Work in progress': colors.cyan,
    Ongoing: colors.cyan,
    'Open comments': colors.yellow,
    'Open Priority': colors.seaBlue,
    'Priority comments': colors.digitalBlue,
    Completed: colors.green,
    Closed: colors.green,
    Transmitted: colors.green,
    Pending: colors.yellow,
    Draft: colors.neutral,
  },
  cdnResources: {
    baseUrl: 'https://dnvdirect4test.blob.core.windows.net/assets',
    backgroundVideo: 'background.mp4',
    sentMessageSound: 'sent.mp3'
  },
  quotationPage: {
    defaultTabUrl: 'scope',
    tabUrls: {
      [QuotationTab.Technical]: 'scope',
      [QuotationTab.CustomerInfo]: 'details',
      [QuotationTab.Agreement]: 'agreement',
      [QuotationTab.PurchaseOrder]: 'order',
      [QuotationTab.Documents]: 'documents'
    },
    helperTexts: {
      [QuotationTab.Technical]:
        'We appreciate your interest in our service. Please complete the form below so we understand your needs and give your quotation request a name to support your own overview and administration.',
      [QuotationTab.CustomerInfo]:
        'Thanks for the specification of your needs. For us to prepare a formal quotation, we kindly ask you to review and complete your details as listed below.',
      [QuotationTab.Agreement]:
        'Based on your input we are pleased to provide you a service as defined by the enclosed agreement. Please review the quotation and upload a signed copy and confirm your order to document your acceptance.',
      [QuotationTab.PurchaseOrder]:
        'You may include your purchase order (PO) reference and a copy of the PO to complete your order.'
    },
    sendTextMessage:
      'Thank you for service request and the provided details. If you confirm, the quotation request will be submitted to an applicable DNV case handler who we will prepare a quotation in the form of a short form agreement for your review and acceptance. This may take up to three (3) business days. When prepared and ready for your review, you will receive a notification. You will find the status of your quotation request under the overview tab. Please confirm your request by using the button below',
    createTextMessage:
      'Thank you for service request. If you confirm, the quotation request will be created. Please confirm your request by using the button below',
    supportedInputTypes: ['string', 'textarea', 'number', 'select', 'checkbox'],
    newQuotationId: 'new',
    allowAcceptWithoutFile: false
  },
  quotationDetails: {
    requiredFields: ['companyName', 'vat', 'companyAddress']
  },
}

export default config
