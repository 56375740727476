import { OButton, OTooltip } from '@dnvgl-onefoundation/onedesign-react'
import React from 'react'
import { FileItem, SignedAgreementFile } from '../../interfaces'

import { momentHelper } from '../../utils'

interface Props {
  disabled?: boolean
  dowloadURL: string
  fileItem?: FileItem | SignedAgreementFile
}

const FileItemButton = (props: Props) => {
  const { disabled, dowloadURL, fileItem } = props
  const { toHumandFriendlyDateTime } = momentHelper
  const tooltip = `Uploaded by ${fileItem?.uploadedBy?.firstName} ${
    fileItem?.uploadedBy?.lastName
  }, ${toHumandFriendlyDateTime(fileItem?.uploadedAt)}`
  return (
    <OTooltip content={tooltip} placement="top">
      <a
        href={dowloadURL}
        className={disabled ? 'is-disabled' : ''}
        target="_blank"
        rel="noopener noreferrer">
        <OButton iconClass="fal fa-file-pdf" variant="flat">
          {fileItem?.name}
        </OButton>
      </a>
    </OTooltip>
  )
}

export default React.memo(FileItemButton)
